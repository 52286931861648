<template>
  <div id="home">
    <div class="left-mask"></div>
    <Navbar></Navbar>

    <!-- 林业工作情况 -->
    <UpperLeft v-show="$store.state.bdMapZoom==1 || $store.state.bdMapZoom==2"></UpperLeft>
     <!-- 碳交易数据 -->
    <Right v-show="$store.state.bdMapZoom==1 || $store.state.bdMapZoom==2"></Right>
      <!-- 无人机数据以及卫星数据 -->
    <TopCenter v-show="$store.state.bdMapZoom==1 || $store.state.bdMapZoom==2" :sourceType="sourceType"></TopCenter>
   <!-- 碳储量对比 -->
     <LowerLeft1 v-show="$store.state.bdMapZoom==1 || $store.state.bdMapZoom==2"></LowerLeft1>




     <!-- 树种累计碳储存占比 -->
    <BottomLeft v-show="$store.state.bdMapZoom==3"></BottomLeft>  
    <!-- 项目已核查减排量 -->
     <RightLin v-show="$store.state.bdMapZoom==3"></RightLin>
      <!-- 碳汇量趋势图 -->
    <TopLienChart v-show="$store.state.bdMapZoom==3"></TopLienChart>
    <!-- 林场碳汇价值量趋势图 -->
    <CenterLienChart v-show="$store.state.bdMapZoom==3"></CenterLienChart>
  <!-- 碳层单位面积碳储量 -->
    <TopRight v-show="$store.state.bdMapZoom==3"></TopRight>
  <!-- 碳层面积对比 -->
    <LowerRight v-show="$store.state.bdMapZoom==3"></LowerRight>
    
   
    <HomeMap ></HomeMap>
   
    <AreaDialog ref="AreaDialog" v-show="$store.state.isShowRotateDialog" :isHomeMap="false"></AreaDialog>
    <div class="right-mask"></div>
    <div class="back-main-map" v-if="$store.state.isHomeMap" @click="handleBack()"></div>
  </div>
</template>

<script>
import SVGA from "svgaplayerweb";
import $ from "jquery";
import HomeMap from "@/components/HomeMap/HomeMap.vue";
import Navbar from "@/components/Navbar/Navbar.vue";
import UpperLeft from "@/components/UpperLeft/UpperLeft.vue";
import LowerLeft from "@/components/LowerLeft/LowerLeft.vue";
import LowerLeft1 from "@/components/LowerLeft/LowerLeft1.vue";
import LowerRight from "@/components/LowerRight/LowerRight.vue";
import TopRight from "@/components/TopRight/TopRight.vue";
import Right from "@/components/TopRight/Right.vue";
import AreaDialog from "@/components/AreaDialog/AreaDialog.vue";
import TopCenter from "@/components/TopCenter/TopCenter.vue";
import BottomLeft from "@/components/BottomLeft/BottomLeft.vue";
import TopLienChart from "@/components/TopLienChart/TopLienChart.vue";
import CenterLienChart from "@/components/CenterLienChart/CenterLienChart.vue";
import RightLin from "@/components/TopRight/RightLin.vue";

export default {
  name: "Home",
  data() {
    return {
      timer: null,
      svgaArr: [
        "../../svga/1.svga",
        "../../svga/2.svga",
        "../../svga/3.svga",
        "../../svga/4.svga",
        "../../svga/门磁2.svga",
        "../../svga/人体2.svga",
        "../../svga/摄像头2.svga",
        "../../svga/手环2.svga",
      ],
      sourceType: 'wx',
    };
  },
  components: {
    HomeMap,
    Navbar,
    UpperLeft,
    LowerLeft,
    LowerLeft1,
    TopRight,
    Right,
    LowerRight,
    AreaDialog,
    TopCenter,
    BottomLeft,
    TopLienChart,
    CenterLienChart,
    RightLin
  },
  mounted() {
    this.getData();
    // this.SVGA("#camera-img", 6);
  },
  destroyed() {

  },
  methods: {
    getData() {},
    // 放svga图片
    SVGA(id, index) {
      let that = this;
      let player = new SVGA.Player(id);
      let parser = new SVGA.Parser(id);
      parser.load(that.svgaArr[index], function (videoItem) {
        //this.bgImg，图片路径需要线上地址，本地引用会报错
        // parser.load('//yun.tuisnake.com/h5-mami/pluginAct/takePack1/cai1.svga',function(videoItem) {                          //this.bgImg，图片路径需要线上地址，本地引用会报错
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
    handleBack() {
      // this.MidMap.startViewAnimation(this.mobilePerspectiveBack); // 开始播放动画
      this.$store.commit("SET_MAP_STATE", false);
    },
  },
  // 监听vuex state
  computed: {
    // getIsHomeMap() {
    //   return this.$store.state.isHomeMap;
    // },
    // getIsShowMapInfo() {
    //   return this.$store.state.isShowMapInfo;
    // },
    getBdMapZoom() {
      return this.$store.state.bdMapZoom;
    },
  },
  watch: {
    // getIsHomeMap(val) {},
    // getIsShowMapInfo(val) {},
    getBdMapZoom(val) {
      console.log(val,'123123123');
      if(val==1){
        this.sourceType = 'wx'
      }else{
        this.sourceType = 'wrj'
      }
    },
  },
};
</script>
<style scoped>
#home {
  position: relative;
  width: 100%;
  /* height: 1080px; */
  /* background: #001529; */
}
.left-mask {
  width: 485px;
  height: calc(100vh - 30px);
  background: linear-gradient(90deg, #012205 0%, rgba(1, 34, 5, 0) 100%);
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 999;
}
.right-mask {
  width: 480px;
  height: calc(100vh - 30px);
  background: linear-gradient(
    270deg,
    rgba(1, 34, 5, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(4px);
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 99;
}
.back-main-map {
  /* icon_sel@2x.png */
  width: 60px;
  height: 60px;
  background-image: url("../../assets/HomeMap/返回@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 110px;
  left: 486px;
  pointer-events: all;
  cursor: pointer;
  z-index: 999;
}

</style>
