<template>
  <div class="container">
    <div class="title-container">
      <div class="title-zh">2021年福建省林业工作情况</div>
      <div class="title-en">FORESTRY WORK</div>
    </div>
    <div class="img-container">
      <div class="blog_news_box" id="content">
        <vue-seamless-scroll v-if="$store.state.bdMapZoom==1" :data="CityData" :class-option="defaultOption">
          <ul class="ul-scoll">
            <li v-for="(item, index) in CityData" :key="index">
              <span class="title">{{ item.title }}</span>
            </li>
          </ul>
        </vue-seamless-scroll>
        <vue-seamless-scroll v-else :data="ProvinceData" :class-option="defaultOption">
          <ul class="ul-scoll">
            <li v-for="(item, index) in ProvinceData" :key="index">
              <span class="title">{{ item.title }}</span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getAreaAndCount, getWorkCount } from "../../api/request.js";
import vueSeamlessScroll from "vue-seamless-scroll/src";
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      //南平市文案
      CityData: [
        {
          title:
            "早在2016年，顺昌县国有林场成为省级林业碳汇项目试点林场，开始组织实",
        },
        {
          title:
            "施林业碳汇项目的设计和申报工作。该项目碳汇林面积6.9万亩，计入期20年",
        },
        {
          title:
            "，总减排量25.7万吨，预估交易总收益约600万元。同年12月22日，首期15.5",
        },
        {
          title:
            "万吨碳减排量在福建省海峡股权交易中心全部售出，成交金额288.3万元。这",
        },
        {
          title: "是福建省获准上线的第一批次林业碳汇项目。",
        },
        {
          title: "",
        },
        {
          title:
            "破冰之举后，再接再厉谱新篇。2017年，顺昌县依托县国有林场下属“顺昌",
        },
        {
          title:
            "县宝杉林业碳汇技术服务中心”，独立完成3.4万亩竹林碳汇项目的策划工作",
        },
        {
          title:
            "。紧接着，顺昌县国有林场通过与高校、科研机构合作，创立并编制完成《",
        },
        {
          title:
            "“一元碳汇”项目方法学》，在全国首创“一元碳汇”试点项目。项目从脱",
        },
        {
          title:
            "贫村、脱贫户的碳汇林开发破题，将碳汇项目实施所产生的碳汇量，通过微",
        },
        {
          title:
            "信小程序，以10千克／元的价格向社会公众销售，使“碳库”变“金库”，",
        },
        {
          title:
            "为实现碳中和目标做好基础性准备工作。2021年，南平市完成实施VCS标准",
        },
        {
          title:
            "的国际林业碳汇项目，进一步推动南平林业碳汇走出国门、进入国际碳市场。",
        },
        {
          title: "",
        },
        {
          title:
            "如今，“碳汇＋”模式不断得到拓展，试点模式也从顺昌向南平全市铺开。",
        },
        {
          title: "“碳汇＋生态司法”“碳汇＋大型会议”“碳汇＋电力”“碳汇＋旅游”“",
        },
        {
          title:
            "碳汇＋金融”等多种模式的推出，丰富了交易场景，不断扩大购买主体。我",
        },
        {
          title: "市创新“碳汇+保险”模式，在全省率先推出“林业碳汇贷”“竹林认证贷",
        },
        {
          title: "”“碳汇致富贷”“售碳+远期碳汇”组合质押模式等碳汇金融产品，累计",
        },
        {
          title:
            "发放贷款62笔，金额2.49亿元。此外，我市还建立完善低碳产业发展机制，",
        },
        {
          title:
            "推动制定了竹、茶、低碳旅游等碳达峰实施方案，创新开展武夷山低碳旅游",
        },
        {
          title:
            "试点，完成《全域近零碳（碳中和）旅游城市建设指南》《低碳旅游达人评",
        },
        {
          title: "价准则》两个团体标准（草案），研究制定碳普惠积分场景方案。",
        },
        {
          title: "",
        },
      ],
      //福建省文案
      ProvinceData: [
        {
          title:
            "作为全国首个国家生态文明试验区，福建省各级生态环境部门突出示范引领，",
        },
        {
          title:
            "聚焦重点难点问题，在体制机制上深入探索、创新突破，涌现出一批可复制可",
        },
        {
          title:
            "推广的改革成果，为推动跨系统改革成果的相互衔接和系统集成，构建全尺度",
        },
        {
          title:
            "、多领域衔接的环境治理长效制度体系，提供更多“福建经验”“福建样板。",
        },
        {
          title: "”",
        },
        {
          title: "",
        },
        {
          title:
            "作为我国南方地区的重要生态屏障，福建省森林覆盖率高达66.8%，是全国",
        },
        {
          title:
            "最“绿”的省份之一。为了提升生态碳汇能力，强化国土空间规划和用途管",
        },
        {
          title:
            "控，提升生态系统碳汇增量，近年来，福建省坚持传承弘扬林权制度改革等",
        },
        {
          title:
            "一系列生态文明建设重要理念、重大实践，围绕碳达峰、碳中和目标愿景，",
        },
        {
          title:
            "在林业碳汇生成、流通、增值、应用等环节积极探索“福建经验”，全省林",
        },
        {
          title:
            "业碳汇成交量和成交额目前均排名全国前列，并成为水、大气、生态环境全",
        },
        {
          title:
            "优且所有地级市人均GDP均超过全国平均水平的省份，进一步让“山定权、",
        },
        {
          title: "树定根、人定心”，实现“不砍树也致富”，形成一条“绿水青山”和“金",
        },
        {
          title:
            "山银山”相互转化、生态环境“高颜值”与经济发展“高素质”协同并进的",
        },
        {
          title: "绿色低碳发展新路径。",
        },
        {
          title: "",
        },
      ],
    };
  },
  mounted() {},
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    getData() {},
  },
};
</script>

<style scoped>
.container {
  width: 432px;
  height: 640px;
  position: absolute;
  top: 110px;
  left: 24px;
  z-index: 999;
}
.title-container {
  width: 432px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/编组备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}
.title-zh {
  width: calc(100% - 34px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 18px;
  position: absolute;
  top: 11px;
  left: 34px;
  text-align: left;
}
.title-en {
  width: calc(100% - 263px);
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #c7fdf3;
  line-height: 12px;
  position: absolute;
  top: 18px;
  left: 263px;
  text-align: left;
  opacity: 0.5;
}
.img-container {
  height: 286px;
  width: 480px;
  background-image: url("../../assets/UpperLeft/文案bg@2x.png");
  margin-top: 30px;
  margin-left: -24px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.blog_news_box {
  position: absolute;
  width: 412px;
  height: 224px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 16px;
  margin-top: 32px;
  margin-left: 37px;
  overflow: hidden;
  /* margin-left: 5px; */
}
.ul-scoll {
  text-align: left;
  /* padding-left: 5px; */
}
</style>