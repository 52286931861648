<template>
  <div class="container">
    <div class="title-container">
      <div class="title-zh">碳汇量趋势图</div>
      <div class="title-en">CARBON SINK TREND GRAPH</div>
    </div>
    <div class="img-container">
      <!-- 折线＋柱状 -->
      <div id="vaccination-chart" v-show="changeChart == 1"></div>
      <!--柱状 -->
      <div id="vaccination-chart1" v-show="changeChart == 2"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { toRaw } from 'vue'
export default {
  components: {},
  data() {
    return {
      end:4,
      timeOut: null,
      option: null,
   axisArr:[],
   actualDataArr:[],
   estimateDataArr:[],
   myDrawChart: null,
   myChart:null,
      changeChart: 1,
         // 项目一8
         vaccineYears:["2022"],
      vaccineActualList: [0],
      vaccineEstimateList: [94283],
      
      // 项目二26
       vaccineYears1:["2006","2007","2008","2009","2010","2011","2012","2013","2014","2015","2016","2017","2018","2019","2020","2021","2022","2023"],
   vaccineActualList1: [80190.6,80190.6,80190.6, 80190.6,80190.6,80190.6,80190.6, 80190.6,80190.6,80190.6,0,0,0,0,0,0,0,0,0,0],
      vaccineEstimateList1: [43672,64192,68936,75755,56764,82203,94235,98816,78644,101057,95387,95554,98427,92153,97947,92355,93339,90689,87783,84684],
      // 项目三5
       vaccineYears2:["2010","2011","2012","2013","2014","2015","2016","2017","2018","2019","2020","2021","2022","2023","2024",'2025','2026','2027',"2028","2029","2030","2031","2032","2033","2034","2035","2036","2037","2038"],
      vaccineActualList2: [27921, 27921,27921, 27921,27921, 27921,27921,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      vaccineEstimateList2: [18376, 31871,55573,31871,60898,31871,24514,0,24514,0,24514,0,24514,-84581,24514,0,24514,0,24514,0,24514,0,24514,0,24514,0,24514,-85803,24514,0],
    };
  },
  mounted() {
    this.changeChart = 2
    this.drawVaccination();
    this.drawChart();

  },
  methods: {
    drawChart() {
      let that = this;
      
      document.getElementById("vaccination-chart1").removeAttribute("_echarts_instance_")
      this.myDrawChart = this.$echarts.init(
        document.getElementById("vaccination-chart1")
      );
      this.myDrawChart.setOption({
        grid: {
          x: 10,
          x2: 10,
          y2: -1,
          top: "15%",
          containLabel: true,
        },
        legend:{
          textStyle:{
            color: "#FFF"
          },
          right: 0,
          top: "2%",
        },
           tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          width: 48,
          height: 12,
          lineHeight: 12,
          confine: true,
          textStyle: {
            color: "#D1FEE9",
            fontSize: "12",
            fontFamily: "MicrosoftYaHei",
            align: "left",
          },
          // extraCssText:'background: linear-gradient(270deg, #1D4A63 0%, #0F244D 100%);',
          // extraCssText:'background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.61) 50%,rgba(0, 255, 135, 0.1) 100%);',
          backgroundColor: " rgba(0, 0, 0, 0.8) ",
          borderColor: "#00FF87", //tooltip边框颜色
          borderWidth: 1,
          formatter: (params) => {
            let str = "";
            let titleStr = "";
            let bg =
              '<span style="z-index:-1;display:inline-block;position:absolute;top:0;left:0;border-radius:10px;width:100%;height:100%;background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.51) 50%,rgba(0, 255, 135, 0.1) 100%);"></span>';
            for (let i = 0; i < params.length; i++) {
              // #3FBF7A 绿
              // #FFBF61 黄
              let textColor = ["#3FBF7A", "#FFBF61"];
              let markerStr = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${textColor[i]}"></span>`;
              let value = `<span style="color:${textColor[i]}">${params[i].value}</span>`;
              // console.log(params[i]);
              titleStr = params[i].name + "<br/>";
              str +=
                markerStr + " " + params[i].seriesName + ": " + value + "<br/>";
            }
            // return titleStr + str
            return str + bg;
          },
        },
        xAxis: [
          {
            type: "category",
            data:toRaw(this.vaccineYears),
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              // 设置Y轴的label
              formatter: "{value} ",
              color: "#F2F2F2",
              fontFamily: "DINPro-CondensedBold, DINPro",
            },
            axisLine: {
              lineStyle: {
                color: "#598FF3",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // min: 0,
            // max: 100,
            // interval: 20,
            axisLabel: {
              // 设置Y轴的label
              formatter: "{value} 吨",
              color: "#F2F2F2",
              fontFamily: "DINPro-CondensedBold, DINPro",
            },
            splitLine: {
              //  设置Y轴的线条
              // show: false,
              lineStyle: {
                color: "#E7E7E7",
                type: [10, 10],
                dashOffset: 1,
                opacity: 0.2,
              },
            },
          },
        ],
        series: [
          {
            name: "项目实际碳汇量",
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              formatter: "{c}吨",
              textStyle: {
                //数值样式
                color: "#BAA270",
                fontSize: 12,
                fontFamily: "MicrosoftYaHei",
              },
            },
            color: "#FFFFFF", // 内线颜色 含拐点
      
            backgroundStyle: {
              color: "rgba(2, 132, 71, 0.2)",
            },
            barGap: "50%",
         
            barWidth: "24",
            itemStyle: {
              color: 
                new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#FFC147" }, //柱图渐变色
                    { offset: 1, color: "rgba(255,193,71,0)" }, //柱图渐变色
                  ]),
                 
             

      
          
      
              borderWidth: 3,
              borderType: 'solid',
        
            },
            emphasis: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#FFC147",
                  fontSize: 14,
                  fontFamily: "MicrosoftYaHei",
                  shadowColor: " #FFC147",
                  shadowBlur: 5,
                },
              },
              itemStyle: {
           
                borderWidth: 3,
                borderType: "solid",
              },
            },
            data: this.vaccineActualList,
            
          },
           {
            name: "项目预估碳汇量",
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              formatter: "{c}吨",
              textStyle: {
                //数值样式
                color: "#BAA270",
                fontSize: 12,
                fontFamily: "MicrosoftYaHei",
              },
            },
            color: "#FFFFFF", // 内线颜色 含拐点
      
            backgroundStyle: {
              color: "rgba(2, 132, 71, 0.2)",
            },
            barGap: "50%",
         
            barWidth: "24",
            itemStyle: {
              color:  new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(0, 255, 135, 0.8)" }, //柱图渐变色
                    { offset: 1, color: "rgba(137, 255, 58, 0)" }, //柱图渐变色
                   
                  ]),
              
      
              borderWidth: 3,
              borderType: 'solid',
        
            },
            emphasis: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#FFC147",
                  fontSize: 14,
                  fontFamily: "MicrosoftYaHei",
                  shadowColor: " #FFC147",
                  shadowBlur: 5,
                },
              },
              itemStyle: {
           
                borderWidth: 3,
                borderType: "solid",
              },
            },
            data: this.vaccineEstimateList,
            
          },
        ],
      });
    },
    drawVaccination() {
      // var echarts = require('echarts');
      let that = this;
      // this.axisArr =this.vaccineYears1
      // this.actualDataArr = this.vaccineActualList1
      // this.estimateDataArr = this.vaccineEstimateList1
      document.getElementById("vaccination-chart").removeAttribute("_echarts_instance_")
     this.myChart = this.$echarts.init(
        document.getElementById("vaccination-chart")
      );
      this.charOption = {
        grid: {
          x: 10,
          x2: 10,
          y2: -1,
          top: "15%",
          containLabel: true,
        },
        legend:{
          textStyle:{
            color: "#FFF"
          },
          right: 0,
          top: "2%",
        },
           tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          width: 48,
          height: 12,
          lineHeight: 12,
          confine: true,
          textStyle: {
            color: "#D1FEE9",
            fontSize: "12",
            fontFamily: "MicrosoftYaHei",
            align: "left",
          },
          // extraCssText:'background: linear-gradient(270deg, #1D4A63 0%, #0F244D 100%);',
          // extraCssText:'background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.61) 50%,rgba(0, 255, 135, 0.1) 100%);',
          backgroundColor: " rgba(0, 0, 0, 0.8) ",
          borderColor: "#00FF87", //tooltip边框颜色
          borderWidth: 1,
          formatter: (params) => {
            let str = "";
            let titleStr = "";
            let bg =
              '<span style="z-index:-1;display:inline-block;position:absolute;top:0;left:0;border-radius:10px;width:100%;height:100%;background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.51) 50%,rgba(0, 255, 135, 0.1) 100%);"></span>';
            for (let i = 0; i < params.length; i++) {
              // #3FBF7A 绿
              // #FFBF61 黄
              let textColor = ["#3FBF7A", "#FFBF61"];
              let markerStr = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${textColor[i]}"></span>`;
              let value = `<span style="color:${textColor[i]}">${params[i].value}</span>`;
              // console.log(params[i]);
              titleStr = params[i].name + "<br/>";
              str +=
                markerStr + " " + params[i].seriesName + ": " + value + " 吨<br/>";
            }
            // return titleStr + str
            return str + bg;
          },
        },
        xAxis: [
          {
            type: "category",
            data: toRaw(this.axisArr),
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              // 设置Y轴的label
              formatter: "{value} ",
              color: "#F2F2F2",
              fontFamily: "DINPro-CondensedBold, DINPro",
            },
            axisLine: {
              lineStyle: {
                color: "#598FF3",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // min: 0,
            // max: 100,
            // interval: 20,
            axisLabel: {
              // 设置Y轴的label
              formatter: "{value} 吨",
              color: "#F2F2F2",
              fontFamily: "DINPro-CondensedBold, DINPro",
            },
            splitLine: {
              //  设置Y轴的线条
              // show: false,
              lineStyle: {
                color: "#E7E7E7",
                type: [10, 10],
                dashOffset: 1,
                opacity: 0.2,
              },
            },
          },
        ],
         dataZoom: [
          {
            show: false,
            type: "slider",
            // realtime: true,
            startValue: 0, // 从头开始。
            endValue: toRaw(this.end), //
          },
          //  {
          //    show: true,
          //    realtime: true,
          //    height: 24,//这里可以设置dataZoom的尺寸
          //    bottom: 6,
          //    start: 0,
          //    end: 100,
          //    backgroundColor: '#1d76e6', // 填充颜色
          //    textStyle: {
          //     color: '#fff' // 左右两边文字颜色
          //   }
          //  }
        ],
        series: [
          {
            name: "预估值",
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              formatter: "{c}吨",
              textStyle: {
                //数值样式
                color: "#BAA270",
                fontSize: 12,
                fontFamily: "MicrosoftYaHei",
              },
            },
            color: "#FFFFFF", // 内线颜色 含拐点
            // areaStyle: {
            //     opacity: 0.6,
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         {
            //             offset: 0,
            //             color: '#00FF67'
            //         },
            //         {
            //             offset: 1,
            //             color: '#1D3A12'
            //         }
            //     ])
            // },
            backgroundStyle: {
              color: "rgba(2, 132, 71, 0.2)",
            },
            barGap: "50%",
            // barCategoryGap: "40%",
            barWidth: "24",
            itemStyle: {
              color: new that.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                [
                  { offset: 0, color: "#FFC147" },
                  { offset: 1, color: "rgba(255,193,71,0)" },
                ]
              ),
              borderColor: new that.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                [
                  { offset: 0, color: "#F6D3AA" },
                  { offset: 0.05, color: "rgba(0,0,0,0)" },
                  { offset: 1, color: "rgba(0,0,0,0)" },
                ]
              ),
              borderWidth: 3,
              borderType: "solid",
            },
            emphasis: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#FFC147",
                  fontSize: 14,
                  fontFamily: "MicrosoftYaHei",
                  shadowColor: " #FFC147",
                  shadowBlur: 5,
                },
              },
              itemStyle: {
                color: new that.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 0, color: "#FFC147" },
                    { offset: 1, color: "rgba(255,193,71,0)" },
                  ]
                ),
                borderColor: new that.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 0, color: "#F6D3AA" },
                    { offset: 0.05, color: "rgba(0,0,0,0)" },
                    { offset: 1, color: "rgba(0,0,0,0)" },
                  ]
                ),
                borderWidth: 3,
                borderType: "solid",
              },
            },
            data:toRaw(this.actualDataArr), 
            // smooth: true, //开启平滑
            // symbol: "circle", // 拐点样式
            // symbolSize: 8, // 拐点大小
            // showSymbol: true, // 是否显示拐点
          },
          {
            name: "实际值",
            type: "line",
            color: "#FFFFFF", // 内线颜色 含拐点
            itemStyle: {
              /*设置折线颜色*/
              normal: {
                // color: "#32DB83",
                borderColor: "#32DB83", //图形的描边颜色
                borderWidth: 1,
                lineStyle: {
                  width: 3,
                  color: "#32DB83", //外线颜色 不含拐点
                },
              },
            },
            // areaStyle: {
            //     opacity: 0.6,
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         {
            //             offset: 0,
            //             color: '#FFC147'
            //         },
            //         {
            //             offset: 1,
            //             color: '#1D3A12'
            //         }
            //     ])
            // },
            data: 
            toRaw(this.estimateDataArr),
            smooth: true, //开启平滑
            symbol: "circle", // 拐点样式
            symbolSize: 8, // 拐点大小
            showSymbol: true, // 是否显示拐点
          },
        ],
      }
      // this.myChart.setOption();
      this.myChart.on("mouseover", this.stop);
      this.myChart.on("mouseout", this.goMove);
      window.addEventListener("resize", function () {
        setTimeout(function () {
          this.myChart.resize();
        }, 350);
      });
      this.autoMove();
    },
        //自动滚动
    autoMove() {
      let that = this;
      console.log("12312312312321");
      toRaw(that.myChart).setOption(toRaw(that.charOption));
      //this.dataList.seriesData为柱形图数据
      if (that.timeOut == null) {
        that.timeOut = setInterval(() => {
          // clearInterval(this.timeOut)
          // 每次向后滚动一个，最后一个从头开始。
          // if(this.stopMove){ return }

         this.axisArr.push(toRaw(this.axisArr.shift()));
          // this.data2.push(toRaw(this.data2.shift()));
         this.actualDataArr.push(toRaw(this.actualDataArr.shift()));
           this.estimateDataArr.push(toRaw(this.estimateDataArr.shift()));
          // this.data

          // if (Number(this.option.dataZoom[0].endValue) === this.data.length) {
          //   this.option.dataZoom[0].endValue = this.end;
          //   this.option.dataZoom[0].startValue = 0;
          // } else {
          //   this.option.dataZoom[0].endValue = this.option.dataZoom[0].endValue + 1;
          //   this.option.dataZoom[0].startValue = this.option.dataZoom[0].startValue + 1;
          // }

          toRaw(that.myChart).setOption(toRaw(that.charOption));
        }, 3000);
      }
    },
    //停止滚动
    stop() {
      console.log(11);
      // this.stopMove=true
      clearInterval(this.timeOut);
      this.timeOut = null;
    },
    //继续滚动
    goMove() {
      console.log(333333);
      //  this.stopMove=false
      this.autoMove();
    },
  },
  computed:{
    getProjectType(){
      return this.$store.state.projectType
    }
  },
  watch:{
    getProjectType(newVal){
      if(newVal == 1){
        this.changeChart = 2
      }else if(newVal == 2){
        this.changeChart = 1
        this.axisArr = this.vaccineYears1
        this.actualDataArr = this.vaccineActualList1
        this.estimateDataArr = this.vaccineEstimateList1
        this.drawVaccination();
      }else if(newVal == 3){
        this.changeChart = 1
        this.axisArr = this.vaccineYears2
        this.actualDataArr = this.vaccineActualList2
        this.estimateDataArr = this.vaccineEstimateList2
        this.drawVaccination();
      }
    }
  }
};
</script>

<style scoped>
.container {
  width: 432px;
  height: 640px;
  position: absolute;
  top: 110px;
  left: 24px;
  z-index: 999;
}

.title-container {
  width: 432px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/编组备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}

.title-zh {
  width: calc(100% - 34px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 18px;
  position: absolute;
  top: 11px;
  left: 34px;
  text-align: left;
}

.title-en {
  width: calc(100% - 152px);
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #c7fdf3;
  line-height: 12px;
  position: absolute;
  top: 18px;
  left: 163px;
  text-align: left;
  opacity: 0.5;
}

.img-container {
  /* height: 286px;
  width: 480px; */
  /* background-image: url("../../assets/UpperLeft/文案bg@2x.png"); */
  /* margin-top: 30px;
  margin-left: -24px;
  background-size: 100% 100%;
  background-repeat: no-repeat; */
}

#vaccination-chart {
  width: 480px !important;
  height: 286px !important;
  margin-top: 30px;
  margin-left: -24px;
}
#vaccination-chart1 {
  width: 480px !important;
  height: 286px !important;
  margin-top: 30px;
  margin-left: -24px;
}
.blog_news_box {
  position: absolute;
  width: 412px;
  height: 224px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 16px;
  margin-top: 32px;
  margin-left: 34px;
  overflow: hidden;
}

/* .ul-scoll li{
                margin: 6px;
                padding: 5px;
                background: rgba(198, 142, 226, 0.4);
            } */
</style>