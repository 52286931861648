<template>

    <div class="container">
        <div class="title-container">
            <div class="title-zh">{{title}}</div>
            <div class="title-en">Satellite Data</div>
        </div>
        <div :id="sourceType === 'wrj'?'round':'other'"></div>
        <div class="nav">
            <el-row>
                <el-col :span="12" v-for="item in content">
                        <i class="point"></i> <span class="title">{{item.title}}</span> <span class="name">{{item.name}}</span>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { watch } from '@vue/runtime-core';
export default {
     props: {
        sourceType:{
            type:String,
            default(){
                return 'wx'
            }
        }
     },
    data() {
        return {
            title:'',
            content:[],
            wrjContent:[
                {title:'无人机：',name:'大疆DJI L1'},
                {title:'测距精度：',name:'3cm-100m'},
                {title:'最多支持回波数：',name:'3'},
                {title:'扫描模式：',name:'重复/非重复扫描'},
                {title:'激光安全等级：',name:'Class1（人眼安全）'},
                {title:'有效像素：',name:'2000万'},
                {title:'焦距：',name:'8.8mm/24mm（等效）'},
                {title:'光圈：',name:'f/2.8 - f/11'},
            ],
            wxContent:[
                {title:'卫星：',name:'高分七号卫星激光测高仪'},
                {title:'激光波束：',name:'2波束'},
                {title:'激光重复频率：',name:'3Hz'},
                {title:'测距精度：',name:'≤0.3m（坡度<15°）'},
                {title:'单脉冲能量：',name:'≥180mJ'},
                {title:'激光器工作波长：',name:'1.064μm'},
                {title:'光斑大小：',name:'30m'},
                {title:'激光发散度：',name:'≤60μrad'},
            ]
        }

    },
    mounted() {
        if(this.sourceType ==='wrj'){
            this.title = '无人机数据';
            this.content = this.wrjContent
        }else{
            this.title = '卫星数据';
            this.content = this.wxContent
        }
     },
    methods: {},
    watch:{
        sourceType(newVal,oldVal){
            if(newVal ==='wrj'){
                this.title = '无人机数据';
                this.content = this.wrjContent
            }else{
                this.title = '卫星数据';
                this.content = this.wxContent
            }
        }
    }
};
</script>

<style scoped>
.container {
    width: 432px;
    height: 336px;
    position: absolute;
    top: 430px;
    left: 24px;
    z-index: 999;
}

@keyframes round_rotate {
    0% {
        background-image: url("../../assets/TopCenter/wrj/wrj_50.png");
    }

    2% {
        background-image: url("../../assets/TopCenter/wrj/wrj_1.png");
    }

    4% {
        background-image: url("../../assets/TopCenter/wrj/wrj_2.png");
    }

   6% {
        background-image: url("../../assets/TopCenter/wrj/wrj_3.png");
    }

   8% {
        background-image: url("../../assets/TopCenter/wrj/wrj_4.png");
    }

   10% {
        background-image: url("../../assets/TopCenter/wrj/wrj_5.png");
    }

   12% {
        background-image: url("../../assets/TopCenter/wrj/wrj_6.png");
    }

    14% {
        background-image: url("../../assets/TopCenter/wrj/wrj_7.png");
    }

    16% {
        background-image: url("../../assets/TopCenter/wrj/wrj_8.png");
    }

    18% {
        background-image: url("../../assets/TopCenter/wrj/wrj_9.png");
    }

    20% {
        background-image: url("../../assets/TopCenter/wrj/wrj_10.png");
    }

    22% {
        background-image: url("../../assets/TopCenter/wrj/wrj_11.png");
    }

    24% {
        background-image: url("../../assets/TopCenter/wrj/wrj_12.png");
    }

    26% {
        background-image: url("../../assets/TopCenter/wrj/wrj_13.png");
    }

    28% {
        background-image: url("../../assets/TopCenter/wrj/wrj_14.png");
    }

    30% {
        background-image: url("../../assets/TopCenter/wrj/wrj_15.png");
    }

    32% {
        background-image: url("../../assets/TopCenter/wrj/wrj_16.png");
    }

    34% {
        background-image: url("../../assets/TopCenter/wrj/wrj_17.png");
    }

    36% {
        background-image: url("../../assets/TopCenter/wrj/wrj_18.png");
    }

    38% {
        background-image: url("../../assets/TopCenter/wrj/wrj_19.png");
    }

    40% {
        background-image: url("../../assets/TopCenter/wrj/wrj_20.png");
    }

    42% {
        background-image: url("../../assets/TopCenter/wrj/wrj_21.png");
    }

    44% {
        background-image: url("../../assets/TopCenter/wrj/wrj_22.png");
    }

   46% {
        background-image: url("../../assets/TopCenter/wrj/wrj_23.png");
    }

   48% {
        background-image: url("../../assets/TopCenter/wrj/wrj_24.png");
    }

   50% {
        background-image: url("../../assets/TopCenter/wrj/wrj_25.png");
    }

   52% {
        background-image: url("../../assets/TopCenter/wrj/wrj_26.png");
    }

    54% {
        background-image: url("../../assets/TopCenter/wrj/wrj_27.png");
    }

    56% {
        background-image: url("../../assets/TopCenter/wrj/wrj_28.png");
    }

    58% {
        background-image: url("../../assets/TopCenter/wrj/wrj_29.png");
    }

    60% {
        background-image: url("../../assets/TopCenter/wrj/wrj_30.png");
    }

    62% {
        background-image: url("../../assets/TopCenter/wrj/wrj_31.png");
    }

    64% {
        background-image: url("../../assets/TopCenter/wrj/wrj_32.png");
    }

    66% {
        background-image: url("../../assets/TopCenter/wrj/wrj_33.png");
    }

    68% {
        background-image: url("../../assets/TopCenter/wrj/wrj_34.png");
    }

    70% {
        background-image: url("../../assets/TopCenter/wrj/wrj_35.png");
    }

    72% {
        background-image: url("../../assets/TopCenter/wrj/wrj_36.png");
    }

    74% {
        background-image: url("../../assets/TopCenter/wrj/wrj_37.png");
    }

    76% {
        background-image: url("../../assets/TopCenter/wrj/wrj_38.png");
    }

    78% {
        background-image: url("../../assets/TopCenter/wrj/wrj_39.png");
    }

    80% {
        background-image: url("../../assets/TopCenter/wrj/wrj_40.png");
    }
      
   82% {
        background-image: url("../../assets/TopCenter/wrj/wrj_41.png");
    }

    84% {
        background-image: url("../../assets/TopCenter/wrj/wrj_42.png");
    }

    86% {
        background-image: url("../../assets/TopCenter/wrj/wrj_43.png");
    }

    88% {
        background-image: url("../../assets/TopCenter/wrj/wrj_44.png");
    }

    90% {
        background-image: url("../../assets/TopCenter/wrj/wrj_45.png");
    }

    92% {
        background-image: url("../../assets/TopCenter/wrj/wrj_46.png");
    }

    94% {
        background-image: url("../../assets/TopCenter/wrj/wrj_47.png");
    }

    96% {
        background-image: url("../../assets/TopCenter/wrj/wrj_48.png");
    }

    98% {
        background-image: url("../../assets/TopCenter/wrj/wrj_49.png");
    }

    100% {
        background-image: url("../../assets/TopCenter/wrj/wrj_50.png");
    }

   
}


#round {
    background:
        url("../../assets/TopCenter/wrj/wrj_1.png"),
        url("../../assets/TopCenter/wrj/wrj_2.png"),
        url("../../assets/TopCenter/wrj/wrj_3.png"),
        url("../../assets/TopCenter/wrj/wrj_4.png"),
        url("../../assets/TopCenter/wrj/wrj_5.png"),
        url("../../assets/TopCenter/wrj/wrj_6.png"),
        url("../../assets/TopCenter/wrj/wrj_7.png"),
        url("../../assets/TopCenter/wrj/wrj_8.png"),
        url("../../assets/TopCenter/wrj/wrj_9.png"),
        url("../../assets/TopCenter/wrj/wrj_10.png"),
        url("../../assets/TopCenter/wrj/wrj_11.png"),
        url("../../assets/TopCenter/wrj/wrj_12.png"),
        url("../../assets/TopCenter/wrj/wrj_13.png"),
        url("../../assets/TopCenter/wrj/wrj_14.png"),
        url("../../assets/TopCenter/wrj/wrj_15.png"),
        url("../../assets/TopCenter/wrj/wrj_16.png"),
        url("../../assets/TopCenter/wrj/wrj_17.png"),
        url("../../assets/TopCenter/wrj/wrj_18.png"),
        url("../../assets/TopCenter/wrj/wrj_19.png"),
        url("../../assets/TopCenter/wrj/wrj_20.png"),
           url("../../assets/TopCenter/wrj/wrj_21.png"),
        url("../../assets/TopCenter/wrj/wrj_22.png"),
        url("../../assets/TopCenter/wrj/wrj_23.png"),
        url("../../assets/TopCenter/wrj/wrj_24.png"),
        url("../../assets/TopCenter/wrj/wrj_25.png"),
        url("../../assets/TopCenter/wrj/wrj_26.png"),
        url("../../assets/TopCenter/wrj/wrj_27.png"),
        url("../../assets/TopCenter/wrj/wrj_28.png"),
        url("../../assets/TopCenter/wrj/wrj_29.png"),
        url("../../assets/TopCenter/wrj/wrj_30.png"),
        url("../../assets/TopCenter/wrj/wrj_31.png"),
        url("../../assets/TopCenter/wrj/wrj_32.png"),
        url("../../assets/TopCenter/wrj/wrj_33.png"),
        url("../../assets/TopCenter/wrj/wrj_34.png"),
        url("../../assets/TopCenter/wrj/wrj_35.png"),
        url("../../assets/TopCenter/wrj/wrj_36.png"),
        url("../../assets/TopCenter/wrj/wrj_37.png"),
        url("../../assets/TopCenter/wrj/wrj_38.png"),
        url("../../assets/TopCenter/wrj/wrj_39.png"),
        url("../../assets/TopCenter/wrj/wrj_40.png"),
        url("../../assets/TopCenter/wrj/wrj_41.png"),
        url("../../assets/TopCenter/wrj/wrj_42.png"),
        url("../../assets/TopCenter/wrj/wrj_43.png"),
        url("../../assets/TopCenter/wrj/wrj_44.png"),
        url("../../assets/TopCenter/wrj/wrj_45.png"),
        url("../../assets/TopCenter/wrj/wrj_46.png"),
        url("../../assets/TopCenter/wrj/wrj_47.png"),
        url("../../assets/TopCenter/wrj/wrj_48.png"),
        url("../../assets/TopCenter/wrj/wrj_49.png"),
        url("../../assets/TopCenter/wrj/wrj_50.png");
    width: 432px;
    height: 246px;
    position: absolute;
    top: 40px;
    left: 4px;
    /*循环播放动画*/
    animation-iteration-count: infinite;
    /*动画名字*/
    animation-name: round_rotate;
    /*动画一周期时间*/
    animation-duration: 4s;
    /*动画周期曲线*/
    animation-timing-function: linear;
    /*动画延迟*/
    /* animation-delay: 2s; */
    /* 调整动画大小 */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 9;
}



@keyframes other_rotate {
    0% {
        background-image: url("../../assets/TopCenter/wx/wx_50.png");
    }

    2% {
        background-image: url("../../assets/TopCenter/wx/wx_1.png");
    }

    4% {
        background-image: url("../../assets/TopCenter/wx/wx_2.png");
    }

   6% {
        background-image: url("../../assets/TopCenter/wx/wx_3.png");
    }

   8% {
        background-image: url("../../assets/TopCenter/wx/wx_4.png");
    }

   10% {
        background-image: url("../../assets/TopCenter/wx/wx_5.png");
    }

   12% {
        background-image: url("../../assets/TopCenter/wx/wx_6.png");
    }

    14% {
        background-image: url("../../assets/TopCenter/wx/wx_7.png");
    }

    16% {
        background-image: url("../../assets/TopCenter/wx/wx_8.png");
    }

    18% {
        background-image: url("../../assets/TopCenter/wx/wx_9.png");
    }

    20% {
        background-image: url("../../assets/TopCenter/wx/wx_10.png");
    }

    22% {
        background-image: url("../../assets/TopCenter/wx/wx_11.png");
    }

    24% {
        background-image: url("../../assets/TopCenter/wx/wx_12.png");
    }

    26% {
        background-image: url("../../assets/TopCenter/wx/wx_13.png");
    }

    28% {
        background-image: url("../../assets/TopCenter/wx/wx_14.png");
    }

    30% {
        background-image: url("../../assets/TopCenter/wx/wx_15.png");
    }

    32% {
        background-image: url("../../assets/TopCenter/wx/wx_16.png");
    }

    34% {
        background-image: url("../../assets/TopCenter/wx/wx_17.png");
    }

    36% {
        background-image: url("../../assets/TopCenter/wx/wx_18.png");
    }

    38% {
        background-image: url("../../assets/TopCenter/wx/wx_19.png");
    }

    40% {
        background-image: url("../../assets/TopCenter/wx/wx_20.png");
    }

    42% {
        background-image: url("../../assets/TopCenter/wx/wx_21.png");
    }

    44% {
        background-image: url("../../assets/TopCenter/wx/wx_22.png");
    }

   46% {
        background-image: url("../../assets/TopCenter/wx/wx_23.png");
    }

   48% {
        background-image: url("../../assets/TopCenter/wx/wx_24.png");
    }

   50% {
        background-image: url("../../assets/TopCenter/wx/wx_25.png");
    }

   52% {
        background-image: url("../../assets/TopCenter/wx/wx_26.png");
    }

    54% {
        background-image: url("../../assets/TopCenter/wx/wx_27.png");
    }

    56% {
        background-image: url("../../assets/TopCenter/wx/wx_28.png");
    }

    58% {
        background-image: url("../../assets/TopCenter/wx/wx_29.png");
    }

    60% {
        background-image: url("../../assets/TopCenter/wx/wx_30.png");
    }

    62% {
        background-image: url("../../assets/TopCenter/wx/wx_31.png");
    }

    64% {
        background-image: url("../../assets/TopCenter/wx/wx_32.png");
    }

    66% {
        background-image: url("../../assets/TopCenter/wx/wx_33.png");
    }

    68% {
        background-image: url("../../assets/TopCenter/wx/wx_34.png");
    }

    70% {
        background-image: url("../../assets/TopCenter/wx/wx_35.png");
    }

    72% {
        background-image: url("../../assets/TopCenter/wx/wx_36.png");
    }

    74% {
        background-image: url("../../assets/TopCenter/wx/wx_37.png");
    }

    76% {
        background-image: url("../../assets/TopCenter/wx/wx_38.png");
    }

    78% {
        background-image: url("../../assets/TopCenter/wx/wx_39.png");
    }

    80% {
        background-image: url("../../assets/TopCenter/wx/wx_40.png");
    }
      
   82% {
        background-image: url("../../assets/TopCenter/wx/wx_41.png");
    }

    84% {
        background-image: url("../../assets/TopCenter/wx/wx_42.png");
    }

    86% {
        background-image: url("../../assets/TopCenter/wx/wx_43.png");
    }

    88% {
        background-image: url("../../assets/TopCenter/wx/wx_44.png");
    }

    90% {
        background-image: url("../../assets/TopCenter/wx/wx_45.png");
    }

    92% {
        background-image: url("../../assets/TopCenter/wx/wx_46.png");
    }

    94% {
        background-image: url("../../assets/TopCenter/wx/wx_47.png");
    }

    96% {
        background-image: url("../../assets/TopCenter/wx/wx_48.png");
    }

    98% {
        background-image: url("../../assets/TopCenter/wx/wx_49.png");
    }

    100% {
        background-image: url("../../assets/TopCenter/wx/wx_50.png");
    }

}


#other{ 
    background:
              url("../../assets/TopCenter/wx/wx_1.png"),
        url("../../assets/TopCenter/wx/wx_2.png"),
        url("../../assets/TopCenter/wx/wx_3.png"),
        url("../../assets/TopCenter/wx/wx_4.png"),
        url("../../assets/TopCenter/wx/wx_5.png"),
        url("../../assets/TopCenter/wx/wx_6.png"),
        url("../../assets/TopCenter/wx/wx_7.png"),
        url("../../assets/TopCenter/wx/wx_8.png"),
        url("../../assets/TopCenter/wx/wx_9.png"),
        url("../../assets/TopCenter/wx/wx_10.png"),
        url("../../assets/TopCenter/wx/wx_11.png"),
        url("../../assets/TopCenter/wx/wx_12.png"),
        url("../../assets/TopCenter/wx/wx_13.png"),
        url("../../assets/TopCenter/wx/wx_14.png"),
        url("../../assets/TopCenter/wx/wx_15.png"),
        url("../../assets/TopCenter/wx/wx_16.png"),
        url("../../assets/TopCenter/wx/wx_17.png"),
        url("../../assets/TopCenter/wx/wx_18.png"),
        url("../../assets/TopCenter/wx/wx_19.png"),
        url("../../assets/TopCenter/wx/wx_20.png"),
           url("../../assets/TopCenter/wx/wx_21.png"),
        url("../../assets/TopCenter/wx/wx_22.png"),
        url("../../assets/TopCenter/wx/wx_23.png"),
        url("../../assets/TopCenter/wx/wx_24.png"),
        url("../../assets/TopCenter/wx/wx_25.png"),
        url("../../assets/TopCenter/wx/wx_26.png"),
        url("../../assets/TopCenter/wx/wx_27.png"),
        url("../../assets/TopCenter/wx/wx_28.png"),
        url("../../assets/TopCenter/wx/wx_29.png"),
        url("../../assets/TopCenter/wx/wx_30.png"),
        url("../../assets/TopCenter/wx/wx_31.png"),
        url("../../assets/TopCenter/wx/wx_32.png"),
        url("../../assets/TopCenter/wx/wx_33.png"),
        url("../../assets/TopCenter/wx/wx_34.png"),
        url("../../assets/TopCenter/wx/wx_35.png"),
        url("../../assets/TopCenter/wx/wx_36.png"),
        url("../../assets/TopCenter/wx/wx_37.png"),
        url("../../assets/TopCenter/wx/wx_38.png"),
        url("../../assets/TopCenter/wx/wx_39.png"),
        url("../../assets/TopCenter/wx/wx_40.png"),
        url("../../assets/TopCenter/wx/wx_41.png"),
        url("../../assets/TopCenter/wx/wx_42.png"),
        url("../../assets/TopCenter/wx/wx_43.png"),
        url("../../assets/TopCenter/wx/wx_44.png"),
        url("../../assets/TopCenter/wx/wx_45.png"),
        url("../../assets/TopCenter/wx/wx_46.png"),
        url("../../assets/TopCenter/wx/wx_47.png"),
        url("../../assets/TopCenter/wx/wx_48.png"),
        url("../../assets/TopCenter/wx/wx_49.png"),
        url("../../assets/TopCenter/wx/wx_50.png");
    width: 432px;
    height: 246px;
    position: absolute;
    top: 40px;
    left: 4px;
    /*循环播放动画*/
    animation-iteration-count: infinite;
    /*动画名字*/
    animation-name: other_rotate;
    /*动画一周期时间*/
    animation-duration: 3s;
    /*动画周期曲线*/
    animation-timing-function: linear;
    /*动画延迟*/
    /* animation-delay: 2s; */
    /* 调整动画大小 */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 9;
}



.title-container {
    width: 432px;
    height: 30px;
    background-image: url("../../assets/UpperLeft/编组备份@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
}

.nav {
    width: 492px;
    height: 100px;
    position: absolute;
    top: 220px;
    z-index: 999
}

.nav .el-col {
    line-height: 30px;
    text-align: left;
}

.point{
    display:inline-block;
    width: 4px;
    height: 4px;
    /* line-height: 20px; */
    margin-bottom:4px;
    margin-right: 10px;
    background: #FFC147;
    box-shadow: 0px 0px 6px 0px #FFBF14, 0px 0px 4px 0px #FFBF14;
}
.title {
    font-size: 14px;
    height: 14px;
    font-family: MicrosoftYaHei;
    color: #D1FEE9;
    line-height: 14px;
    text-shadow: 0px 0px 5px rgba(209, 254, 233, 0.5000);
}

.name {
    font-size: 14px;
    height: 14px;
    font-family: MicrosoftYaHei;
    color: #FFC147;
    line-height: 14px;
    text-shadow: 0px 0px 5px #FFC147;
}

.title-zh {
    width: calc(100% - 34px);
    height: 18px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #d1fee9;
    line-height: 18px;
    position: absolute;
    top: 11px;
    left: 34px;
    text-align: left;
}

.title-en {
    width: calc(100% - 152px);
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #c7fdf3;
    line-height: 12px;
    position: absolute;
    top: 18px;
    left: 133px;
    text-align: left;
    opacity: 0.5;
}

.img-container {
    height: 286px;
    width: 480px;
    background-image: url("../../assets/UpperLeft/文案bg@2x.png");
    margin-top: 30px;
    margin-left: -24px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
</style>