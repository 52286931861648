<template>
  <div class="container">
    <div class="title-container">
      <div class="title-zh">项目碳汇价值趋势</div>
      <div class="title-en">CARBON SINK VALUE TREND GRAPH</div>
    </div>
    <div class="img-container">
      <div id="center-chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { toRaw } from 'vue'
export default {
  components: {},
  data() {
    return {
       end:3,
      timeOut: null,
      charOption1:null,
   axisArr:[],
   dataArr:[],
       // 项目一8
 vaccineYears:["2022"],
      vaccineList: [0],
      // 项目二26
       vaccineYears1:["2006","2007","2008","2009","2010","2011","2012","2013","2014","2015"],
    vaccineList1: [1547678.6,3095357.2,4643035.8, 6190714.4,7738393,9286071.6,10833750.2,12381428.8,13929107.4,15476786],
    
      // 项目三5
       vaccineYears2:["2010","2011","2012","2013","2014","2015","2016"],
       vaccineList2: [538875.3, 1077750.6,1616625.9, 2155501.2,2694376.5, 3233251.8,3772127.1],
     
      myChartOne: null
    //   vaccineList1: [40, 20, 35, 25, 40],
    //   vaccineList2: [33, 14, 25, 35, 15],
    };
  },
  mounted() {
    this.axisArr =this.vaccineYears
      this.dataArr = this.vaccineList
   
    this.drawVaccination();
  },
  computed:{
    getProjectType(){
      return this.$store.state.projectType
    }
  },
   watch:{
    getProjectType(newVal){
      if(newVal == 1){
       this.axisArr =this.vaccineYears
      this.dataArr = this.vaccineList
        this.drawVaccination();
      }else if(newVal == 2){
        this.axisArr = this.vaccineYears1
        this.dataArr = this.vaccineList1
        this.drawVaccination();
      }else if(newVal == 3){
        this.axisArr = this.vaccineYears2
       this.dataArr = this.vaccineList2
        this.drawVaccination();
      }
    }
  },
  methods: {
    drawVaccination() {
      // var echarts = require('echarts');
      // if(this.myChartOne){
      //   this.myChartOne.clear(); //清空图表
      //   this.myChartOne.dispose(); //释放图表组件
      //   this.myChartOne = null;
      // }
       let that = this;
      document.getElementById("center-chart").removeAttribute("_echarts_instance_")
      this.myChartOne = this.$echarts.init(document.getElementById("center-chart"));
      this.charOption1 = {
       grid: {
          x: 10,
          x2: 10,
          y2: -1,
          top: "15%",
          containLabel: true,
        },
           tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          width: 48,
          height: 12,
          lineHeight: 12,
          confine: true,
          textStyle: {
            color: "#D1FEE9",
            fontSize: "12",
            fontFamily: "MicrosoftYaHei",
            align: "left",
          },
          // extraCssText:'background: linear-gradient(270deg, #1D4A63 0%, #0F244D 100%);',
          // extraCssText:'background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.61) 50%,rgba(0, 255, 135, 0.1) 100%);',
          backgroundColor: " rgba(0, 0, 0, 0.8) ",
          borderColor: "#00FF87", //tooltip边框颜色
          borderWidth: 1,
          formatter: (params) => {
            let str = "";
            let titleStr = "";
            let bg =
              '<span style="z-index:-1;display:inline-block;position:absolute;top:0;left:0;border-radius:10px;width:100%;height:100%;background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.51) 50%,rgba(0, 255, 135, 0.1) 100%);"></span>';
            for (let i = 0; i < params.length; i++) {
              // #3FBF7A 绿
              // #FFBF61 黄
              let textColor = ["#3FBF7A", "#FFBF61"];
              let markerStr = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${textColor[i]}"></span>`;
              let value = `<span style="color:${textColor[i]}">${params[i].value}</span>`;
              // console.log(params[i]);
              titleStr = params[i].name + "<br/>";
              str +=
                markerStr + " " + params[i].seriesName + ": " + value + " 元<br/>";
            }
            // return titleStr + str
            return str + bg;
          },
        },
        xAxis: [
          {
            type: "category",
            data: toRaw(this.axisArr),
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              // 设置Y轴的label
              formatter: "{value} ",
              color: "#F2F2F2",
              fontFamily: "DINPro-CondensedBold, DINPro",
            },
            axisLine: {
              lineStyle: {
                color: "#598FF3",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // min: 0,
            // max: 100,
            // interval: 20,
            axisLabel: {
              // 设置Y轴的label
              formatter: "{value} 元",
              color: "#F2F2F2",
              fontFamily: "DINPro-CondensedBold, DINPro",
            },
            splitLine: {
              //  设置Y轴的线条
              // show: false,
              lineStyle: {
                color: "#E7E7E7",
                type: [10, 10],
                dashOffset: 1,
                opacity: 0.2,
              },
            },
          },
        ],
         dataZoom: [
          {
            show: false,
            type: "slider",
            // realtime: true,
            startValue: 0, // 从头开始。
            endValue: toRaw(this.end), //
          },
          //  {
          //    show: true,
          //    realtime: true,
          //    height: 24,//这里可以设置dataZoom的尺寸
          //    bottom: 6,
          //    start: 0,
          //    end: 100,
          //    backgroundColor: '#1d76e6', // 填充颜色
          //    textStyle: {
          //     color: '#fff' // 左右两边文字颜色
          //   }
          //  }
        ],
        series: [
          {
            name: "碳汇价值",
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              formatter: "{c}元",
              textStyle: {
                //数值样式
                color: "#BAA270",
                fontSize: 12,
                fontFamily: "MicrosoftYaHei",
              },
            },
            color: "#FFFFFF", // 内线颜色 含拐点
            // areaStyle: {
            //     opacity: 0.6,
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         {
            //             offset: 0,
            //             color: '#00FF67'
            //         },
            //         {
            //             offset: 1,
            //             color: '#1D3A12'
            //         }
            //     ])
            // },
            backgroundStyle: {
              color: "rgba(2, 132, 71, 0.2)",
            },
            barGap: "50%",
            // barCategoryGap: "40%",
            barWidth: "24",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(17, 136, 136, 0.8)" },
                { offset: 0.5, color: "rgba(1, 87, 46, 0.49)" },
                { offset: 1, color: "rgba(137, 255, 58, 0.5)" },
              ]),
              borderRadius: 2,
            },
            emphasis: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#FFC147",
                  fontSize: 14,
                  fontFamily: "MicrosoftYaHei",
                  shadowColor: " #FFC147",
                  shadowBlur: 5,
                },
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(17, 136, 136, 0.8)" },
                  { offset: 0.5, color: "rgba(1, 87, 46, 0.49)" },
                  { offset: 1, color: "rgba(137, 255, 58, 0.5)" },
                ]),
                borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "rgba(17, 136, 136, 0.8)" },
                  { offset: 0.5, color: "rgba(1, 87, 46, 0.49)" },
                  { offset: 0, color: "rgba(137, 255, 58, 0.5)" },
                ]),
                borderType: "solid",
                borderWidth: 1,
                // shadowOffsetX: 0,
                // shadowOffsetY: 0,
                // shadowColor: '#00FF87',
                // shadowBlur: 4,
              },
            },
            data:toRaw(this.dataArr), 
            // smooth: true, //开启平滑
            // symbol: "circle", // 拐点样式
            // symbolSize: 8, // 拐点大小
            // showSymbol: true, // 是否显示拐点
          },
        ],
      };
       this.myChartOne.on("mouseover", this.stop);
      this.myChartOne.on("mouseout", this.goMove);
      window.addEventListener("resize", ()=> {
        setTimeout( () =>{
          this.myChartOne.resize();
        }, 350);
      });  
           this.autoMove();
    },
    //自动滚动
    autoMove() {
      let that = this;
      console.log("12312312312321");
      toRaw(that.myChartOne).setOption(toRaw(that.charOption1));
      //this.dataList.seriesData为柱形图数据
      if (that.timeOut == null) {
        that.timeOut = setInterval(() => {
          // clearInterval(this.timeOut)
          // 每次向后滚动一个，最后一个从头开始。
          // if(this.stopMove){ return }

         this.axisArr.push(toRaw(this.axisArr.shift()));
          // this.data2.push(toRaw(this.data2.shift()));
         this.dataArr.push(toRaw(this.dataArr.shift()));
        
          

          toRaw(that.myChartOne).setOption(toRaw(that.charOption1));
        }, 3000);
      }
    },
    //停止滚动
    stop() {
      console.log(11);
      // this.stopMove=true
      clearInterval(this.timeOut);
      this.timeOut = null;
    },
    //继续滚动
    goMove() {
      console.log(333333);
      //  this.stopMove=false
      this.autoMove();
    },
  },
};
</script>

<style scoped>
.container {
  width: 432px;
  height: 640px;
  position: absolute;
  top: 430px;
  left: 24px;
  z-index: 999;
}

.title-container {
  width: 432px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/编组备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}

.title-zh {
  width: calc(100% - 34px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 18px;
  position: absolute;
  top: 11px;
  left: 34px;
  text-align: left;
}

.title-en {
  width: calc(100% - 152px);
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #c7fdf3;
  line-height: 12px;
  position: absolute;
  top: 18px;
  left: 223px;
  text-align: left;
  opacity: 0.5;
}

.img-container {
  /* height: 286px;
  width: 480px; */
  /* background-image: url("../../assets/UpperLeft/文案bg@2x.png"); */
  /* margin-top: 30px;
  margin-left: -24px;
  background-size: 100% 100%;
  background-repeat: no-repeat; */
}

#center-chart {
  width: 480px !important;
  height: 286px !important;
  margin-top: 30px;
  margin-left: -24px;
}

.blog_news_box {
  position: absolute;
  width: 412px;
  height: 224px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 16px;
  margin-top: 32px;
  margin-left: 34px;
  overflow: hidden;
}

/* .ul-scoll li{
                margin: 6px;
                padding: 5px;
                background: rgba(198, 142, 226, 0.4);
            } */
</style>