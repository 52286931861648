<template>
<div class="home-map-out-container">
  <div class='home-map-container'>
    <!-- <div class="top-title"> {{'南平市'}}</div> -->
    <div class="top-title" @click="emitMapZoom"> {{areaObj.area}}</div>
    <div class="mid-container">
      <div class="left">
        <div class="left-img"></div>
        <div class="left-title">项目总数</div>
        <div class="left-content" v-if="areaObj.count == 0">
          开发中
          <!-- {{999}} -->
        </div>
        <div class="left-content" v-else>
          {{areaObj.count}}
          <!-- {{999}} -->
          <div class="unit">个</div>
        </div>
      </div>
      <div class="right">
        <div class="right-img"></div>
        <div class="right-title">碳减排量</div>
        <div class="right-content" v-if="areaObj.total_amount == 0">
          开发中
         <!-- {{95890}} -->
        </div>
        <div class="right-content" v-else>
         {{areaObj.total_amount}}
         <!-- {{95890}} -->
          <div class="unit">tCO2-e</div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>
<script>

export default {
  props:{
    areaObj:{
      type: Object,
      default(){
        return {}
      }
    },
  },
  components:{
  },
  data() {
    return {

    }

    },
  mounted(){
  },
  methods:{
    showHomeMap(){
      this.$store.commit("SET_MAP_STATE", true)
      this.$store.commit("SET_ROTATE_DIALOG_STATE", false)
    },
    emitMapZoom(){
      this.$emit('emitMapZoom')
    }
  }

};
</script>

<style scoped>
.home-map-out-container{
  display: none;
}
.home-map-container {
  width: 364px;
  height: 126px;
  background-image: url("../../assets/AreaDialog/弹窗1bg@2xhomemap.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 999;
  position: absolute;
  /* top: 500px; */
  /* left: 900px; */
}
.sub-container{
  background-image: url("../../assets/HomeMap/弹窗1bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 364px !important;
  /* background-color: red !important; */
  display: none;
  position: absolute;
}
.top-title {
  width: 100%;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #d1fee9;
  line-height: 18px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.mid-container {
  width: calc(100% - 9px);
  margin-top: 24px;
  display: flex;
  padding-left: 9px;
  /* padding-right: 9px; */
  justify-content: space-between;
}
.left {
  width: 134px;
  height: 93px;
  position: relative;
  /* background-color: #fff; */
}
.left-img {
  width: 56px;
  height: 46px;
  background-image: url("../../assets/UpperLeft/projectIconLarge.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.left-title {
  width: 100px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #d1fee9;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 62px;
  text-align: left;
}
.left-content {
  width: 100px;
  height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffc147;
  line-height: 24px;
  text-shadow: 0px 0px 2px #ffbf14;
  text-align: left;
  display: flex;
  position: absolute;
  top: 23px;
  left: 62px;
}
.unit {
  height: 24px;
  font-size: 12px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffc147;
  line-height: 28px;
  text-shadow: 0px 0px 2px #ffbf14;
  margin-left: 2px;
}
.right {
  width: 183px;
  height: 93px;
  position: relative;
}
.right-img {
  width: 56px;
  height: 49px;
  background-image: url("../../assets/UpperLeft/projectIconLarge.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.right-title {
  width: 100px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #d1fee9;
  line-height: 18px;
  text-align: left;
  position: absolute;
  left: 62px;
  top: 0;
}
.right-content {
  width: 121px;
  height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffc147;
  line-height: 24px;
  text-shadow: 0px 0px 2px #ffbf14;
  display: flex;
  text-align: left;
  position: absolute;
  top: 23px;
  left: 62px;
}
</style>>
