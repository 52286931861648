<template>
  <div class="HomeMap">
    <div id="container"></div>
    <div id="info-window-box">
      <div class="info-window-top">
        <div class="top-title">{{cityProjectObj.title}}</div>
      </div>
      <div class="info-window-middle">
        <div class="middle-unit">
          <div class="middle-unit-left">
            <div class="middle-unit-img"></div>
            <div class="middle-unit-title">项目业主</div>
          </div>
          <div class="middle-unit-right">
            <div class="middle-unit-content">{{cityProjectObj.owner}}</div>
          </div>
        </div>
        <div class="middle-unit">
          <div class="middle-unit-left">
            <div class="middle-unit-img-two"></div>
            <div class="middle-unit-title">项目属地</div>
          </div>
          <div class="middle-unit-right">
            <div class="middle-unit-content" v-if="$store.state.bdMapZoom == 1">{{cityProjectObj.Territory}}</div>
            <div class="middle-unit-content" v-else>{{cityProjectObj.subTerritory}}</div>
          </div>
        </div>
      </div>
      <div class="info-window-bottom">
        <div class="info-window-bottom-unit-container">
          <div class="info-window-bottom-unit">
            <div class="info-window-bottom-point"></div>
            <div class="info-window-bottom-title">项目计入期：</div>
            <div class="info-window-bottom-content">{{cityProjectObj.creditingPeriod}}</div>
          </div>
          <div class="info-window-bottom-unit">
            <div class="info-window-bottom-point"></div>
            <div class="info-window-bottom-title">第一监测期：</div>
            <div class="info-window-bottom-content">{{cityProjectObj.monitoringPeriod}}</div>
          </div>
          <div class="info-window-bottom-unit">
            <div class="info-window-bottom-point"></div>
            <div class="info-window-bottom-title">第一监测期减排量：</div>
            <div class="info-window-bottom-content">{{cityProjectObj.emissionReduction}}tCO2-e</div>
          </div>
        </div>
        <!-- <div class="info-window-bottom-unit-container">
          <div class="info-window-bottom-unit">
            <div class="info-window-bottom-point"></div>
            <div class="info-window-bottom-title">开工月份：</div>
            <div class="info-window-bottom-content">2022-03</div>
          </div>
          <div class="info-window-bottom-unit">
            <div class="info-window-bottom-point"></div>
            <div class="info-window-bottom-title">建设年限：</div>
            <div class="info-window-bottom-content">2022-2023</div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="normal-map-tip" v-show="$store.state.bdMapType == 'B_NORMAL_MAP'">
      <div class="normal-map-tip-title" v-if="!isCarbon">冠层高度（米）</div>
      <div class="normal-map-tip-title" v-else>碳储量密度（吨/公顷）</div>
      <!-- 刻度 0-14  24  40-->
      <!-- 冠层 -->
      <!-- 刻度 0-15  24  40-->
      <div class="normal-map-tip-scale-container-zero">
        <div class="normal-map-tip-scale-line"></div>
        <div class="normal-map-tip-scale-content">0</div>
      </div>
      <div class="normal-map-tip-scale-container-half">
        <div class="normal-map-tip-scale-line"></div>
        <div class="normal-map-tip-scale-content">20</div>
      </div>
      <div class="normal-map-tip-scale-container-full">
        <div class="normal-map-tip-scale-line"></div>
        <div class="normal-map-tip-scale-content">40</div>
      </div>
      <div class="normal-map-tip-bar"></div>
    </div>
    <div class="drawer-container">
      <div class="drawer-left" @click="handleChangeDrawer">
        <div class="drawer-left-img"></div>
        <div class="drawer-left-content" v-if="$store.state.bdMapZoom == 3">项目切换</div>
        <div class="drawer-left-content" v-else>地图切换</div>
      </div>
      <div class="drawer-right" id="drawer-right">
        <div class="drawer-content-container" id="drawer-content-container">
            <div class="drawer-content-unit" v-if="$store.state.bdMapZoom == 3" @click="toProjectOne()">
             2022碳中和林项目
            </div>
            <div class="drawer-content-unit" v-else @click="handleSatelliteMap()">
              项目展示
            </div>
            <div class="drawer-content-unit-line"></div>
            <div class="drawer-content-unit" v-if="$store.state.bdMapZoom == 3" @click="toProjectTwo()">
            顺昌县国有林场森林经营碳汇项目
            </div>
            <div class="drawer-content-unit" v-else @click="handleCarbonMap()">
              <!-- 碳密度展示 -->
              冠层高度
              <!-- 加标注柱状图 蓝-》黄  冠层高度单位（米） -->
            </div>
            <div class="drawer-content-unit-line-two"></div>
            <div class="drawer-content-unit" v-if="$store.state.bdMapZoom == 3" @click="toProjectThree()">
              顺昌县国有林场竹林项目
            </div>
            <div class="drawer-content-unit" v-else @click="handleSpotMap()">
              <!-- 卫星光斑展示 -->
              碳储量密度
              <!-- 加标注柱状图 蓝-》黄  碳储量密度单位（吨/公顷） -->
            </div>
      
        </div>
        <div
          class="back-fisso-position"
          @click="handlePosition()"
        >
        </div>
      </div>
    </div>
    <div class="back-map-zoom" @click="handleBackZoom" v-show="showBackButton"></div>
      <!-- v-show="$store.state.isShowMapInfo" -->
    <ProjectDialog ref="AreaDialog" :isShowBottom="isShowBottom" :projectObj="littleBanProjectObj"></ProjectDialog>
    <AreaDialog ref="AreaDialogCity" :areaObj="areaObj" @emitMapZoom="activeMapZoom"></AreaDialog>
  </div>
</template>

<script>
// @ is an alias to /src
import styleJsonTwo from "../../assets/mapJson/custom_map_config_two.json";
import * as mapvgl from "../../utils/home-map/mapvgl.min.js";
import $ from "jquery";
import AreaDialog from "../AreaDialog/AreaDialog.vue"
import {getProjectInfo} from "../../api/request.js"
import axios from 'axios'
import {toRefs,toRef,toRaw} from 'vue';
import ProjectDialog from '../ProjectDialog/ProjectDialog.vue';
import tree0 from "../../../public/littleBanTreeJson/1.json"
import tree1 from "../../../public/littleBanTreeJson/2.json"
import tree2 from "../../../public/littleBanTreeJson/3.json"
import tree3 from "../../../public/littleBanTreeJson/4.json"
import tree4 from "../../../public/littleBanTreeJson/5.json"
import tree5 from "../../../public/littleBanTreeJson/6.json"
import tree6 from "../../../public/littleBanTreeJson/7.json"
import tree7 from "../../../public/littleBanTreeJson/8.json"

export default {
  name: "HomeMap",
  components:{
    AreaDialog,
    ProjectDialog
  },
  data() {
    return {
      MidMap: null, //地图
      width: window.innerWidth,
      height: window.innerHeight,
      isShow3D: false,
      mobilePerspective: null, //视角移动相关动画
      mobilePerspectiveBack: null, //视角移动相关动画
       slsxList: [],
      // areaNameArr: [
      //   "光泽县",
      //   "邵武市",
      //   "顺昌县",
      //   "建阳区",
      //   "武夷山市",
      //   "建瓯市",
      //   "延平区",
      //   "浦城县",
      //   "松溪县",
      //   "政和县",
      //   "武夷新区",
      //   "南平工业园区",
      //   "荣华山产业组团"
      // ],
      cityArr: [
        {
          name:'南平市',
          icon:require("../../assets/HomeMap/city/南平市.png")
        },
        {
          name:'宁德市',
          icon:require("../../assets/HomeMap/city/宁德市.png")
        },
        {
          name:'福州市',
          icon:require("../../assets/HomeMap/city/福州市.png")
        },
        {
          name:'福建三明市',
          icon:require("../../assets/HomeMap/city/三明市.png")
        },
        {
          name:'莆田市',
          icon:require("../../assets/HomeMap/city/莆田市.png")
        },
        {
          name:'泉州市',
          icon:require("../../assets/HomeMap/city/泉州市.png")
        },
        {
          name:'厦门市',
          icon:require("../../assets/HomeMap/city/厦门市.png")
        },
        {
          name:'漳州市',
          icon:require("../../assets/HomeMap/city/漳州市.png")
        },
        {
          name:'龙岩市',
          icon:require("../../assets/HomeMap/city/龙岩市.png")
        },
      ],
      cityArrTwo: [
        {
          name: '顺昌县',
          icon: require('../../assets/HomeMap/county/顺昌县.png'),
          area: '顺昌县',
          count: '2',
          total_amount: '274243'
        },
        {
          name: '光泽县',
          icon: require('../../assets/HomeMap/county/光泽县.png'),
          area: '光泽县',
          count: '0',
          total_amount: '0'
        },
        {
          name: '武夷山市',
          icon: require('../../assets/HomeMap/county/武夷山市.png'),
          area: '武夷山市',
          count: '1',
          total_amount: '125049'
        },
        {
          name: '浦城县',
          icon: require('../../assets/HomeMap/county/浦城县.png'),
          area: '浦城县',
          count: '0',
          total_amount: '0'
        },
        {
          name: '松溪县',
          icon: require('../../assets/HomeMap/county/松溪县.png'),
          area: '松溪县',
          count: '0',
          total_amount: '0'
        },
        {
          name: '建阳区',
          icon: require('../../assets/HomeMap/county/建阳区.png'),
          area: '建阳区',
          count: '0',
          total_amount: '0'
        },

        {
          name: '邵武市',
          icon: require('../../assets/HomeMap/county/邵武市.png'),
          area: '邵武市',
          count: '0',
          total_amount: '0'
        },
        {
          name: '建瓯市',
          icon: require('../../assets/HomeMap/county/建瓯市.png'),
          area: '建瓯市',
          count: '0',
          total_amount: '0'
        },
        {
          name: '延平区',
          icon: require('../../assets/HomeMap/county/延平区.png'),
          area: '延平区',
          count: '0',
          total_amount: '0'
        },
        {
          name: '政和县',
          icon: require('../../assets/HomeMap/county/政和县.png'),
          area: '政和县',
          count: '1',
          total_amount: '67407'
        },
      ],
      areaNameArr: [
        {
          name: '顺昌县',
          icon: require('../../assets/HomeMap/sc.png')
        },
        {
          name: '延平区',
          icon: require('../../assets/HomeMap/yp.png')
        },
        {
          name: '建阳区',
          icon: require('../../assets/HomeMap/jy.png')
        },
        {
          name: '邵武市',
          icon: require('../../assets/HomeMap/sw.png')
        },
        {
          name: '武夷山市',
          icon: require('../../assets/HomeMap/wys.png')
        },
        {
          name: '建瓯市',
          icon: require('../../assets/HomeMap/jo.png')
        },

        {
          name: '浦城县',
          icon: require('../../assets/HomeMap/pc.png')
        },
        {
          name: '光泽县',
          icon: require('../../assets/HomeMap/gz.png')
        },
        {
          name: '松溪县',
          icon: require('../../assets/HomeMap/sx.png')
        },
        {
          name: '政和县',
          icon: require('../../assets/HomeMap/zh.png')
        },
        {
          name: '武夷新区',
          icon: require('../../assets/HomeMap/wyxq.png')
        },
        {
          name: '南平工业园区',
          icon: require('../../assets/HomeMap/npgyy.png')
        },
        {
          name: '荣华山产业组团',
          icon: require('../../assets/HomeMap/rhs.png')
        },
      ],
      cityProjectNameArr: [
        {
          // name:'顺昌县国有林场森林经营碳汇项目',
          name:'顺昌县云际',
          owner:'顺昌县国有林场', //项目业主
          icon:require('../../assets/HomeMap/cityProject/8.3顺昌.png'),
           title:'顺昌县国有林场森林经营碳汇项目',//项目名称
          content: "4608.1hm2",//项目概括
          creditingPeriod: "2006/11/1-2026/10/31",//项目计入期
          monitoringPeriod:"2006/11/1-2016/10/31",//第一监测期
          emissionReduction:"154828",//第一监测期减排量
          certificationAuthority:"北京中创碳投科技有限公司",//第三方核证机构
          Territory:"南平市",
          subTerritory:"顺昌县",
        },
        {
          name:'顺昌县琇坑',
          owner:'顺昌县国有林场', //项目业主
          icon:require('../../assets/HomeMap/cityProject/8.4顺昌.png'),
           title:'顺昌县国有林场竹林经营碳汇项目',//项目名称
          content: "2278.5hm2",//项目概括
          creditingPeriod: "2010/1/15-2040/1/14",//项目计入期
          monitoringPeriod:"2010/1/15-2017/1/14",//第一监测期
          emissionReduction:"119415",//第一监测期减排量
          certificationAuthority:"北京中创碳投科技有限公司",//第三方核证机构
          Territory:"南平市",
          subTerritory:"顺昌县",
        },
        {
          name:'福建省政和县对洋',
          owner:'福建省政和县林业公司', //项目业主
          icon:require('../../assets/HomeMap/cityProject/8.1政和.png'),
          title:'福建省政和县林业总公司森林经营碳汇项目',//项目名称
          content: "项目位于政和县境内，采用经国家发展改革委备案的方法学开发，经营面积为2256.05公顷。",//项目概括
          creditingPeriod: "2009/1/1-2028/12/31",//项目计入期
          monitoringPeriod:"2009/1/1-2017/7/31",//第一监测期
          emissionReduction:"67407",//第一监测期减排量
          certificationAuthority:"中国林业科学研究院林业科技信息研究所",//第三方核证机构
          Territory:"南平市",
          subTerritory:"政和县",
        },
        {
          name:'武夷山市茅塔',
          owner:'武夷山市科技试验林场', //项目业主
          icon:require('../../assets/HomeMap/cityProject/8.2武夷山.png'),
           title:'武夷山市科技试验林场森林经营碳汇项目',//项目名称
          content: "项目位于武夷山市境内，采用经国家发展改革委备案的方法学开发，经营面积为3671.43公顷。",//项目概括
          creditingPeriod: "2007/1/1-2026/12/31",//项目计入期
          monitoringPeriod:"2007/1/1-2017/8/18",//第一监测期
          emissionReduction:"125049",//第一监测期减排量
          certificationAuthority:"北京中创碳投科技有限公司",//第三方核证机构
          Territory:"南平市",
          subTerritory:"武夷山市",
        },
        {
          name:'永泰县', //地图检索项
          owner:'永泰县国有林管理站', //项目业主
          icon:require('../../assets/HomeMap/cityProject/1福州.png'),//项目图标
          title:'永泰县国有林管理站森林经营碳汇项目',//项目名称
          content: "项目位于福州市永泰县境内，采用经国家发展改革委备案的方法学开发，经营面积为2722hm2。",//项目概括
          creditingPeriod: "2006/2/18-2026/2/17",//项目计入期
          monitoringPeriod:"2006/2/18-2017/7/17",//第一监测期
          emissionReduction:"115960",//第一监测期减排量
          certificationAuthority:"中国林业科学研究院林业科技信息研究所",//第三方核证机构
          Territory:"福州市",
          subTerritory:"福州市",
        },
        {
          name:'安溪县林业局',
          owner:'安溪县林业局', //项目业主
          icon:require('../../assets/HomeMap/cityProject/3泉州.png'),
          title:'福建省安溪县森林经营碳汇项目',//项目名称
          content: "项目位于泉州市安溪县境内，采用经国家发展改革委备案的方法学开发，经营面积为3473.6hm2。",//项目概括
          creditingPeriod: "2007/10/1-2027/9/30",//项目计入期
          monitoringPeriod:"2007/10/1-2017/9/30",//第一监测期
          emissionReduction:"101746",//第一监测期减排量
          certificationAuthority:"北京中创碳投科技有限公司",//第三方核证机构
       Territory:"泉州市",
          subTerritory:"泉州市",
       },
        // {
        //   name:'福建省漳平五一国有林场',
        //   owner:'福建省漳平五一国有林场', //项目业主
        //   icon:require('../../assets/HomeMap/cityProject/5漳州.png'),
        //   title:'福建省漳平五一国有林场森林经营碳汇项目',//项目名称
        //   content: "项目位于漳平县境内，采用经国家发展改革委备案的方法学开发，经营面积为4493.2公顷。",//项目概括
        //   creditingPeriod: "2007/1/1-2026/12/31",//项目计入期
        //   monitoringPeriod:"2007/1/1-2017/8/31",//第一监测期
        //   emissionReduction:"208390",//第一监测期减排量
        //   certificationAuthority:"北京中创碳投科技有限公司",//第三方核证机构
        // Territory:"龙岩市"
        // },
        {
          name:'福建省长汀楼子坝国有林场',
          owner:'福建省长汀楼子坝国有林场', //项目业主
          icon:require('../../assets/HomeMap/cityProject/6龙岩.png'),
         title:'长汀楼子坝国有林场竹林经营碳汇项目',//项目名称
          content: "项目位于长汀县境内，采用经国家发展改革委备案的方法学开发，经营面积为2625.93公顷。",//项目概括
          creditingPeriod: "2005/10/1-2025/9/30",//项目计入期
          monitoringPeriod:"2005/10/1-2017/9/30",//第一监测期
          emissionReduction:"148993.65",//第一监测期减排量
          certificationAuthority:"中国林业科学研究院林业科技信息研究所",//第三方核证机构
        Territory:"龙岩市",
          subTerritory:"龙岩市",
        },
        {
          name:'建宁县',
          owner:'建宁县林业建设投资公司', //项目业主
          icon:require('../../assets/HomeMap/cityProject/7三明.png'),
           title:'建宁县林业建设投资公司森林经营碳汇项目',//项目名称
          content: "项目位于建宁县境内，采用经国家发展改革委备案的方法学开发，经营面积为4275.32公顷。",//项目概括
          creditingPeriod: "2012/1/1-2031/12/31",//项目计入期
          monitoringPeriod:"2012/1/1-2017/9/30",//第一监测期
          emissionReduction:"55143",//第一监测期减排量
          certificationAuthority:"中国林业科学研究院林业科技信息研究所",//第三方核证机构
        Territory:"三明市",
          subTerritory:"三明市",
        },
        {
          name:'福建省周宁腊洋国有林场',
          owner:'福建省周宁腊洋国有林场', //项目业主
          icon:require('../../assets/HomeMap/cityProject/9宁德.png'),
          title:'福建省周宁腊洋国有林场森林经营碳汇项目',//项目名称
          content: "项目位于宁德市周宁县、古田县、寿宁县境内，采用经国家发展改革委备案的方法学开发，经营面积为4268.27hm2。",//项目概括
          creditingPeriod: "2007/9/1-2027/8/31",//项目计入期
          monitoringPeriod:"2007/9/1-2017/8/31",//第一监测期
          emissionReduction:"136492",//第一监测期减排量
          certificationAuthority:"中国林业科学研究院林业科技信息研究所",//第三方核证机构
        Territory:"宁德市",
          subTerritory:"宁德市",
        },
      ],
      cityProjectObj:{},  // 省 项目对象
      cMapZoom: 8,    //地图缩放
      cMapPoint: new BMapGL.Point(118.120427, 26.031749),    // 地图中心点
      cMapFissoZoom: 8,    //地图初始缩放
      cMapFissoPoint: new BMapGL.Point(118.120427, 26.031749),    // 地图初始中心点
      areaObj: {},
      areaObjArr: [
        {
          area:"南平市",
          count:"4",
          total_amount:"466699"
        },
        {
          area:"宁德市",
          count:"2",
          total_amount:"219485"
        },
        {
          area:"福州市",
          count:"2",
          total_amount:"188702"
        },
        {
          area:"三明市",
          count:"3",
          total_amount:"261107"
        },
          {
           area:"莆田市",
           count:"0",
           total_amount:"0"
         },
          {
            area:"泉州市",
            count:"2",
            total_amount:"350268"
          },
        {
          area:"厦门市",
          count:"0",
          total_amount:"0"
        },
        {
          area:"漳州市",
          count:"0",
          total_amount:"0"
        },
        {
          area:"龙岩市",
          count:"5",
          total_amount:"841222.9"
        },
        //  {
        //   area:"武夷山市",
        //   count:"1",
        //   total_amount:"125049"
        // },
      ],
      infoWindowEl: null,
      view: null,
      heatmap: null,  //省碳密度
      heatmapTwo: null,  //市碳密度
      heatmapThree: null,  //省冠层
      heatmapFour: null,  //市冠层
      administrativeArea: null,
      markerArr: [],    // 地标 省
      markerArrTwo: [],  // 地标 市
      iconLayerArr: [],  // 普通地图模式下地标 省
      iconLayerArrTwo: [],  // 普通地图模式下地标 市
      projectArr: [],   // 项目 省
      projectArrTwo: [], // 项目 市
      projectArrThree: [], // 项目 县
      littleBanProjectPathOne: [
        {
          icon:require("../../assets/HomeMap/countyProject/1.png"),
          lng: 117.67811821442214,
          lat: 26.91484930928138,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/2.png"),
          lng: 117.67308998654563,
          lat: 26.880713115261685,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/3.png"),
          lng: 117.70110439900036,
          lat: 26.756962708566057,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/4.png"),
          lng: 117.6601602577204,
          lat: 26.71181142822574,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/5.png"),
          lng: 117.75641490564173,
          lat: 26.70019817173497,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/6.png"),
          lng: 117.99228877859682,
          lat: 26.9643201911548,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/7.png"),
          lng: 117.98510559591611,
          lat: 27.01194344910247,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/8.png"),
          lng: 118.02892301026834,
          lat: 26.93921325152338,
        },
      ],    // 小班项目地址1
      littleBanProjectPathTwo: [
        {
          icon:require("../../assets/HomeMap/countyProject/1.png"),
          lng: 117.81107348630985,
          lat: 26.729460282294127,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/2.png"),
          lng: 117.75439534517544,
          lat: 26.818789940494224,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/3.png"),
          lng: 117.86182019406974,
          lat: 26.741591984645957,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/4.png"),
          lng: 117.9039992758442,
          lat: 26.907162161904207,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/5.png"),
          lng: 117.93859930386232,
          lat: 26.947630452280745,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/6.png"),
          lng: 117.88587545164425,
          lat: 26.95944324302481,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/7.png"),
          lng: 117.85885447738248,
          lat: 26.949107119577945,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/8.png"),
          lng: 118.01471936550213,
          lat: 26.902139526005033,
        },
      ],    // 小班项目地址2
      littleBanProjectPathThree: [
        {
          icon:require("../../assets/HomeMap/countyProject/1.png"),
          lng: 117.80904465770064,
          lat: 26.775855985317687,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/2.png"),
          lng: 117.7757941486462,
          lat: 26.7262695260117,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/3.png"),
          lng: 117.73070871264021,
          lat: 26.716147154730926,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/4.png"),
          lng: 117.73859866394126,
          lat: 26.762196592216053,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/5.png"),
          lng: 117.98262358632375,
          lat: 26.968427865688227,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/6.png"),
          lng: 118.01249268767774,
          lat: 26.92650476648185,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/7.png"),
          lng: 117.9566994606203,
          lat: 26.930546233437816,
        },
        {
          icon:require("../../assets/HomeMap/countyProject/8.png"),
          lng: 117.91217759256436,
          lat: 27.03759172780974,
        },
      ],  // 小班项目地址3
      littleBanProjectOne: [],    // 小班项目1
      littleBanProjectTwo: [],    // 小班项目2
      littleBanProjectThree: [],  // 小班项目3
      littleBanProjectObj: {
        title: '开发中',
        project:{
          owner: '',
          area: '',
          creditingPeriod: '',
          monitoringPeriod: '',
          emissionReduction: ''
        },
        three:
        [
          {"treeSpecies":"杉木","DBH":"21.5","treeHeight":"16.2","volume":"0.2822","carbonStorage":"0.3363"}
        ],
        pointCloud:{
          srcOne: '',
          srcTwo: '',
        },
        data:[
          [1,1],
          [1,1],
        ]
      },     // 小班弹窗数据
      littleBanImgOne: [
        require("../../assets/littleBanPoint/one/1.jpg"),
        require("../../assets/littleBanPoint/one/2.jpg"),
        require("../../assets/littleBanPoint/one/3.jpg"),
        require("../../assets/littleBanPoint/one/4.jpg"),
        require("../../assets/littleBanPoint/one/5.jpg"),
        require("../../assets/littleBanPoint/one/6.jpg"),
        require("../../assets/littleBanPoint/one/7.jpg"),
        require("../../assets/littleBanPoint/one/8.jpg"),
      ],
      littleBanImgTwo: [
        require("../../assets/littleBanPoint/two/1.jpg"),
        require("../../assets/littleBanPoint/two/2.jpg"),
        require("../../assets/littleBanPoint/two/3.jpg"),
        require("../../assets/littleBanPoint/two/4.jpg"),
        require("../../assets/littleBanPoint/two/5.jpg"),
        require("../../assets/littleBanPoint/two/6.jpg"),
        require("../../assets/littleBanPoint/two/7.jpg"),
        require("../../assets/littleBanPoint/two/8.jpg"),
      ],
      littleBanDataOneArr: [
        [22.60,21.98],
        [12.59,13.01],
        [19.63,19.42],
        [13.51,13.01],
        [33.02,31.51],
        [17.06,18.23],
        [23.67,25.90],
        [18.14,19.75],
      ],
      littleBanDataTwoArr: [
        [4,0.5],
        [4,0.5],
        [8,0.5],
        [6,0.5],
        [4,0.5],
        [4,0.5],
        [4,0.5],
        [4,0.5],
      ],
      littleBanNameArr:[
        '武坊场洋门工区039林班12大班020小班',
        '武坊场洋门工区039林班12大班010小班',
        '曲村场曲村工区028林班06大班150小班',
        '曲村场曲村工区028林班06大班160小班',
        '曲村场曲村工区028林班07大班080小班',
        '岚下场钱墩工区074林班07大班010小班',
        '岚下场钱墩工区069林班05大班030小班',
        '岚下场钱墩工区039林班05大班051小班',
      ],
      treeArr: [tree0,tree1,tree2,tree3,tree4,tree5,tree6,tree7],
      projectIconArr: [],  // 普通地图模式下项目 省
      projectIconArrTwo: [],  // 普通地图模式下项目 市
      projectIconArrThree: [],  // 普通地图模式下项目 县
      holeArr: [], // 镂空面 省
      holeArrTwo: [],   // 镂空面市
      showBackButton: true,
      isCountyDraw: false,
      isCarbon: false,   // 是否为碳储量
      isShowBottom: 1, // 是否展示小班数据  1 为展示 2为待开发
      // https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fn.sinaimg.cn%2Fsinacn23%2F519%2Fw1200h919%2F20180429%2F8f7e-fzvpatr2553811.jpg&refer=http%3A%2F%2Fn.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660112185&t=1d7f393863a7ce9ca4af53dd2d711bc1

    };
  },
  methods: {
    // initMap({BMap, map}){
    //   this.map = map
    //   map.setMapStyle({style: 'midnight'})
    //   let point = new BMap.Point(118.120427,27.331749)  //设置经纬度
    //   map.centerAndZoom(point, 14)
    //   map.setCurrentCity('建阳')
    //   map.enableScrollWheelZoom(true)
    // }
    //  async onChange(file) {
    //   let dataBinary = XLSX.read(碳中和项目表, { type: "binary" }); // 读取文件
    //   let workBook = xlsx.read(dataBinary, { type: 'binary', cellDates: true })
    //   let workSheet = workBook.Sheets[workBook.SheetNames[0]]
    //    // 解析文件 {defval: ''}=>防止单元格为空的时解析出来的结果缺少相应的key
    //   const data = xlsx.utils.sheet_to_json(碳中和项目表, { defval: "" })
    //   console.log(data)//这里已经能拿到转换后的json
    //   //过滤数据
    //   data.filter(v => {
    //     if (typeof v['蔬菜交易情况录入信息表'] == 'number') {
    //       let obj = {
    //         BH: v['蔬菜交易情况录入信息表'], //编号
    //         SCMJ: v[''], //名称
    //         ZGJ: v['_3'] - 0, //最高价
    //         ZDJ: v['_3'] - 0, //最低价
    //         JJ: v['__EMPTY'] - 0, //平均价
    //       }
    //       this.slsxList.push(obj)
    //     }
    //   })
    // },
    getData(){
      // getProjectInfo().then((res)=>{
      //   console.log(res,'getProjectInfo');
      //   this.areaObjArr = res
      // })
    },
    handleInfo(val) {
      // console.log(val);
      if (val) {
        // 进入方舱3d
        this.$store.commit("SET_MAP_STATE", false);
      } else {
        // 进入地图详情视角
        this.MidMap.startViewAnimation(this.mobilePerspective); // 开始播放动画
        // this.$store.commit("SET_MAP_INFO_STATE", true);
      }
      // 根据进入模式，修改中部遮罩大小
      if (this.$store.state.isHomeMap) {
        $(".mid-container").css("height", "67.6vh");
      } else {
        $(".mid-container").css("height", "75.5vh");
      }
      console.log("2");
    },
    initMap() {
      // 百度地图API功能
      let that = this;
      this.MidMap = new BMapGL.Map("container",{
        minZoom: 7,  // 缩小倍速限定
        // maxZoom: 15,    // 放大倍数限定
        mapType: BMAP_EARTH_MAP  // 设置地图类型为地球模式
      });
      // this.MidMap.setMapType(BMAP_EARTH_MAP);      // 设置地图类型为地球模式
      this.MidMap.setDisplayOptions({  // 隐藏POI文字以及图标
          poiText: false,
          poiIcon: false,
          street: false,     //是否显示路网（只对卫星图和地球模式有效）
      })
      console.log(this.MidMap,'MidMap');
      console.log(this.MidMap.getMapType(),'MidMap');
      // 添加行政区
      // this.MidMap.removeDistrictLayer(dist);
      // this.administrativeArea = new BMapGL.DistrictLayer({
      //     name: '(' + '福建省' + ')',
      //     kind: 1,
      //     fillColor: 'rgba(1,166,66,0.6000)',
      //     strokeColor: 'rgba(255,193,71,0.7000)',
      //     storkeWith: 2,
      //     opacity: 1,
      //     viewport: true
      // });

      // this.MidMap.addDistrictLayer(this.administrativeArea);
      // dist.addEventListener('mouseover', function (e) {
      //     e.currentTarget.setFillColor('#9169db');
      // });
      // -------------------
      this.MidMap.addEventListener('moveend',function (e) {
        console.log(e,'moveend');
        console.log(that.MidMap.getCenter().lat,'moveend')
        that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
        console.log(that.cMapPoint,'moveend')
        // setTimeout(() => {
          console.log(that.MidMap.getTilt());
          
          if(that.MidMap.getTilt() < 50){
            console.log(1);
            // that.MidMap.setTilt(50);
          }
        // }, 350);
      })
      // this.MidMap.addEventListener('moving',function (e) {
      //   console.log(e,'moving');
      //   console.log(that.MidMap.getCenter(),'moving')
      //   that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
      // })
      // this.MidMap.addEventListener('moving',function (e) {
      //   // console.log(e,'moving');
      //   console.log(that.MidMap.getCenter())
        
      // })
      let point = that.cMapFissoPoint;
      this.MidMap.centerAndZoom(point, that.cMapFissoZoom);
      this.MidMap.setTilt(0);
      this.MidMap.enableScrollWheelZoom();
      this.MidMap.disableDoubleClickZoom()
      let bd = new BMapGL.Boundary();
      let myGeo = new BMapGL.Geocoder();

      // layer = new mapvgl.TextLayer({
      //     color: '#fff',
      //     fontFamily: 'Microsoft Yahei',
      //     fontSize: 14,
      //     flat: false,
      //     collides: true,
      //     offset: [0, 0],
      //     padding: [2, 2],
      //     margin: [0, 0],
      //     data: [{
      //         geometry: {
      //             type: 'Point',
      //             coordinates: [116.403748, 39.915055]
      //         },
      //         properties: {
      //                 text: '文字' // 展示的文字
      //         }
      //     }]
      // });


      // 画高亮镂空面  省级
      bd.get('南平市', function (rs) {
          // console.log('外轮廓：', rs.boundaries[0]);
          // console.log('内镂空：', rs.boundaries[1]);
        that.holeArr[0] = new BMapGL.Polygon(rs.boundaries, {
          fillColor: 'rgba(1,166,66,0.9000)',
          strokeColor: 'rgba(255,193,71,0.9000)',
          storkeWith: 2,
        });
        that.MidMap.addOverlay(that.holeArr[0]);
      });
      for (let i = 1; i <= this.cityArr.length; i++) {
        bd.get(this.cityArr[i-1].name, function (rs) {
            // console.log('外轮廓：', rs.boundaries[0]);
            // console.log('内镂空：', rs.boundaries[1]);
          that.holeArr[i] = new BMapGL.Polygon(rs.boundaries, {
            fillColor: 'rgba(1,166,66,0.9000)',
            strokeColor: 'rgba(255,193,71,0.9000)',
            storkeWith: 1,
          });
          that.MidMap.addOverlay(that.holeArr[i]);
        });
        
      }
      // console.log(that.holeArr);
      // 画高亮镂空面  市级、县
      bd.get('顺昌县', function (rs) {
          // console.log('外轮廓：', rs.boundaries[0]);
          // console.log('内镂空：', rs.boundaries[1]);
        that.holeArrTwo[0] = new BMapGL.Polygon(rs.boundaries, {
          fillColor: 'rgba(1,166,66,0.9000)',
          strokeColor: 'rgba(255,193,71,0.9000)',
          storkeWith: 2,
        });
        that.holeArrTwo[100] = new BMapGL.Polygon(rs.boundaries, {
          fillColor: 'rgba(1,166,66,0.9000)',
          strokeColor: 'rgba(255,193,71,0.9000)',
          storkeWith: 2,
        });
        // that.MidMap.addOverlay(that.holeArrTwo[0]);
      });
      for (let i = 1; i <= this.cityArrTwo.length; i++) {
        bd.get(this.cityArrTwo[i-1].name, function (rs) {
            // console.log('外轮廓：', rs.boundaries[0]);
            // console.log('内镂空：', rs.boundaries[1]);
          that.holeArrTwo[i] = new BMapGL.Polygon(rs.boundaries, {
            fillColor: 'rgba(1,166,66,0.9000)',
            strokeColor: 'rgba(255,193,71,0.9000)',
            storkeWith: 1,
          });
          // that.MidMap.addOverlay(that.holeArrTwo[i]);
        });
      }
      // -----------------------------------------------
      // 添加标记点
        var myIcon = new BMapGL.Icon(require("../../assets/HomeMap/subIcon.png"), new BMapGL.Size(78, 56));
        // var myIcon2 = new BMapGL.Icon(`https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fn.sinaimg.cn%2Fsinacn23%2F519%2Fw1200h919%2F20180429%2F8f7e-fzvpatr2553811.jpg&refer=http%3A%2F%2Fn.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660112185&t=1d7f393863a7ce9ca4af53dd2d711bc1`, new BMapGL.Size(90, 90));
        // var myIconLayer = `https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fn.sinaimg.cn%2Fsinacn23%2F519%2Fw1200h919%2F20180429%2F8f7e-fzvpatr2553811.jpg&refer=http%3A%2F%2Fn.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660112185&t=1d7f393863a7ce9ca4af53dd2d711bc1`;
        // 省级项目 卫星地图
        for (let i = 0; i < that.cityProjectNameArr.length; i++) {
          myGeo.getPoint(that.cityProjectNameArr[i].name, function(areaPoint){
            console.log(areaPoint);
            if (areaPoint) {
              // 卫星模式下创建Marker标注，使用自定义图标
              let address = new BMapGL.Point(areaPoint.lng, areaPoint.lat);
              // let pt = new BMapGL.Point(118.076988, 27.201181);
              that.projectArr[i] = new BMapGL.Marker(address, {
                  // icon: that.cityProjectNameArr[i].icon
                  icon: new BMapGL.Icon(that.cityProjectNameArr[i].icon, new BMapGL.Size(164, 76)),
                  // offset: new BMapGL.Size(0,300/8),
              });
              // 将标注添加到地图
              that.MidMap.addOverlay(that.projectArr[i]);
              that.projectArr[i].addEventListener('click', (e) => {
                console.log(e,'123');
                that.cityProjectObj = that.cityProjectNameArr[i]
                that.infoWindowEl.style.display = "block";
                // that.$refs.AreaDialog.$el.style.display = "block";
                that.MidMap.openInfoWindow(
                  infoWindow,
                  // infoWindow,
                  new BMapGL.Point(
                    e.latLng.lng,
                    e.latLng.lat
                  )
                );
              });
              // 省级项目 普通地图
              that.projectIconArr[i] = new mapvgl.IconLayer({
                width: 164,
                height: 77,
                offset: [0, -300 / 8],
                // offset: [0, -153 / 8],
                opacity: 1,
                icon: that.cityProjectNameArr[i].icon,
                enablePicked: true, // 是否可以拾取
                selectedIndex: 1, // 选中项
                // selectedColor: '#ff0000', // 选中项颜色
                // autoSelect: true, // 根据鼠标位置来自动设置选中项
                onClick: (e) => {
                  // 点击事件
                  if (e.dataIndex != -1) {
                    that.cityProjectObj = that.cityProjectNameArr[i]
                    that.infoWindowEl.style.display = "block";
                    that.MidMap.openInfoWindow(
                      infoWindow,
                      new BMapGL.Point(
                        e.dataItem.geometry.coordinates[0],
                        e.dataItem.geometry.coordinates[1]
                      )
                    );
                  } else {
                  }
                },
                // onDblClick: e => {
                //   if (e.dataIndex != -1) {
                //   } else {
                //     return
                //   }
                // },
                // onRightClick: e => {
                //   if (e.dataIndex != -1) {
                //   } else {
                //     return
                //   }
                // }
              });
              that.projectIconArr[i].setData(
                [
                  {
                    geometry: {
                      type: "Point",
                      coordinates: [address.lng, address.lat],
                    },
                  }
                ]
              );
            }
          })     
        }

        // 市级项目  卫星地图
        var pt = new BMapGL.Point(117.076988, 27.201181);
        this.projectArrTwo[0] = new BMapGL.Marker(pt, {
            icon: myIcon
        });
        // 将标注添加到地图
        // this.MidMap.addOverlay(this.projectArrTwo[0]);
        this.projectArrTwo[0].addEventListener('click', (e) => {
          console.log(e,'123');
          that.infoWindowEl.style.display = "block";
          // that.$refs.AreaDialog.$el.style.display = "block";
          that.MidMap.openInfoWindow(
            infoWindow,
            // infoWindow,
            new BMapGL.Point(
              e.latLng.lng,
              e.latLng.lat
            )
          );
        });
        // 市级项目   普通地图
        that.projectIconArrTwo[0] = new mapvgl.IconLayer({
          width: 90,
          height: 90,
          offset: [0, -300 / 8],
          // offset: [0, -153 / 8],
          opacity: 1,
          icon: require("../../assets/HomeMap/subIcon.png"),
          enablePicked: true, // 是否可以拾取
          selectedIndex: 1, // 选中项
          // selectedColor: '#ff0000', // 选中项颜色
          // autoSelect: true, // 根据鼠标位置来自动设置选中项
          onClick: (e) => {
            // 点击事件
            if (e.dataIndex != -1) {
              that.infoWindowEl.style.display = "block";
              that.MidMap.openInfoWindow(
                infoWindow,
                new BMapGL.Point(
                  e.dataItem.geometry.coordinates[0],
                  e.dataItem.geometry.coordinates[1]
                )
              );
            } else {
            }
          },
          // onDblClick: e => {
          //   if (e.dataIndex != -1) {
          //   } else {
          //     return
          //   }
          // },
          // onRightClick: e => {
          //   if (e.dataIndex != -1) {
          //   } else {
          //     return
          //   }
          // }
        });
        that.projectIconArrTwo[0].setData(
          [
            {
              geometry: {
                type: "Point",
                coordinates: [pt.lng, pt.lat],
              },
            }
          ]
        );
        // 县级项目  卫星地图  默认小班1
        for (let i = 0; i < that.littleBanProjectPathOne.length; i++) {
          // 项目一小班
          // 卫星模式下创建Marker标注，使用自定义图标
          let address = new BMapGL.Point(that.littleBanProjectPathOne[i].lng, that.littleBanProjectPathOne[i].lat);
          // let pt = new BMapGL.Point(118.076988, 27.201181);
          that.littleBanProjectOne[i] = new BMapGL.Marker(address, {
              // icon: that.cityProjectNameArr[i].icon
              icon: new BMapGL.Icon(that.littleBanProjectPathOne[i].icon, new BMapGL.Size(106, 60)),
              // offset: new BMapGL.Size(0,300/8),
          });
          // 将标注添加到地图
          // that.MidMap.addOverlay(that.littleBanProjectOne[i]);
          that.littleBanProjectOne[i].addEventListener('click', (e) => {
            console.log(e,'123');
            // that.cityProjectObj = that.littleBanProjectOne[i]
            // that.infoWindowEl.style.display = "block";
            that.littleBanProjectObj = {
              title: that.littleBanNameArr[i],
              project:{
                owner: '顺昌县国有林场',
                area: '南平市顺昌县',
                creditingPeriod: '2021/04/01 - 2023/04/01',
                monitoringPeriod: '2021/04/01 - 2022/04/11',
                emissionReduction: '94283'
              },
              three: that.treeArr[i],
              pointCloud:{
                srcOne: that.littleBanImgOne[i],
                srcTwo: that.littleBanImgTwo[i],
              },
              data:[
                that.littleBanDataOneArr[i],
                that.littleBanDataTwoArr[i],
              ]
            },     // 小班弹窗数据
            that.isShowBottom = 1
            that.$refs.AreaDialog.resetActive()
            that.$refs.AreaDialog.$el.style.display = "block";
            // that.$refs.AreaDialog.$el.style.display = "block";
            that.MidMap.openInfoWindow(
              areaDialogInfoWindow,
              // infoWindow,
              new BMapGL.Point(
                e.latLng.lng,
                e.latLng.lat
              )
            );
          });
          // 项目二小班
          // 卫星模式下创建Marker标注，使用自定义图标
          let addressTwo = new BMapGL.Point(that.littleBanProjectPathTwo[i].lng, that.littleBanProjectPathTwo[i].lat);
          // let pt = new BMapGL.Point(118.076988, 27.201181);
          that.littleBanProjectTwo[i] = new BMapGL.Marker(addressTwo, {
              // icon: that.cityProjectNameArr[i].icon
              icon: new BMapGL.Icon(that.littleBanProjectPathTwo[i].icon, new BMapGL.Size(106, 60)),
              // offset: new BMapGL.Size(0,300/8),
          });
          // 将标注添加到地图
          // that.MidMap.addOverlay(that.littleBanProjectOne[i]);
          that.littleBanProjectTwo[i].addEventListener('click', (e) => {
            console.log(e,'123');
            // that.cityProjectObj = that.littleBanProjectOne[i]
            // that.infoWindowEl.style.display = "block";
            that.littleBanProjectObj = {
              title: '开发中',
              project:{
                owner: '顺昌县国有林场',
                area: '南平市顺昌县',
                creditingPeriod: '2006/11/01 - 2026/10/31',
                monitoringPeriod: '2006/11/01 - 2016/10/31',
                emissionReduction: '154828'
              },
              three:
              [
                {"treeSpecies":"","DBH":"","treeHeight":"","volume":"","carbonStorage":""}
              ],
              pointCloud:{
                srcOne: '',
                srcTwo: '',
              },
              data:[
                [0,0],
                [0,0],
              ]
            }
            that.isShowBottom = 0
            that.$refs.AreaDialog.resetActive()
            that.$refs.AreaDialog.$el.style.display = "block";
            // that.$refs.AreaDialog.$el.style.display = "block";
            that.MidMap.openInfoWindow(
              areaDialogInfoWindow,
              // infoWindow,
              new BMapGL.Point(
                e.latLng.lng,
                e.latLng.lat
              )
            );
          });
          // 项目三小班
          // 卫星模式下创建Marker标注，使用自定义图标
          let addressThree = new BMapGL.Point(that.littleBanProjectPathThree[i].lng, that.littleBanProjectPathThree[i].lat);
          // let pt = new BMapGL.Point(118.076988, 27.201181);
          that.littleBanProjectThree[i] = new BMapGL.Marker(addressThree, {
              // icon: that.cityProjectNameArr[i].icon
              icon: new BMapGL.Icon(that.littleBanProjectPathThree[i].icon, new BMapGL.Size(106, 60)),
              // offset: new BMapGL.Size(0,300/8),
          });
          // 将标注添加到地图
          // that.MidMap.addOverlay(that.littleBanProjectOne[i]);
          that.littleBanProjectThree[i].addEventListener('click', (e) => {
            console.log(e,'123');
            // that.cityProjectObj = that.littleBanProjectOne[i]
            // that.infoWindowEl.style.display = "block";
            that.littleBanProjectObj = {
              title: '开发中',
              project:{
                owner: '顺昌县国有林场',
                area: '南平市顺昌县',
                creditingPeriod: '2010/01/15 - 2040/01/14',
                monitoringPeriod: '2010/01/15 - 2017/01/14',
                emissionReduction: '119415'
              },
              three:
              [
                {"treeSpecies":"","DBH":"","treeHeight":"","volume":"","carbonStorage":""}
              ],
              pointCloud:{
                srcOne: '',
                srcTwo: '',
              },
              data:[
                [0,0],
                [0,0],
              ]
            }
            that.isShowBottom = 0
            that.$refs.AreaDialog.resetActive()
            that.$refs.AreaDialog.$el.style.display = "block";
            // that.$refs.AreaDialog.$el.style.display = "block";
            that.MidMap.openInfoWindow(
              areaDialogInfoWindow,
              // infoWindow,
              new BMapGL.Point(
                e.latLng.lng,
                e.latLng.lat
              )
            );
          });
        }
        var pt = new BMapGL.Point(117.83649501043922, 26.850525688923227);
        this.projectArrThree[0] = new BMapGL.Marker(pt, {
            icon: myIcon
        });
        // 将标注添加到地图
        // this.MidMap.addOverlay(this.projectArrThree[0]);
        this.projectArrThree[0].addEventListener('click', (e) => {
          console.log(e,'123');
          // that.infoWindowEl.style.display = "block";
          that.$refs.AreaDialog.$el.style.display = "block";
          that.MidMap.openInfoWindow(
            areaDialogInfoWindow,
            // infoWindow,
            new BMapGL.Point(
              e.latLng.lng,
              e.latLng.lat
            )
          );
        });
        // 县级项目  普通地图
        that.projectIconArrThree[0] = new mapvgl.IconLayer({
          width: 90,
          height: 90,
          offset: [0, -300 / 8],
          // offset: [0, -153 / 8],
          opacity: 1,
          icon: require("../../assets/HomeMap/subIcon.png"),
          enablePicked: true, // 是否可以拾取
          selectedIndex: 1, // 选中项
          // selectedColor: '#ff0000', // 选中项颜色
          // autoSelect: true, // 根据鼠标位置来自动设置选中项
          onClick: (e) => {
            // 点击事件
            if (e.dataIndex != -1) {
              that.$refs.AreaDialog.$el.style.display = "block";
              that.MidMap.openInfoWindow(
                areaDialogInfoWindow,
                new BMapGL.Point(
                  e.dataItem.geometry.coordinates[0],
                  e.dataItem.geometry.coordinates[1]
                )
              );
            } else {
            }
          },
          // onDblClick: e => {
          //   if (e.dataIndex != -1) {
          //   } else {
          //     return
          //   }
          // },
          // onRightClick: e => {
          //   if (e.dataIndex != -1) {
          //   } else {
          //     return
          //   }
          // }
        });
        that.projectIconArrThree[0].setData(
          [
            {
              geometry: {
                type: "Point",
                coordinates: [pt.lng, pt.lat],
              },
            }
          ]
        );

        // 省级地标
        for (let i = 0; i < that.cityArr.length; i++) {
          myGeo.getPoint(that.cityArr[i].name, function(areaPoint){
            console.log(areaPoint);
            if (areaPoint) {
              // 卫星模式下创建Marker标注，使用自定义图标
              let address = new BMapGL.Point(areaPoint.lng, areaPoint.lat);
              // let pt = new BMapGL.Point(118.076988, 27.201181);
              
              that.markerArr[i] = new BMapGL.Marker(address, {
                  icon: new BMapGL.Icon(that.cityArr[i].icon, new BMapGL.Size(146, 36)),
                  // // baseZIndex: 999,
                  // isTop: true,
                  // zIndexFixed: true
              });
              console.log(that.markerArr[i],'11111');
              // 将标注添加到地图
              that.MidMap.addOverlay(that.markerArr[i]);
              that.markerArr[i].addEventListener('click', (e) => {
                console.log(e,'123');
                that.areaObj = that.areaObjArr[i]
                // that.infoWindowEl.style.display = "block";
                that.$refs.AreaDialogCity.$el.style.display = "block";
                that.MidMap.openInfoWindow(
                  areaDialogCityInfoWindow,
                  // infoWindow,
                  new BMapGL.Point(
                    e.latLng.lng,
                    e.latLng.lat
                  )
                );
              });
              that.markerArr[i].addEventListener('rightclick', (e) => {
              // that.markerArr[i].addEventListener('dblclick', (e) => {
                console.log(e,'123dblclick');
                that.showBackButton = true
                that.$store.commit("SET_MAP_ZOOM", 2);
                for (let i = 0; i < that.markerArr.length; i++) {
                  that.MidMap.removeOverlay(that.markerArr[i])
                }
                for (let i = 0; i < that.holeArr.length; i++) {
                  that.MidMap.removeOverlay(that.holeArr[i]);
                }
                for (let i = 4; i < that.projectArr.length; i++) {
                  that.MidMap.removeOverlay(that.projectArr[i]);
                }
                // for (let i = 0; i < that.projectArrTwo.length; i++) {
                //   that.MidMap.addOverlay(that.projectArrTwo[i]);
                // }

                for (let i = 0; i < that.holeArrTwo.length; i++) {
                  that.MidMap.addOverlay(that.holeArrTwo[i]);
                }
                for (let i = 0; i < that.markerArrTwo.length; i++) {
                  that.MidMap.addOverlay(that.markerArrTwo[i])
                }
                that.MidMap.flyTo(
                  new BMapGL.Point(
                    e.latLng.lng,
                    e.latLng.lat
                  ),10);
              });


              // 普通模式下创建iconLayer
              // 画标签普通模式下标签
              that.iconLayerArr[i] = new mapvgl.IconLayer({
                width: 146,
                height: 36,
                offset: [0, -300 / 8],
                // offset: [0, -153 / 8],
                opacity: 1,
                icon: that.cityArr[i].icon,
                enablePicked: true, // 是否可以拾取
                selectedIndex: 1, // 选中项
                // selectedColor: '#ff0000', // 选中项颜色
                // autoSelect: true, // 根据鼠标位置来自动设置选中项
                onClick: (e) => {
                  // 点击事件
                  console.log("click", e);
                  if (e.dataIndex != -1) {
                    that.areaObj = that.areaObjArr[i]
                    that.$refs.AreaDialogCity.$el.style.display = "block";
                    that.MidMap.openInfoWindow(
                      areaDialogCityInfoWindow,
                      // infoWindow,
                      new BMapGL.Point(
                        e.dataItem.geometry.coordinates[0],
                        e.dataItem.geometry.coordinates[1]
                      )
                    );
                  } else {
                  }
                },
                onRightClick: e => {
                  if (e.dataIndex != -1) {
                    that.view.removeLayer(that.heatmapTwo);
                    that.view.removeLayer(that.heatmap);
                    that.view.removeLayer(that.heatmapThree);
                    that.view.removeLayer(that.heatmapFour);
                    if(that.isCarbon){
                      that.view.addLayer(that.heatmapTwo);
                    }else{
                      that.view.addLayer(that.heatmapFour);
                    }
                    that.showBackButton = true
                    that.$store.commit("SET_MAP_ZOOM", 2);
                    for (let i = 0; i < that.iconLayerArrTwo.length; i++) {
                      that.view.addLayer(that.iconLayerArrTwo[i])
                    }
                    for (let i = 4; i < that.projectIconArr.length; i++) {
                      that.view.removeLayer(that.projectIconArr[i]);
                    }

                    for (let i = 0; i < that.iconLayerArr.length; i++) {
                      that.view.removeLayer(that.iconLayerArr[i]);
                    }
                    for (let i = 0; i < that.holeArrTwo.length; i++) {
                      that.MidMap.addOverlay(that.holeArrTwo[i]);
                    }
                    for (let i = 0; i < that.holeArr.length; i++) {
                      that.MidMap.removeOverlay(that.holeArr[i]);
                    }
                    // for (let i = 0; i < that.projectIconArrTwo.length; i++) {
                    //   that.view.addLayer(that.projectIconArrTwo[i])
                    // }
                    that.MidMap.flyTo(
                      new BMapGL.Point(
                        e.dataItem.geometry.coordinates[0],
                        e.dataItem.geometry.coordinates[1]
                      ),10);
                  } else {
                    return
                  }
                }
              });
              that.iconLayerArr[i].setData(
                [
                  {
                    geometry: {
                      type: "Point",
                      coordinates: [address.lng, address.lat],
                    },
                  }
                ]
              );
            }
          })    
        }

        // 市级地标
        for (let i = 0; i < that.cityArrTwo.length; i++) {
          myGeo.getPoint(that.cityArrTwo[i].name, function(areaPoint){
            console.log(areaPoint);
            if (areaPoint) {
              // 卫星模式下创建Marker标注，使用自定义图标
              let address = new BMapGL.Point(areaPoint.lng, areaPoint.lat);
              // let pt = new BMapGL.Point(118.076988, 27.201181);
              
              that.markerArrTwo[i] = new BMapGL.Marker(address, {
                  icon: new BMapGL.Icon(that.cityArrTwo[i].icon, new BMapGL.Size(146, 36)),
                  // // baseZIndex: 999,
                  // isTop: true,
                  // zIndexFixed: true
              });
              // marker.zIndex = 9999
              console.log(that.markerArr[i],'11111');
              // 将标注添加到地图
              // that.MidMap.addOverlay(that.markerArr[i]);
              that.markerArrTwo[i].addEventListener('click', (e) => {
                console.log(e,'123');
                that.areaObj = that.cityArrTwo[i]
                // that.infoWindowEl.style.display = "block";
                that.$refs.AreaDialogCity.$el.style.display = "block";
                that.MidMap.openInfoWindow(
                  areaDialogCityInfoWindow,
                  // infoWindow,
                  new BMapGL.Point(
                    e.latLng.lng,
                    e.latLng.lat
                  )
                );
              });
              that.markerArrTwo[i].addEventListener('rightclick', (e) => {
                
                that.$store.commit('SET_MAP_ZOOM', 3)
                that.showBackButton = true
                for (let i = 0; i < that.projectArr.length; i++) {
                  that.MidMap.removeOverlay(that.projectArr[i]);
                }
                // for (let i = 0; i < that.projectArrTwo.length; i++) {
                //   that.MidMap.removeOverlay(that.projectArrTwo[i]);
                // }
                for (let i = 1; i < that.holeArrTwo.length; i++) {
                  that.MidMap.removeOverlay(that.holeArrTwo[i]);
                }
                that.MidMap.addOverlay(that.holeArrTwo[100]);
                for (let i = 1; i < that.markerArrTwo.length; i++) {
                  that.MidMap.removeOverlay(that.markerArrTwo[i])
                }
                for (let i = 0; i < that.littleBanProjectOne.length; i++) {
                  that.MidMap.addOverlay(that.littleBanProjectOne[i]);
                }
                console.log(e,'123');
                that.MidMap.flyTo(
                  new BMapGL.Point(
                    e.latLng.lng,
                    e.latLng.lat
                  ),11.3);
              });


              // 普通模式下创建iconLayer
              // 画标签普通模式下标签
              that.iconLayerArrTwo[i] = new mapvgl.IconLayer({
                width: 146,
                height: 36,
                offset: [0, -300 / 8],
                // offset: [0, -153 / 8],
                opacity: 1,
                icon: that.cityArrTwo[i].icon,
                enablePicked: true, // 是否可以拾取
                selectedIndex: 1, // 选中项
                // selectedColor: '#ff0000', // 选中项颜色
                // autoSelect: true, // 根据鼠标位置来自动设置选中项
                onClick: (e) => {
                  // 点击事件
                  console.log("click", that.iconLayerArrTwo[i]);
                  if (e.dataIndex != -1) {
                    that.areaObj = that.cityArrTwo[i]
                    that.$refs.AreaDialogCity.$el.style.display = "block";
                    that.MidMap.openInfoWindow(
                      areaDialogCityInfoWindow,
                      // infoWindow,
                      new BMapGL.Point(
                        e.dataItem.geometry.coordinates[0],
                        e.dataItem.geometry.coordinates[1]
                      )
                    );
                  } else {
                  }
                },
                onRightClick: e => {
                  if (e.dataIndex != -1) {
                    that.view.removeLayer(that.heatmapTwo);
                    that.showBackButton = true
                    that.$store.commit("SET_MAP_ZOOM", 3);
                    for (let i = 0; i < that.iconLayerArrTwo.length; i++) {
                      that.view.removeLayer(that.iconLayerArrTwo[i])
                    }
                    // for (let i = 0; i < that.projectIconArrTwo.length; i++) {
                    //   that.view.removeLayer(that.projectIconArrTwo[i])
                    // }
                    for (let i = 0; i < that.projectIconArr.length; i++) {
                      that.view.removeLayer(that.projectIconArr[i])
                    }
                    // for (let i = 0; i < that.projectIconArrThree.length; i++) {
                    //   that.view.addLayer(that.projectIconArrThree[i])
                    // }
                    for (let i = 1; i < that.holeArrTwo.length; i++) {
                      that.MidMap.removeOverlay(that.holeArrTwo[i]);
                    }
                    that.MidMap.addOverlay(that.holeArrTwo[100]);
                    that.MidMap.setMapType(BMAP_EARTH_MAP)
                    for (let i = 0; i < that.littleBanProjectPathOne.length; i++) {
                      that.MidMap.addOverlay(that.littleBanProjectOne[i]);
                    }
                    that.MidMap.addOverlay(that.markerArrTwo[0]);
                    that.MidMap.flyTo(
                      new BMapGL.Point(
                        e.dataItem.geometry.coordinates[0],
                        e.dataItem.geometry.coordinates[1]
                      ),11.3);
                  } else {
                    return
                  }
                }
              });
              that.iconLayerArrTwo[i].setData(
                [
                  {
                    geometry: {
                      type: "Point",
                      coordinates: [address.lng, address.lat],
                    },
                  }
                ]
              );
            }
          })    
        }
      // -----------------------------------------------
      // 福建省热力图
      // ./mapJson/RasterT_Reclass1_FeaturesToJ.json福建省  碳层
      // ./mapJson/RasterT_Reclass2_FeaturesToJ.json南平市  碳层
      // ./mapJson/RasterT_Reclass1_FeaturesToJ.json福建省  冠层
      // ./mapJson/RasterT_Reclass5_FeaturesToJ.json南平市  冠层
      axios({
        url:"./mapJson/RasterT_Reclass1_FeaturesToJ.json",
        mesthod: 'get',
        dataType: "json",
        crossDomain: true,
        cache: false
      }).then((rs)=>{
        rs = rs.data.features;
        console.log(rs,'rs');
        var dataTwo = [];
        for (var i = 0; i < rs.length; i++) {
            var item = rs[i];
            for (let j = 0; j < item.geometry.coordinates[0].length; j++) {
                dataTwo.push({
                    geometry: {
                        type: 'Point',
                        coordinates: [item.geometry.coordinates[0][j][0], item.geometry.coordinates[0][j][1]]
                    },
                    properties: {
                        count: item.properties.gridcode
                    }
                });
            }
        }
        // console.log(data);
        this.heatmap = new mapvgl.HeatmapLayer({
            size: 1000, // 单个点绘制大小
            max: 10, // 最大阈值
            height: 0, // 最大高度，默认为0
            unit: 'm', // 单位，m:米，px: 像素
            gradient: { // 对应比例渐变色
                0.33: 'rgba(0, 0, 255, 1)',
                0.66: 'rgba(0, 255, 0, 1)',
                1: 'rgba(255, 201, 73, 1)',
                // 1: 'rgba(255, 0, 0, 1)'
            }
        });
        // this.view.addLayer(this.heatmap);
        this.heatmap.setData(dataTwo);
        console.log(this.heatmap,'this.heatmap');
      })
      // 南平市热力图
      axios({
        url:"./mapJson/RasterT_Reclass2_FeaturesToJ.json",
        mesthod: 'get',
        dataType: "json",
        crossDomain: true,
        cache: false
      }).then((rs)=>{
        rs = rs.data.features;
        console.log(rs,'rs');
        var data = [];
        for (var i = 0; i < rs.length; i++) {
            var item = rs[i];
            for (let j = 0; j < item.geometry.coordinates[0].length; j++) {
                data.push({
                    geometry: {
                        type: 'Point',
                        coordinates: [item.geometry.coordinates[0][j][0], item.geometry.coordinates[0][j][1]]
                    },
                    properties: {
                        count: item.properties.gridcode
                    }
                });
            }
        }
        console.log(data);
        this.heatmapTwo = new mapvgl.HeatmapLayer({
            size: 1000, // 单个点绘制大小
            max: 10, // 最大阈值
            height: 0, // 最大高度，默认为0
            unit: 'm', // 单位，m:米，px: 像素
            gradient: { // 对应比例渐变色
                0.33: 'rgba(0, 0, 255, 1)',
                0.66: 'rgba(0, 255, 0, 1)',
                1: 'rgba(255, 201, 73, 1)',
                // 1: 'rgba(255, 0, 0, 1)'
            }
        });
        // this.view.addLayer(this.heatmap);
        this.heatmapTwo.setData(data);
      })
      // 省级热力图 冠层
      axios({
        url:"./mapJson/RasterT_Reclass6_FeaturesToJ.json",
        mesthod: 'get',
        dataType: "json",
        crossDomain: true,
        cache: false
      }).then((rs)=>{
        rs = rs.data.features;
        console.log(rs,'rs');
        var data = [];
        for (var i = 0; i < rs.length; i++) {
            var item = rs[i];
            for (let j = 0; j < item.geometry.coordinates[0].length; j++) {
                data.push({
                    geometry: {
                        type: 'Point',
                        coordinates: [item.geometry.coordinates[0][j][0], item.geometry.coordinates[0][j][1]]
                    },
                    properties: {
                        count: item.properties.gridcode
                    }
                });
            }
        }
        console.log(data);
        this.heatmapThree = new mapvgl.HeatmapLayer({
            size: 1000, // 单个点绘制大小
            max: 10, // 最大阈值
            height: 0, // 最大高度，默认为0
            unit: 'm', // 单位，m:米，px: 像素
            gradient: { // 对应比例渐变色
                0.33: 'rgba(0, 0, 255, 1)',
                0.66: 'rgba(0, 255, 0, 1)',
                1: 'rgba(255, 201, 73, 1)',
                // 1: 'rgba(255, 0, 0, 1)'
            }
        });
        // this.view.addLayer(this.heatmap);
        this.heatmapThree.setData(data);
        console.log(this.heatmapThree,'heatmapThree');
      })
      // 南平市热力图 冠层
      axios({
        url:"./mapJson/RasterT_Reclass5_FeaturesToJ.json",
        mesthod: 'get',
        dataType: "json",
        crossDomain: true,
        cache: false
      }).then((rs)=>{
        rs = rs.data.features;
        console.log(rs,'rs');
        var data = [];
        for (var i = 0; i < rs.length; i++) {
            var item = rs[i];
            for (let j = 0; j < item.geometry.coordinates[0].length; j++) {
                data.push({
                    geometry: {
                        type: 'Point',
                        coordinates: [item.geometry.coordinates[0][j][0], item.geometry.coordinates[0][j][1]]
                    },
                    properties: {
                        count: item.properties.gridcode
                    }
                });
            }
        }
        console.log(data);
        this.heatmapFour = new mapvgl.HeatmapLayer({
            size: 1000, // 单个点绘制大小
            max: 10, // 最大阈值
            height: 0, // 最大高度，默认为0
            unit: 'm', // 单位，m:米，px: 像素
            gradient: { // 对应比例渐变色
                0.33: 'rgba(0, 0, 255, 1)',
                0.66: 'rgba(0, 255, 0, 1)',
                1: 'rgba(255, 201, 73, 1)',
                // 1: 'rgba(255, 0, 0, 1)'
            }
        });
        // this.view.addLayer(this.heatmap);
        this.heatmapFour.setData(data);
      })
      // for (let i = 0; i < this.areaNameArr.length; i++) {
      //   myGeo.getPoint(this.areaNameArr[i].name, function(areaPoint){
      //     if (areaPoint) {
      //         let address = new BMapGL.Point(areaPoint.lng, areaPoint.lat);
      //         console.log(address)
      //         // addMarker(address,new BMapGL.Label(index+":"+add,{offset:new BMapGL.Size(10,-10)}));

      //         // 画标签
      //         let layerIcon = new mapvgl.IconLayer({
      //           width: 90,
      //           height: 90,
      //           offset: [0, -300 / 8],
      //           // offset: [0, -153 / 8],
      //           opacity: 1,
      //           icon: that.areaNameArr[i].icon,
      //           enablePicked: true, // 是否可以拾取
      //           selectedIndex: 1, // 选中项
      //           // selectedColor: '#ff0000', // 选中项颜色
      //           // autoSelect: true, // 根据鼠标位置来自动设置选中项
      //           // onClick: (e) => {
      //           //   // 点击事件
      //           //   console.log("click", e);
      //           //   // if (e.dataIndex != -1) {
      //           //   //   that.areaObj = that.areaObjArr[i + 1]
      //           //   //   that.$refs.AreaDialog.$el.style.display = "block";
      //           //   //   that.MidMap.openInfoWindow(
      //           //   //     areaDialogInfoWindow,
      //           //   //     new BMapGL.Point(
      //           //   //       e.dataItem.geometry.coordinates[0],
      //           //   //       e.dataItem.geometry.coordinates[1]
      //           //   //     )
      //           //   //   );
      //           //   //   // 移动视角
      //           //   //   // that.MidMap.flyTo(
      //           //   //   //   new BMapGL.Point(
      //           //   //   //     e.dataItem.geometry.coordinates[0],
      //           //   //   //     e.dataItem.geometry.coordinates[1]
      //           //   //   //   ),
      //           //   //   //   14.5)
      //           //   // } else {
      //           //   //   // view.showLayer(layerIcon)
      //           //   // }
      //           // },
      //           onDblClick: e => {
      //               // console.log('double click', e);
      //             if (e.dataIndex != -1) {
      //                  // 移动视角
      //                 //  console.log('onDblClick');
      //               that.MidMap.flyTo(new BMapGL.Point(
      //                 e.dataItem.geometry.coordinates[0],
      //                 e.dataItem.geometry.coordinates[1]
      //               ),12)
      //             } else {
      //               return
      //               // view.showLayer(layerIcon)
      //             }
      //           },
      //           onRightClick: e => {
      //               console.log('right click', e);
      //             if (e.dataIndex != -1) {
      //               that.areaObj = that.areaObjArr[i + 1]
      //               that.$refs.AreaDialog.$el.style.display = "block";
      //               that.MidMap.openInfoWindow(
      //                 areaDialogInfoWindow,
      //                 new BMapGL.Point(
      //                   e.dataItem.geometry.coordinates[0],
      //                   e.dataItem.geometry.coordinates[1]
      //                 )
      //               );

      //               // 移动视角
      //               // that.MidMap.flyTo(
      //               //   new BMapGL.Point(
      //               //     e.dataItem.geometry.coordinates[0],
      //               //     e.dataItem.geometry.coordinates[1]
      //               //   ),
      //               //   14.5)
      //             } else {
      //               return
      //               // view.showLayer(layerIcon)
      //             }
      //           }
      //         });




      //         view.addLayer(layerIcon);
      //         layerIcon.setData(
      //           [
      //             {
      //               geometry: {
      //                 type: "Point",
      //                 coordinates: [address.lng, address.lat],
      //               },
      //             }
      //           ]
      //         );
      //         // 画泡泡
      //         let bubbleLayer = new mapvgl.CircleLayer({
      //           // 绘制带泡泡的圆
      //           type: "bubble",
      //           size: (size) => 3 * size,
      //           // 扩散半径，支持直接设置和回调两种形式，size为点的大小
      //           radius(size) {
      //             return 2 * size;
      //           },
      //           // 扩散时间
      //           duration: 1,
      //           // 渐隐时间
      //           trail: 1,
      //         });
      //         view.addLayer(bubbleLayer);
      //         bubbleLayer.setData(
      //           [
      //             {
      //               geometry: {
      //                 type: "Point",
      //                 coordinates: [address.lng, address.lat],
      //               },
      //               color: "rgba(71,98,255, 0.6)",
      //               // 圆的半径
      //               size: 15,
      //             },
      //           ]
      //         );
      //     }
      //   }, "南平市");
      //   bd.get(this.areaNameArr[i].name, function (rs) {
      //     // console.log(rs,'bd.get');
      //     let count = rs.boundaries.length; //行政区域的点有多少个
      //     let pointArray = [];
      //     for (let i = 0; i < count; i++) {
      //       let path = [];
      //       let str = rs.boundaries[i].replace(" ", "");
      //       let points = str.split(";");
      //       for (let j = 0; j < points.length; j++) {
      //         let lng = points[j].split(",")[0];
      //         let lat = points[j].split(",")[1];
      //         path.push(new BMapGL.Point(lng, lat));
      //       }
      //       let prism = new BMapGL.Prism(path, 5000, {
      //         topFillColor: "#067F77",
      //         topFillOpacity: 0.7,
      //         sideFillColor: "#24DDB6",
      //         sideFillOpacity: 0.9,
      //       });
      //       that.MidMap.addOverlay(prism);

      //       console.log(path,'112313');
      //       let tempPath = []
      //       for (let i = 0; i < path.length; i++) {
      //         tempPath.push([path[i].lng,path[i].lat])
      //       }
      //       // var layertestTwo = new mapvgl.WallLayer({
      //       //     gradient: {
      //       //         0: 'rgba(204, 153, 255, 0.5)',
      //       //         1: 'rgba(0, 0, 204, 0.2)'
      //       //     },
      //       //     height: 10000,
      //       //     data: [{
      //       //       geometry: {                
      //       //             coordinates: tempPath
      //       //         }
      //       //     }]
      //       // });
      //       var layertest = new mapvgl.WallTripLayer({
      //         // color: 'rgba(25, 255, 145, 1)',
      //           gradient: {
      //               0: 'rgba(25, 255, 145, 0)',
      //               1: 'rgba(25, 255, 145, 0.8)'
      //           },
      //       trailLength: 500.0,
      //       step: 5,
      //       height: 5000,
      //           data: [{
      //             geometry: {
      //               type: 'LineString',
      //                   coordinates: tempPath
      //               }
      //           }]
      //       });
      //       // view.addLayer(layertestTwo);
      //       view.addLayer(layertest);
      //       // layer.setData(path);
      //       // prism.removeEventListener('click')
      //       // // 绑定鼠标事件
      //       // let events = ['mouseover', 'mouseout'];
      //       // for (let i = 0; i < events.length; i++) {
      //       //     prism.addEventListener(events[i], e => {
      //       //         switch (events[i]) {
      //       //             case 'click':
      //       //                 alert('北京市');
      //       //                 break;
      //       //             case 'mouseover':
      //       //                 e.target.setTopFillColor('#067F77');
      //       //                 e.target.setTopFillOpacity(1);
      //       //                 break;
      //       //             case 'mouseout':
      //       //                 e.target.setTopFillColor('#067F77');
      //       //                 e.target.setTopFillOpacity(0.7);
      //       //                 break;
      //       //         }
      //       //     }); 
      //       // }
      //     }
      //   });
      // }
      this.MidMap.setMapStyleV2({ styleJson: styleJsonTwo });

      this.view = new mapvgl.View({
        map: this.MidMap,
      });

      let data = [
        {
          geometry: {
            type: "Point",
            coordinates: [118.120427, 27.331749],
          },
          color: "rgba(255, 193, 71, 0.7)",
          // 圆的半径
          size: 10,
        },
        {
          geometry: {
            type: "Point",
            coordinates: [118.220427, 27.431749],
          },
          color: "rgba(255, 193, 71, 0.7)",
          // 圆的半径
          size: 10,
        },
        {
          geometry: {
            type: "Point",
            coordinates: ["118.85754", "27.36609"],
          },
          color: "rgba(255, 193, 71, 0.8)",
          // 圆的半径
          size: 10,
        },
      ];
      // let dataTwo = [
      //   {
      //     geometry: {
      //         type: 'light',
      //         coordinates: [118.120427, 27.331749]
      //     },
      //     color: ['rgba(255, 193, 71, 1)'],
      //     // 圆的半径
      //     size: 70,
      //     height: 20000,
      //   },
      //   {
      //     geometry: {
      //         type: 'Point',
      //         coordinates: ['118.85754', '27.36609']
      //     },
      //     color: 'rgba(255, 193, 71, 0.8)',
      //     // 圆的半径
      //     size: 10
      //   }
      // ];
      // let dataThree = [
      //   {
      //     geometry: {
      //         type: 'Point',
      //         coordinates: [118.120427, 27.331749]
      //     },
      //     color: ['rgba(255, 193, 71, 1)'],
      //     // 圆的半径
      //     size: 70,
      //     height: 20000,
      //     properties: {
      //         text: '￥' + (Math.random() * 10).toFixed(2)
      //     }
      //   },
      //   {
      //     geometry: {
      //         type: 'Point',
      //         coordinates: ['118.85754', '27.36609']
      //     },
      //     color: 'rgba(255, 193, 71, 0.8)',
      //     // 圆的半径
      //     size: 10
      //   }
      // ];
      let dataFour = [
        {
          geometry: {
            type: "Point",
            coordinates: [118.120427, 27.331749],
          },
          // color: ['rgba(255, 193, 71, 1)'],
          // 圆的半径
          // properties: {

          // }
        },
        {
          geometry: {
            type: "Point",
            coordinates: ["118.85754", "27.36609"],
          },
          color: "rgba(255, 193, 71, 0.8)",
          // 圆的半径
          size: 10,
        },
      ];
      let dataFive = [
        {
          geometry: {
            type: "Point",
            coordinates: [118.220427, 27.431749],
          },
          // color: ['rgba(255, 193, 71, 1)'],
          // 圆的半径
          // properties: {

          // }
        },
        {
          geometry: {
            type: "Point",
            coordinates: ["118.85754", "27.36609"],
          },
          color: "rgba(255, 193, 71, 0.8)",
          // 圆的半径
          size: 10,
        },
      ];

      // let bubbleLayer = new mapvgl.CircleLayer({
      //   // 绘制带泡泡的圆
      //   type: "bubble",
      //   size: (size) => 3 * size,
      //   // 扩散半径，支持直接设置和回调两种形式，size为点的大小
      //   radius(size) {
      //     return 2 * size;
      //   },
      //   // 扩散时间
      //   duration: 1,
      //   // 渐隐时间
      //   trail: 1,
      // });
      // view.addLayer(bubbleLayer);
      // bubbleLayer.setData(data);

      let layer = new mapvgl.BarLayer({
        // size: 20 * map.getZoomUnits(),
        // 柱体的边数，可设置较大值趋近圆柱
        edgeCount: 50,
        // type: 'light',
      });
      // view.addLayer(layer);
      // layer.setData(dataTwo);

      // let textLayer = new mapvgl.TextLayer({
      //     // fontFamily: 'Songti SC',
      //     enablePicked: true,
      //     autoSelect: true,
      //     selectedColor: '#f00', // 选中项颜色
      //     color: '#ff0',
      //     onClick: (e) => { // 点击事件
      //       console.log(layer,'layer');
      //       let tempInterval = setInterval(() => {
      //         if(dataTwo[0].height == 23000){
      //           clearInterval(tempInterval)
      //         }else{
      //           dataTwo[0].height += 100
      //           layer.setData(dataTwo);
      //         }
      //       }, 50);

      //       // layer.data[0].height = 3000
      //     },
      // });

      // view.addLayer(textLayer);
      // textLayer.setData(dataThree);
      // let myIcon1 = new BMapGL.Icon(require('../assets/AI@2x.png'), new BMapGL.Size(30,30));
      //     let marker = new BMapGL.Marker(point,{icon:myIcon1});
      //     map.addOverlay(marker);
      let sContent = that.infoWindowEl;
      let areaDialogContent = that.$refs.AreaDialog.$el;
      let areaDialogCityContent = that.$refs.AreaDialogCity.$el;
      console.log(areaDialogContent,'areaDialogContent');
      console.log(sContent,'sContent');
      // let sContent = `<h4 style='margin:0 0 5px 0;'>天安门</h4>
      //     <img style='float:right;margin:0 4px 22px' id='imgDemo' src='../img/tianAnMen.jpg' width='139' height='104'/>
      //     <p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>
      //     天安门坐落在中国北京市中心,故宫的南侧,与天安门广场隔长安街相望,是清朝皇城的大门...
      //     </p></div>`;
      let opts = {
        position: new BMapGL.Point(118.120427, 27.331749), // 指定文本标注所在的地理位置
        // width : 100,     // 信息窗口宽度
        // height: 50,     // 信息窗口高度
        offset: new BMapGL.Size(-40, 320), //设置文本偏移量
        title: "", // 信息窗口标题
      };
      let areaDialogOpts = {
        position: new BMapGL.Point(118.120427, 27.331749), // 指定文本标注所在的地理位置
        // width : 100,     // 信息窗口宽度
        // height: 50,     // 信息窗口高度
        offset: new BMapGL.Size(-40, 220), //设置文本偏移量
        title: "", // 信息窗口标题
      };
      let infoWindow = new BMapGL.InfoWindow(sContent, opts);
      let areaDialogInfoWindow = new BMapGL.InfoWindow(areaDialogContent, areaDialogOpts);
      let areaDialogCityInfoWindow = new BMapGL.InfoWindow(areaDialogCityContent, areaDialogOpts);
      // marker.addEventListener("click", function(){
      //   this.openInfoWindow(infoWindow);
      //   console.log(document.getElementsByClassName('BMap_bubble_pop'))
      //   // document.getElementsByClassName('BMap_bubble_pop')[0].style.left += 200 + 'px'
      // });

      // let layerIcon = new mapvgl.IconLayer({
      //   width: 40,
      //   height: 40,
      //   offset: [0, -153 / 8],
      //   opacity: 1,
      //   icon: require("../../assets/HomeMap/AI@2x.png"),
      //   enablePicked: true, // 是否可以拾取
      //   selectedIndex: -1, // 选中项
      //   // selectedColor: '#ff0000', // 选中项颜色
      //   // autoSelect: true, // 根据鼠标位置来自动设置选中项
      //   onClick: (e) => {
      //     // 点击事件
      //     console.log("click", e);
      //     if (e.dataIndex != -1) {
      //       document.getElementById("info-window-box").style.display = "block";
      //       this.MidMap.openInfoWindow(
      //         infoWindow,
      //         new BMapGL.Point(
      //           e.dataItem.geometry.coordinates[0],
      //           e.dataItem.geometry.coordinates[1]
      //         )
      //       );
      //       // 图片加载完毕重绘infoWindow
      //       // document.getElementById('box').onload = function () {
      //       //     infoWindow.redraw(); // 防止在网速较慢时生成的信息框高度比图片总高度小，导致图片部分被隐藏
      //       // };
      //       // view.hideLayer(layerIcon)
      //       console.log(layerIcon, "layerIcon");
      //       this.isShow3D = false;
      //       // 移动视角
      //       // 自定义关键帧
      //       let keyFrames = [
      //         {
      //           center: point, // 定义第一个关键帧帧地图中心点
      //           zoom: 12, // 定义第一个关键帧地图等级
      //           tilt: 50, // 定义第一个关键帧地图倾斜角度
      //           heading: 0, // 定义第一个关键帧地图旋转方向
      //           percentage: 0, // 定义第一个关键帧处于动画过程的百分比，取值范围0~1
      //         },
      //         {
      //           center: new BMapGL.Point(
      //             e.dataItem.geometry.coordinates[0],
      //             e.dataItem.geometry.coordinates[1]
      //           ), // 定义第一个关键帧帧地图中心点
      //           // center: new BMapGL.Point(e.dataItem.geometry.coordinates[0], e.dataItem.geometry.coordinates[1]),     // 定义第一个关键帧帧地图中心点
      //           zoom: 19, // 定义第一个关键帧地图等级
      //           tilt: 50, // 定义第一个关键帧地图倾斜角度
      //           heading: 0, // 定义第一个关键帧地图旋转方向（角度）
      //           percentage: 1, // 定义第一个关键帧处于动画过程的百分比，取值范围0~1
      //         },
      //       ];
      //       // 设置动画属性
      //       let opts2 = {
      //         duration: 1500, // 设置每次迭代动画持续时间
      //         delay: 0, // 设置动画延迟开始时间
      //         interation: 1, // 设置动画迭代次数
      //       };
      //       // 监听动画事件
      //       this.mobilePerspective = new BMapGL.ViewAnimation(keyFrames, opts2); // 初始化动画实例
      //       this.mobilePerspective.addEventListener(
      //         "animationstart",
      //         function (e) {
      //           // 监听动画开始事件
      //           console.log("start");
      //         }
      //       );
      //       this.mobilePerspective.addEventListener(
      //         "animationiterations",
      //         function (e) {
      //           // 监听动画迭代事件
      //           console.log("onanimationiterations");
      //         }
      //       );
      //       this.mobilePerspective.addEventListener(
      //         "animationend",
      //         function (e) {
      //           // 监听动画结束事件
      //           console.log("end");
      //         }
      //       );
      //       this.mobilePerspective.addEventListener(
      //         "animationcancel",
      //         function (e) {
      //           // 监听动画中途被终止事件
      //           console.log("cancel");
      //         }
      //       );
      //       // 开始播放动画
      //       // this.MidMap.startViewAnimation(animation);         // 开始播放动画
      //       // 强制停止动画
      //       // map.cancelViewAnimation(animation);         // 强制停止动画

      //       // 定义返回动画
      //       // 自定义关键帧
      //       let keyFramesBack = [
      //         {
      //           center: new BMapGL.Point(
      //             e.dataItem.geometry.coordinates[0],
      //             e.dataItem.geometry.coordinates[1]
      //           ), // 定义第一个关键帧帧地图中心点
      //           // center: new BMapGL.Point(e.dataItem.geometry.coordinates[0], e.dataItem.geometry.coordinates[1]),     // 定义第一个关键帧帧地图中心点
      //           zoom: 19, // 定义第一个关键帧地图等级
      //           tilt: 50, // 定义第一个关键帧地图倾斜角度
      //           heading: 0, // 定义第一个关键帧地图旋转方向（角度）
      //           percentage: 0, // 定义第一个关键帧处于动画过程的百分比，取值范围0~1
      //         },
      //         {
      //           center: point, // 定义第一个关键帧帧地图中心点
      //           zoom: 12, // 定义第一个关键帧地图等级
      //           tilt: 50, // 定义第一个关键帧地图倾斜角度
      //           heading: 0, // 定义第一个关键帧地图旋转方向
      //           percentage: 1, // 定义第一个关键帧处于动画过程的百分比，取值范围0~1
      //         },
      //       ];
      //       // 监听动画事件
      //       this.mobilePerspectiveBack = new BMapGL.ViewAnimation(
      //         keyFramesBack,
      //         opts2
      //       ); // 初始化动画实例
      //       this.mobilePerspectiveBack.addEventListener(
      //         "animationstart",
      //         function (e) {
      //           // 监听动画开始事件
      //           console.log("start");
      //         }
      //       );
      //       this.mobilePerspectiveBack.addEventListener(
      //         "animationiterations",
      //         function (e) {
      //           // 监听动画迭代事件
      //           console.log("onanimationiterations");
      //         }
      //       );
      //       this.mobilePerspectiveBack.addEventListener(
      //         "animationend",
      //         function (e) {
      //           // 监听动画结束事件
      //           console.log("end");
      //         }
      //       );
      //       this.mobilePerspectiveBack.addEventListener(
      //         "animationcancel",
      //         function (e) {
      //           // 监听动画中途被终止事件
      //           console.log("cancel");
      //         }
      //       );
      //     } else {
      //       // view.showLayer(layerIcon)
      //     }
      //   },
      //   // onDblClick: e => {
      //   //     console.log('double click', e);
      //   // },
      //   // onRightClick: e => {
      //   //     console.log('right click', e);
      //   // }
      // });
      // view.addLayer(layerIcon);
      // layerIcon.setData(dataFour);

      // let layerIcon2 = new mapvgl.IconLayer({
      //   width: 40,
      //   height: 40,
      //   offset: [0, -153 / 8],
      //   opacity: 1,
      //   icon: require("../../assets/HomeMap/AI@2xWarning.png"),
      //   enablePicked: true, // 是否可以拾取
      //   selectedIndex: -1, // 选中项
      //   // selectedColor: '#ff0000', // 选中项颜色
      //   // autoSelect: true, // 根据鼠标位置来自动设置选中项
      //   onClick: (e) => {
      //     // 点击事件
      //     console.log("click", e);
      //     if (e.dataIndex != -1) {
      //       document.getElementById("info-window-box").style.display = "block";
      //       this.MidMap.openInfoWindow(
      //         infoWindow,
      //         new BMapGL.Point(
      //           e.dataItem.geometry.coordinates[0],
      //           e.dataItem.geometry.coordinates[1]
      //         )
      //       );
      //       // 图片加载完毕重绘infoWindow
      //       // document.getElementById('box').onload = function () {
      //       //     infoWindow.redraw(); // 防止在网速较慢时生成的信息框高度比图片总高度小，导致图片部分被隐藏
      //       // };
      //       // view.hideLayer(layerIcon2)
      //       console.log(layerIcon2, "layerIcon2");
      //       this.isShow3D = true;
      //       console.log(
      //         this.$store.state.isHomeMap,
      //         "this.$store.state.isHomeMap"
      //       );
      //     } else {
      //       // view.showLayer(layerIcon2)
      //     }
      //   },
      //   // onDblClick: e => {
      //   //     console.log('double click', e);
      //   // },
      //   // onRightClick: e => {
      //   //     console.log('right click', e);
      //   // }
      // });
      // view.addLayer(layerIcon2);
      // layerIcon2.setData(dataFive);
      // map.getPanes().markerPane.appendChild(document.getElementsByClassName('box')[0])




let subLayerIcon = new mapvgl.IconLayer({
        width: 20,
        height: 38,
        offset: [0, -123 / 8],
        opacity: 1,
        icon: require("../../assets/HomeMap/项目icon@2x.png"),
        enablePicked: true, // 是否可以拾取
        selectedIndex: -1, // 选中项
        // selectedColor: '#ff0000', // 选中项颜色
        // autoSelect: true, // 根据鼠标位置来自动设置选中项
        // onClick: (e) => {
        //   // 点击事件
        //   console.log("click", e);

        // },
        // onDblClick: e => {
        //     console.log('double click', e);

        // },
        onRightClick: e => {
            console.log('right click', e);
          if (e.dataIndex != -1) {
            this.infoWindowEl.style.display = "block";
            this.MidMap.openInfoWindow(
              infoWindow,
              new BMapGL.Point(
                e.dataItem.geometry.coordinates[0],
                e.dataItem.geometry.coordinates[1]
              )
            );
            // view.hideLayer(layerIcon)
          } else {
            return
            // view.showLayer(layerIcon)
          }
        }
      });
      subLayerIcon.setData([
        {
          geometry: {
            type: "Point",
            coordinates: [118.076988, 27.201181],
          },
        },
      ]);
      // 画泡泡
      let bubbleLayer = new mapvgl.CircleLayer({
        // 绘制带泡泡的圆
        type: "bubble",
        size: (size) => 3 * size,
        // 扩散半径，支持直接设置和回调两种形式，size为点的大小
        radius(size) {
          return 2 * size;
        },
        // 扩散时间
        duration: 1,
        // 渐隐时间
        trail: 1,
      });

      bubbleLayer.setData(
        [
          {
            geometry: {
              type: "Point",
              coordinates: [118.076988, 27.201181],
            },
            color: "rgba(71,98,255, 0.6)",
            // 圆的半径
            size: 15,
          },
        ]
      );



      // 监听地图缩放 操作Layer
      that.MidMap.addEventListener("zoomend", function(e){
        let zoom= that.MidMap.getZoom(); // 获取缩放级别
        console.log(zoom,'zoom');
        that.cMapZoom = zoom
        if(zoom <= 9){
          //  省8.7 市9.7 县 11
          // this.view.addLayer(subLayerIcon);
          // this.view.addLayer(bubbleLayer);

        }else if(zoom <= 10){

          // this.view.removeLayer(subLayerIcon);
          // this.view.removeLayer(bubbleLayer);
          // that.MidMap.closeInfoWindow()
        }else{

        }
        // 其余操作
      });
      // 绘制地图边界
      // // ---------------------------------------------------
          // let oldply;
          // function getBoundary(data) {

          //         let bdary = new BMapGL.Boundary();

          //         bdary.get(data, function (rs) {

          //             let maxNum = -1, maxPly;

          //             let ply = new BMapGL.Polygon(rs.boundaries[0], { strokeWeight: 2, strokeOpacity: 0.5, fillColor: "#CDAA7D", strokeColor: "#FF00FF" });

          //             that.MidMap.addOverlay(ply);

          //             //开始用"mouseover","mouseout"发现,鼠标移动过快,会多个省份也高亮了.所以改成 click了

          //             ply.addEventListener("click", function (e) {

          //                 if (typeof (oldply) != "undefined") {

          //                     oldply.setFillColor("#E0FFFF");

          //                 }

          //                 ply.setFillColor("red");

          //                 oldply = ply;

          //             });

          //             if (maxPly) {

          //                 that.MidMap.setViewport(maxPly.getPoints());

          //             }

          //         });

          //     }
          //     let datas = ["黄坑镇", "南平", "南漳县", "谷城县", "保康县", "老河口市", "枣阳市", "宜城市"];
          //     for (let i = 0; i < datas.length; i++) {
          //       getBoundary(datas[i]);
          //     }
      //   // ---------------------------------------------------

      // // 画墙  数据包含首尾
      // let dataWall = [
      //   {
      //     geometry: {
      //       coordinates: [
      //         [118.10537405990496, 27.37811201362488],
      //         [118.10765315252037, 27.377652944079962],
      //         [118.10819832056977, 27.378706476871244],
      //         [118.10632464544784, 27.37907034408902],
      //         [118.106032966098, 27.378928667419363],
      //         [118.10556494084271, 27.378556815071406],
      //         [118.10542173486482, 27.378326168013988],
      //         [118.10537405990496, 27.37811201362488],
      //       ],
      //       type: "LineString",
      //     },
      //   },
      // ];
      // let layerWall = new mapvgl.WallLayer({
      //   gradient: {
      //     0: "rgba(255, 204, 204, 0.5)",
      //     1: "rgba(255, 102, 102, 0.8)",
      //   },
      //   color: "rgba(57, 254, 247, 0.9)",
      //   height: 100,
      // });
      // layerWall.setData(dataWall);
      // view.addLayer(layerWall);
    },

    // handlePosition(){
    //   if(this.mobilePerspective){
    //     this.MidMap.cancelViewAnimation(this.mobilePerspective)
    //   }
    //   let that = this
    //           //  返回主视角动画
    //           // 自定义关键帧
    //           let backKeyFrames = [
    //             {
    //               center: that.cMapPoint, // 定义第一个关键帧帧地图中心点
    //               zoom: that.cMapZoom, // 定义第一个关键帧地图等级
    //               tilt: 50, // 定义第一个关键帧地图倾斜角度
    //               heading: 0, // 定义第一个关键帧地图旋转方向
    //               percentage: 0, // 定义第一个关键帧处于动画过程的百分比，取值范围0~1
    //             },
    //             {
    //               center: that.cMapFissoPoint, // 定义第一个关键帧帧地图中心点
    //               // center: new BMapGL.Point(e.dataItem.geometry.coordinates[0], e.dataItem.geometry.coordinates[1]),     // 定义第一个关键帧帧地图中心点
    //               zoom: that.cMapFissoZoom, // 定义第一个关键帧地图等级
    //               tilt: 50, // 定义第一个关键帧地图倾斜角度
    //               heading: 0, // 定义第一个关键帧地图旋转方向（角度）
    //               percentage: 1, // 定义第一个关键帧处于动画过程的百分比，取值范围0~1
    //             },
    //           ];
    //           // 设置动画属性
    //           let backOpts = {
    //             duration: 1500, // 设置每次迭代动画持续时间
    //             delay: 0, // 设置动画延迟开始时间
    //             interation: 1, // 设置动画迭代次数
    //           };
    //           // 监听动画事件
    //           that.mobilePerspectiveBack = new BMapGL.ViewAnimation(backKeyFrames, backOpts); // 初始化动画实例
    //           that.mobilePerspectiveBack.addEventListener(
    //             "animationstart",
    //             function (e) {
    //               // 监听动画开始事件
    //               console.log("start");
    //               that.MidMap.removeEventListener('moveend')
    //               that.MidMap.removeEventListener('moving')
    //               that.MidMap.addEventListener('dragging',function (e) {
    //                 // if(that.mobilePerspective){
    //                   // that.MidMap.cancelViewAnimation(that.mobilePerspective);
    //                 // }
    //                 // if(that.mobilePerspectiveBack){
    //                   that.MidMap.cancelViewAnimation(that.mobilePerspectiveBack);
    //                 // }     
    //                 that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
    //               })
    //             }
    //           );
    //           that.mobilePerspectiveBack.addEventListener(
    //             "animationiterations",
    //             function (e) {
    //               // 监听动画迭代事件
    //               console.log("onanimationiterations");
    //             }
    //           );
    //           that.mobilePerspectiveBack.addEventListener(
    //             "animationend",
    //             function (e) {
    //               // 监听动画结束事件
    //               console.log("end");
    //               that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
    //               that.MidMap.addEventListener('moveend',function (e) {
    //                 console.log(e,'moveend');
    //                 console.log(that.MidMap.getCenter(),'moveend')
    //                 that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
    //               })
    //               that.MidMap.addEventListener('moving',function (e) {
    //                 console.log(e,'moving');
    //                 console.log(that.MidMap.getCenter(),'moving')
    //                 that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
    //               })
    //               that.MidMap.removeEventListener('dragging')
    //             }
    //           );
    //           that.mobilePerspectiveBack.addEventListener(
    //             "animationcancel",
    //                   function (e) {
    //                     // 监听动画中途被终止事件
    //                     console.log("cancel");
    //                     that.MidMap.removeEventListener('dragging')
    //                     that.cMapZoom = that.MidMap.getZoom()
    //                     that.cMapPoint = new BMapGL.Point(that.MidMap.getCenter().lng, that.MidMap.getCenter().lat)
    //                   }
    //           );
    //           // 开始播放动画
    //           // that.MidMap.startViewAnimation(that.mobilePerspectiveBack);         // 开始播放动画
    //           // 强制停止动画
    //           // map.cancelViewAnimation(animation);         // 强制停止动画
    //                       // that.MidMap.startViewAnimation(that.mobilePerspectiveBack);

      
    //   this.MidMap.startViewAnimation(this.mobilePerspectiveBack)
    // },
    // 定位
    handlePosition(){
      let that = this
      console.log(that.$store.state.bdMapZoom,'that.$store.state.bdMapZoom');
      if(that.$store.state.bdMapZoom == 1){
        that.MidMap.flyTo( new BMapGL.Point(118.120427, 26.031749),8.99)
      }else if(that.$store.state.bdMapZoom == 2){
        that.MidMap.flyTo(new BMapGL.Point(118.12125539559224,27.39353818304773),9.8)
      }else{
        that.MidMap.flyTo(new BMapGL.Point(117.91026439527197, 26.930037987197853),11.3)
      }
    },
    // 卫星地图
    handleSatelliteMap(){
      let that = this
      that.$store.commit('SET_MAP_TYPE','B_EARTH_MAP')
      that.MidMap.setMapType(BMAP_EARTH_MAP)
      if(that.$store.state.bdMapZoom == 1){  //省
        that.view.removeLayer(that.heatmap);
        that.view.removeLayer(that.heatmapThree);
        // 替换地标
        for (let i = 0; i < that.markerArr.length; i++) {
          that.view.removeLayer(that.iconLayerArr[i])
          that.MidMap.addOverlay(that.markerArr[i])
        }
        // 替换项目
        for (let i = 0; i < that.projectArr.length; i++) {
          that.view.removeLayer(that.projectIconArr[i])
          that.MidMap.addOverlay(that.projectArr[i])
        }
      }else if(that.$store.state.bdMapZoom == 2){   //市
        // 替换地标
        that.view.removeLayer(that.heatmapTwo);
        that.view.removeLayer(that.heatmapFour);
        for (let i = 0; i < that.markerArrTwo.length; i++) {
          that.view.removeLayer(that.iconLayerArrTwo[i])
          that.MidMap.addOverlay(that.markerArrTwo[i])
        }
        // 替换项目
        for (let i = 0; i < 4; i++) {
          that.view.removeLayer(that.projectIconArr[i])
          that.MidMap.addOverlay(that.projectArr[i])
        }
      }else if(that.$store.state.bdMapZoom == 3){   //县
        // 替换地标
        that.view.removeLayer(that.iconLayerArrTwo[0])
        that.MidMap.addOverlay(that.markerArrTwo[0])
        // 替换项目
        // for (let i = 0; i < that.projectArrThree.length; i++) {
        //   that.view.removeLayer(that.projectIconArrThree[i])
        //   that.MidMap.addOverlay(that.projectArrThree[i])
        // }
      }
    },
    // 冠层
    handleCarbonMap(){
      let that = this
      that.$store.commit('SET_MAP_TYPE','B_NORMAL_MAP')
      that.isCarbon = false
      // this.MidMap.clearOverlays()
      this.MidMap.setMapType(BMAP_NORMAL_MAP)
        // // 定义XYZLayer图层
        // var wms = new BMapGL.XYZLayer({
        //     transparentPoints: ["#FFFFFF"],
        //     tileUrlTemplate: '/geoserver/ktt/wms?service=WMS&version=1.1.0&request=GetMap&layers=ktt:fjs&styles=&bbox=[b]&width=768&height=750&srs=EPSG:3857&format=image%2Fjpeg&transparent=false'
        // });

        // this.MidMap.addTileLayer(wms);
        // var bd = new BMapGL.Boundary();
        // bd.get('福建', function (rs) {
        //     wms.addBoundary(rs.boundaries); // 添加掩膜
        // });
      if(that.$store.state.bdMapZoom == 1){  //省
        setTimeout(() => {
          that.view.addLayer(that.heatmapThree);
          // 替换地标
          for (let i = 0; i < that.markerArr.length; i++) {
            that.view.addLayer(that.iconLayerArr[i])
          }
          // 替换项目
          for (let i = 0; i < that.projectArr.length; i++) {
            that.view.addLayer(that.projectIconArr[i])
          }
        }, 0);
        that.view.removeLayer(that.heatmap);
        // 替换地标
        for (let i = 0; i < that.markerArr.length; i++) {
          that.view.removeLayer(that.iconLayerArr[i])
          that.MidMap.removeOverlay(that.markerArr[i])
        }
        // 替换项目
        for (let i = 0; i < that.projectArr.length; i++) {
          that.view.removeLayer(that.projectIconArr[i])
          that.MidMap.removeOverlay(that.projectArr[i])
        }
      }else if(that.$store.state.bdMapZoom == 2){   //市
        // 替换地标
        setTimeout(() => {
          that.view.addLayer(that.heatmapFour);
          for (let i = 0; i < that.markerArrTwo.length; i++) {
            that.view.addLayer(that.iconLayerArrTwo[i])
          }
          // 替换项目
          for (let i = 0; i < 4; i++) {
            that.view.addLayer(that.projectIconArr[i]);
          }
        }, 0);
        that.view.removeLayer(that.heatmapTwo);
        for (let i = 0; i < that.markerArrTwo.length; i++) {
          that.view.removeLayer(that.iconLayerArrTwo[i])
          that.MidMap.removeOverlay(that.markerArrTwo[i])
        }
        // 替换项目
        for (let i = 0; i < 4; i++) {
          that.MidMap.removeOverlay(that.projectArr[i]);
          that.view.removeLayer(that.projectIconArr[i]);
        }
      }else if(that.$store.state.bdMapZoom == 3){   //县
        // 替换地标
        that.view.addLayer(that.iconLayerArrTwo[0])
        that.MidMap.removeOverlay(that.markerArrTwo[0])
        // 替换项目
        // for (let i = 0; i < that.projectArrThree.length; i++) {
        //   that.view.addLayer(that.projectIconArrThree[i])
        //   that.MidMap.removeOverlay(that.projectArrThree[i])
        // }
      }
      // this.heatmap.disableMassClear()
    },
    // 碳密度
    handleSpotMap(){
      let that = this
      that.$store.commit('SET_MAP_TYPE','B_NORMAL_MAP')
      that.isCarbon = true
      // this.MidMap.clearOverlays()
      this.MidMap.setMapType(BMAP_NORMAL_MAP)
      if(that.$store.state.bdMapZoom == 1){  //省
        setTimeout(() => {
          that.view.addLayer(that.heatmap);
          // 替换地标
          for (let i = 0; i < that.markerArr.length; i++) {
            that.view.addLayer(that.iconLayerArr[i])
          }
          // 替换项目
          for (let i = 0; i < that.projectArr.length; i++) {
            that.view.addLayer(that.projectIconArr[i])
          }
        }, 0);
        that.view.removeLayer(that.heatmapThree);
        // 替换地标
        for (let i = 0; i < that.markerArr.length; i++) {
          that.view.removeLayer(that.iconLayerArr[i])
          that.MidMap.removeOverlay(that.markerArr[i])
        }
        // 替换项目
        for (let i = 0; i < that.projectArr.length; i++) {
          that.view.removeLayer(that.projectIconArr[i])
          that.MidMap.removeOverlay(that.projectArr[i])
        }
      }else if(that.$store.state.bdMapZoom == 2){   //市
        // 替换地标
        setTimeout(() => {          
          that.view.addLayer(that.heatmapTwo);
          for (let i = 0; i < that.markerArrTwo.length; i++) {
            that.view.addLayer(that.iconLayerArrTwo[i])
          }
          // 替换项目
          for (let i = 0; i < 4; i++) {
            that.view.addLayer(that.projectIconArr[i]);
          }
        }, 0);
        that.view.removeLayer(that.heatmapFour);
        for (let i = 0; i < that.markerArrTwo.length; i++) {
          that.view.removeLayer(that.iconLayerArrTwo[i])
          that.MidMap.removeOverlay(that.markerArrTwo[i])
        }
        // 替换项目
        for (let i = 0; i < 4; i++) {
          that.MidMap.removeOverlay(that.projectArr[i]);
          that.view.removeLayer(that.projectIconArr[i]);
        }
      }else if(that.$store.state.bdMapZoom == 3){   //县
        // 替换地标
        that.view.addLayer(that.iconLayerArrTwo[0])
        that.MidMap.removeOverlay(that.markerArrTwo[0])
        // 替换项目
        // for (let i = 0; i < that.projectArrThree.length; i++) {
        //   that.view.addLayer(that.projectIconArrThree[i])
        //   that.MidMap.removeOverlay(that.projectArrThree[i])
        // }
      }
      // this.heatmap.disableMassClear()
    },
    // 返回地图级别
    handleBackZoom(){
      let that = this
        console.log(that.$store.state.bdMapZoom,'that.$store.state.bdMapZoom');
      if(that.MidMap.getMapType() == 'B_EARTH_MAP'){
        if(that.$store.state.bdMapZoom == 1){
          that.$store.commit('SET_MAP_TYPE','B_EARTH_MAP')
          that.$router.replace("/login");
        }else if(that.$store.state.bdMapZoom == 2){
          that.showBackButton = true
          that.$store.commit("SET_MAP_ZOOM", 1)
          that.MidMap.flyTo( new BMapGL.Point(118.120427, 26.031749),8.5)
          for (let i = 0; i < that.markerArr.length; i++) {
            that.MidMap.addOverlay(that.markerArr[i])
          }
          for (let i = 0; i < that.holeArr.length; i++) {
            that.MidMap.addOverlay(that.holeArr[i]);
          }
          for (let i = 4; i < that.projectArr.length; i++) {
            that.MidMap.addOverlay(that.projectArr[i])
          }
          for (let i = 0; i < that.holeArrTwo.length; i++) {
            that.MidMap.removeOverlay(that.holeArrTwo[i]);
          }
          for (let i = 0; i < that.markerArrTwo.length; i++) {
            that.MidMap.removeOverlay(that.markerArrTwo[i])
          }
          // for (let i = 0; i < that.projectArrTwo.length; i++) {
          //   that.MidMap.removeOverlay(that.projectArrTwo[i])
          // }
        }else{
          that.showBackButton = true
          that.$store.commit("SET_MAP_ZOOM", 2)
          that.$store.commit("SET_PROJECT_TYPE", 1)
          that.MidMap.flyTo(new BMapGL.Point(118.12125539559224,27.39353818304773),10.1)
          for (let i = 0; i < that.littleBanProjectOne.length; i++) {
            that.MidMap.removeOverlay(that.littleBanProjectOne[i])
            that.MidMap.removeOverlay(that.littleBanProjectTwo[i])
            that.MidMap.removeOverlay(that.littleBanProjectThree[i])
          }
          for (let i = 0; i < 4; i++) {
            that.MidMap.addOverlay(that.projectArr[i])
          }
          // for (let i = 0; i < that.projectArrTwo.length; i++) {
          //   that.MidMap.addOverlay(that.projectArrTwo[i]);
          // }
          for (let i = 1; i < that.holeArrTwo.length; i++) {
            that.MidMap.addOverlay(that.holeArrTwo[i]);
          }
          for (let i = 1; i < that.markerArrTwo.length; i++) {
            that.MidMap.addOverlay(that.markerArrTwo[i])
          }
        }
      }else{
        if(that.$store.state.bdMapZoom == 1){
          that.view.removeLayer(that.heatmapTwo);
          that.view.removeLayer(that.heatmap);
          that.view.removeLayer(that.heatmapThree);
          that.view.removeLayer(that.heatmapFour);
          that.$store.commit('SET_MAP_TYPE','B_EARTH_MAP')
          that.$router.replace("/login");
        }else if(that.$store.state.bdMapZoom == 2){
          setTimeout(() => {
            if(that.isCarbon){
              that.view.addLayer(that.heatmap);
            }else{
              that.view.addLayer(that.heatmapThree);
            }
            for (let i = 0; i < that.iconLayerArr.length; i++) {
              that.view.addLayer(that.iconLayerArr[i])
            }
            for (let i = 0; i < that.projectIconArr.length; i++) {
              that.view.addLayer(that.projectIconArr[i])
            }
            
          }, 0);
          that.showBackButton = true
          that.view.removeLayer(that.heatmapTwo);
          that.view.removeLayer(that.heatmap);
          that.view.removeLayer(that.heatmapThree);
          that.view.removeLayer(that.heatmapFour);
          that.$store.commit("SET_MAP_ZOOM", 1)
          that.MidMap.flyTo( new BMapGL.Point(118.120427, 26.031749),8.5)
          for (let i = 0; i < that.holeArr.length; i++) {
            that.MidMap.addOverlay(that.holeArr[i]);
          }
          // for (let i = 0; i < 4; i++) {
          //   that.view.removeLayer(that.projectIconArr[i])
          // }
          for (let i = 0; i < that.holeArrTwo.length; i++) {
            that.MidMap.removeOverlay(that.holeArrTwo[i]);
          }
          for (let i = 0; i < that.iconLayerArrTwo.length; i++) {
            that.view.removeLayer(that.iconLayerArrTwo[i])
          }
          // for (let i = 0; i < that.projectIconArrTwo.length; i++) {
          //   that.view.removeLayer(that.projectIconArrTwo[i])
          // }
        }else{
          that.showBackButton = true
          that.view.addLayer(that.heatmapTwo);
          that.$store.commit("SET_MAP_ZOOM", 2)
          that.$store.commit("SET_PROJECT_TYPE", 1)
          that.MidMap.flyTo(new BMapGL.Point(118.12125539559224,27.39353818304773),9.9)
          for (let i = 0; i < that.projectIconArrThree.length; i++) {
            that.view.removeLayer(that.projectIconArrThree[i])
          }
          for (let i = 0; i < that.projectIconArrTwo.length; i++) {
            that.view.addLayer(that.projectIconArrTwo[i]);
          }
          for (let i = 1; i < that.holeArrTwo.length; i++) {
            that.MidMap.addOverlay(that.holeArrTwo[i]);
          }
          for (let i = 1; i < that.iconLayerArrTwo.length; i++) {
            that.view.addLayer(that.iconLayerArrTwo[i])
          }
        }
      }
    },
    toProjectOne(){
      for (let i = 0; i < this.littleBanProjectPathOne.length; i++) {
        this.MidMap.addOverlay(this.littleBanProjectOne[i]);
        this.MidMap.removeOverlay(this.littleBanProjectTwo[i]);
        this.MidMap.removeOverlay(this.littleBanProjectThree[i]);
        this.$store.commit('SET_PROJECT_TYPE', 1)
      }
      this.MidMap.closeInfoWindow()
    },
    toProjectTwo(){
      for (let i = 0; i < this.littleBanProjectPathTwo.length; i++) {
        this.MidMap.removeOverlay(this.littleBanProjectOne[i]);
        this.MidMap.addOverlay(this.littleBanProjectTwo[i]);
        this.MidMap.removeOverlay(this.littleBanProjectThree[i]);
        this.$store.commit('SET_PROJECT_TYPE', 2)
      }
      this.MidMap.closeInfoWindow()
    },
    toProjectThree(){
      for (let i = 0; i < this.littleBanProjectPathThree.length; i++) {
        this.MidMap.removeOverlay(this.littleBanProjectOne[i]);
        this.MidMap.removeOverlay(this.littleBanProjectTwo[i]);
        this.MidMap.addOverlay(this.littleBanProjectThree[i]);
        this.$store.commit('SET_PROJECT_TYPE', 3)
      }
      this.MidMap.closeInfoWindow()
    },
    handleChangeDrawer(){
      // console.log(document.getElementById("drawer-right").style.left == '',document.getElementById("drawer-right").style.left);
      if( document.getElementById("drawer-right").style.left == '' || document.getElementById("drawer-right").style.left != '0px'){
          $("#drawer-right").animate(
            { left: "0px" },
            800,
            "swing",
            () => {}
          );
          $("#drawer-content-container").animate(
            { opacity: 1 },
            2000,
            "swing",
            () => {}
          );
        }else{
          $("#drawer-right").animate(
            { left: "-52%" },
            2000,
            "swing",
            () => {}
          );
          $("#drawer-content-container").animate(
            { opacity: 0 },
            800,
            "swing",
            () => {}
          );
      }
    },
    activeMapZoom(e){
      console.log('触发了');
      let that = this
      if(this.$store.state.bdMapType === 'B_EARTH_MAP'){
        if(that.$store.state.bdMapZoom == 1){
          console.log(e,'123dblclick');
          that.showBackButton = true
          that.$store.commit("SET_MAP_ZOOM", 2);
          for (let i = 0; i < that.markerArr.length; i++) {
            that.MidMap.removeOverlay(that.markerArr[i])
          }
          for (let i = 0; i < that.holeArr.length; i++) {
            that.MidMap.removeOverlay(that.holeArr[i]);
          }
          for (let i = 4; i < that.projectArr.length; i++) {
            that.MidMap.removeOverlay(that.projectArr[i]);
          }
          // for (let i = 0; i < that.projectArrTwo.length; i++) {
          //   that.MidMap.addOverlay(that.projectArrTwo[i]);
          // }
  
          for (let i = 0; i < that.holeArrTwo.length; i++) {
            that.MidMap.addOverlay(that.holeArrTwo[i]);
          }
          for (let i = 0; i < that.markerArrTwo.length; i++) {
            that.MidMap.addOverlay(that.markerArrTwo[i])
          }
          that.MidMap.flyTo(new BMapGL.Point(118.12125539559224,27.39353818304773),9.9)
        }else if(that.$store.state.bdMapZoom == 2){
          that.$store.commit('SET_MAP_ZOOM', 3)
          that.showBackButton = true
          for (let i = 0; i < that.projectArr.length; i++) {
            that.MidMap.removeOverlay(that.projectArr[i]);
          }
          // for (let i = 0; i < that.projectArrTwo.length; i++) {
          //   that.MidMap.removeOverlay(that.projectArrTwo[i]);
          // }
          for (let i = 1; i < that.holeArrTwo.length; i++) {
            that.MidMap.removeOverlay(that.holeArrTwo[i]);
          }
          that.MidMap.addOverlay(that.holeArrTwo[100]);
          for (let i = 1; i < that.markerArrTwo.length; i++) {
            that.MidMap.removeOverlay(that.markerArrTwo[i])
          }
          for (let i = 0; i < that.littleBanProjectOne.length; i++) {
            that.MidMap.addOverlay(that.littleBanProjectOne[i]);
          }
          console.log(e,'123');
          that.MidMap.flyTo(new BMapGL.Point(117.91026439527197, 26.930037987197853),11.3)
        }else{
          return
        }
      }else{
        if(that.$store.state.bdMapZoom == 1){
          setTimeout(() => {
            if(that.isCarbon){
              that.view.addLayer(that.heatmapTwo);
            }else{
              that.view.addLayer(that.heatmapFour);
            }
            
            for (let i = 0; i < that.iconLayerArrTwo.length; i++) {
              that.view.addLayer(that.iconLayerArrTwo[i])
            }
            for (let i = 0; i < 4; i++) {
              that.view.addLayer(that.projectIconArr[i]);
            }
          }, 0);
          that.view.removeLayer(that.heatmapTwo);
          that.view.removeLayer(that.heatmap);
          that.view.removeLayer(that.heatmapThree);
          that.view.removeLayer(that.heatmapFour);
          that.showBackButton = true
          that.$store.commit("SET_MAP_ZOOM", 2);
          for (let i = 0; i < that.projectIconArr.length; i++) {
            that.view.removeLayer(that.projectIconArr[i]);
          }

          for (let i = 0; i < that.iconLayerArr.length; i++) {
            that.view.removeLayer(that.iconLayerArr[i]);
          }
          for (let i = 0; i < that.holeArr.length; i++) {
            that.MidMap.removeOverlay(that.holeArr[i]);
          }
          for (let i = 0; i < that.holeArrTwo.length; i++) {
            that.MidMap.addOverlay(that.holeArrTwo[i]);
          }
          // for (let i = 0; i < that.projectIconArrTwo.length; i++) {
          //   that.view.addLayer(that.projectIconArrTwo[i])
          // }
          that.MidMap.flyTo(new BMapGL.Point(118.12125539559224,27.39353818304773),9.9)
        }else if(that.$store.state.bdMapZoom == 2){
          that.view.removeLayer(that.heatmapTwo);
          that.showBackButton = true
          that.$store.commit("SET_MAP_ZOOM", 3);
          that.$store.commit('SET_MAP_TYPE','B_EARTH_MAP')
          for (let i = 0; i < that.iconLayerArrTwo.length; i++) {
            that.view.removeLayer(that.iconLayerArrTwo[i])
          }
          // for (let i = 0; i < that.projectIconArrTwo.length; i++) {
          //   that.view.removeLayer(that.projectIconArrTwo[i])
          // }
          for (let i = 0; i < that.projectIconArr.length; i++) {
            that.view.removeLayer(that.projectIconArr[i])
          }
          // for (let i = 0; i < that.projectIconArrThree.length; i++) {
          //   that.view.addLayer(that.projectIconArrThree[i])
          // }
          for (let i = 1; i < that.holeArrTwo.length; i++) {
            that.MidMap.removeOverlay(that.holeArrTwo[i]);
          }
          that.MidMap.addOverlay(that.holeArrTwo[100]);
          that.MidMap.setMapType(BMAP_EARTH_MAP)
          for (let i = 0; i < that.littleBanProjectPathOne.length; i++) {
            that.MidMap.addOverlay(that.littleBanProjectOne[i]);
          }
          that.MidMap.addOverlay(that.markerArrTwo[0]);
          that.MidMap.flyTo(new BMapGL.Point(117.91026439527197, 26.930037987197853),11.3)
        }else{
          return
        }
      }
    }
  },
  beforeDestroy() {
    console.log('beforeDestroy');
  },
  destroyed() {
    console.log('destroyed');
  },
  mounted() {
    this.$nextTick(()=>{
      if(this.MidMap == null){
        this.infoWindowEl = document.getElementById('info-window-box')
        this.initMap()
      }else{
        this.MidMap.destroy()
        this.MidMap = null
        this.infoWindowEl = document.getElementById('info-window-box')
        this.initMap()
      }
    })
    this.getData()
  },
  computed:{
    getMapZoom(){
      return this.$store.state.bdMapZoom
    }
  },
  watch:{
    getMapZoom(){
      console.log('getMapZoom hoomMap');
      this.MidMap.closeInfoWindow()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.HomeMap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
#container {
  width: 100vw;
  height: 100vh;
}
#info-window-box {
  width: 364px;
  /* height: 100px; */
  /* background-color: red !important; */
  display: none;
  position: absolute;
  background-image: url("../../assets/HomeMap/弹窗1bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* top: -10px; */
}
.top-title{
  width: 328px;
  /* height: 36px; */
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  line-height: 18px;
  /* position: absolute; */
  margin-top: 10px;
  margin-left:18px;
  margin: 21px 0 27px 18px;
}
.info-window-middle{
  width: calc(100% - 18px);
  margin-left: 18px;
}
.middle-unit{
  width: 314px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.middle-unit-left{
  width: 95px;
  display: flex;
  justify-content: space-between;
}
/* 小标题icon@2x.png */
/* 属地@2x.png */
.middle-unit-img{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/HomeMap/小标题icon@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.middle-unit-title{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  line-height: 18px;
  text-align: justify;
  text-justify:distribute;
  text-align-last: justify;
}
.middle-unit-content{
  width: 198px;
  /* height: 18px; */
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFC147;
  line-height: 18px;
  text-shadow: 0px 0px 2px #FFBF14;
}
.middle-unit-img-two{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/HomeMap/属地@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.info-window-bottom{
  width: 340px;
  height: 75px;
  margin-top: 44px;
  margin-left: 28px;
  display: flex;
  justify-content: space-between;
}
.info-window-bottom-unit{
  width: 308px;
  height: 14px;
  display: flex;
  margin-bottom: 10px;
}
.info-window-bottom-point{
  width: 4px;
  height: 4px;
  margin: 5px 5px 0 0;
  background: #FFC147;
  box-shadow: 0px 0px 6px 0px #FFBF14, 0px 0px 4px 0px #FFBF14;
}
.info-window-bottom-title{
  width: 130px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D1FEE9;
  line-height: 14px;
  text-shadow: 0px 0px 5px rgba(209, 254, 233, 0.5);
}
.info-window-bottom-content{
  width: 176px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #FFC147;
  line-height: 16px;
  text-shadow: 0px 0px 5px #FFC147;
  text-align: right;
}




/* 调整信息框的背景 */
#container >>> .BMap_bubble_pop {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}
/* 去除信息框的三角形以及阴影 */
>>> .BMap_bubble_pop > img,
>>> .shadow {
  display: none;
}
/* 去除信息框右边关闭按钮 */
>>> .BMap_bubble_buttons {
  display: none;
}

/*隐藏infowindow头部*/
>>> .BMap_bubble_top,>>> .BMap_bubble_center{
  height: 0 !important;
}
/*地图标题*/
>>> .BMap_bubble_title {
  width: 308px !important;
  height: 20px !important;
  color: white !important;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  /* padding-left:5px; */
  padding-top: 5px;
  /* border-bottom:1px solid gray; */
  /* background-color:#0066b3; */
  /* background-image: url("../../assets/HomeMap/弹窗标题背景@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
}
>>> .BMap_bubble_center {
  /* height: 4px !important; */
}
/* 消息内容 */
>>> .BMap_bubble_content {
  width: 364px !important;
  height: 4px !important;
  /* background-image: url("../../assets/HomeMap/弹窗1bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  color: white !important;
  margin-top: 5px;
  margin-left: 10px;
  overflow: visible !important;
  /* background-color:white; */
  /* padding-left:5px;
	padding-top:5px;
	padding-bottom:10px; */
}
/* 内容 */
>>> .BMap_pop div:nth-child(9) {
  top: 35px !important;
  border-radius: 7px;
}
/* 左上角删除按键 */
>>> .BMap_pop img {
  top: 43px !important;
  left: 215px !important;
}
>>> .BMap_top {
  display: none;
}
>>> .BMap_bottom {
  display: none;
}
>>> .BMap_center {
  display: none;
}
/* 隐藏边角 */
>>> .BMap_pop div:nth-child(1) div {
  display: none;
}
>>> .BMap_pop div:nth-child(3) {
  display: none;
}
>>> .BMap_pop div:nth-child(7) {
  display: none;
}
.normal-map-tip{
  position: absolute;
  top: 840px;
  left: 504px;
  z-index: 999;
  /* background-color: #fff; */
}
.normal-map-tip-title{
  /* width: 112px; */
  height: 30px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #D1FEE9;
  line-height: 12px;
  text-shadow: 0px 0px 5px rgba(209,254,233,0.5000);
}
.normal-map-tip-scale-container-zero{
  position: absolute;
  bottom: 0;
  left: 17px;
}
.normal-map-tip-scale-container-half{
  position: absolute;
  bottom: 45px;
  left: 17px;
}
.normal-map-tip-scale-container-full{
  position: absolute;
  bottom: 89px;
  left: 17px;
}
.normal-map-tip-scale-line{
  height: 2px;
  width: 10px;
  background-color: #D1FEE9;
}
.normal-map-tip-scale-content{
  position: absolute;
  left: 15px;
  top: -5px;
  /* height: 30px; */
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #D1FEE9;
  line-height: 12px;
  text-shadow: 0px 0px 5px rgba(209,254,233,0.5000);
}
.normal-map-tip-bar{
  width: 17px;
  height: 91px;
  background: linear-gradient(0deg, rgba(17, 72, 136, 1) 0%, rgba(61, 87, 1, 1) 49%, rgba(255, 201, 73, 1) 100%);
  border-radius: 2px;
}
.drawer-container{
  position: absolute;
  top: 985px;
  left: 504px;
  z-index: 999;
  display: flex;
}
.drawer-left{
  width: 140px;
  height: 50px;
  background-image: url("../../assets/drawer/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 999;
  cursor: pointer;
}
.drawer-left-img{
  width: 49px;
  height: 40px;
  background-image: url("../../assets/drawer/魔方处理后@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 5px;
  left: 13px;
}
.drawer-left-content{
  width: 67px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHeiSemibold;
  color: #D1FEE9;
  line-height: 14px;
  text-align: left;
  position: absolute;
  top: 18px;
  left: 73px;
}
.drawer-right{
  width: 330px;
  display: flex;
  justify-content: space-between;
  position: relative;
  left: -52%;
}
.drawer-content-container{
  width: 246px;
  display: flex;
  opacity: 0;
  align-items: center;
}
.drawer-content-unit{
  width: 82px;
  height: 50px;
  background: linear-gradient(90deg, rgba(0,197,197,0.5000) 0%, #01572E 100%);
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  /* line-height: 50px; */
  position: relative;
  cursor: pointer;
  display: flex;
  flex:1;
  /* opacity: 0; */
  align-items: center;
  justify-content: space-around;
  /* text-align: center; */
}
.drawer-content-unit-line{
  width: 19px;
  height: 50px;
  position: absolute;
  background-image: url("../../assets/drawer/分割线1@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 72px;
  z-index: 9;
}
.drawer-content-unit-line-two{
  width: 19px;
  height: 50px;
  position: absolute;
  background-image: url("../../assets/drawer/分割线1@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 154px;
  z-index: 9;
}



/* 返回@2x.png */
/* 定位回位@2x.png */
.back-fisso-position{
  width: 60px;
  height: 60px;
  background-image: url("../../assets/HomeMap/定位回位@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 9999;
  cursor: pointer;
  margin-top: -5px;
}

/* 返回地图缩放级别 */
.back-map-zoom {
  /* icon_sel@2x.png */
  width: 60px;
  height: 60px;
  background-image: url("../../assets/HomeMap/返回@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 110px;
  left: 486px;
  pointer-events: all;
  cursor: pointer;
  z-index: 999;
}
</style>
