<template>
  <div class="project-dialog">
    <div class="top-container">
      <div class="top-title">
        {{projectObj.title}}
      </div>
      <div class="top-button-container">
        <div class="top-button-unit" @click="handleChange(1)">
          <div class="top-circle"></div>
          <div class="top-button-content" :class="{'active':isActive == 1}">项目详情</div>
        </div>
        <div class="top-button-unit" @click="handleChange(2)">
          <div class="top-circle"></div>
          <div class="top-button-content" :class="{'active':isActive == 2}">林木详情</div>
        </div>
        <div class="top-button-unit" @click="handleChange(3)">
          <div class="top-circle"></div>
          <div class="top-button-content" :class="{'active':isActive == 3}">点云展示</div>
        </div>
        <div class="top-button-unit" @click="handleChange(4)">
          <div class="top-circle"></div>
          <div class="top-button-content" :class="{'active':isActive == 4}">数据对比</div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="bottom-one" v-show="isActive == 1">
        <div class="info-window-middle">
          <div class="middle-unit">
            <div class="middle-unit-left">
              <div class="middle-unit-img"></div>
              <div class="middle-unit-title">项目业主</div>
            </div>
            <div class="middle-unit-right">
              <div class="middle-unit-content">{{projectObj.project.owner}}</div>
            </div>
          </div>
          <div class="middle-unit">
            <div class="middle-unit-left">
              <div class="middle-unit-img-two"></div>
              <div class="middle-unit-title">项目属地</div>
            </div>
            <div class="middle-unit-right">
              <div class="middle-unit-content">{{projectObj.project.area}}</div>
            </div>
          </div>
        </div>
        <div class="info-window-bottom">
          <div class="info-window-bottom-unit-container">
            <div class="info-window-bottom-unit">
              <div class="info-window-bottom-point"></div>
              <div class="info-window-bottom-title">项目计入期：</div>
              <div class="info-window-bottom-content">{{projectObj.project.creditingPeriod}}</div>
              <!-- <div class="info-window-bottom-content">2007/01/01 - 2026/12/31</div> -->
            </div>
            <div class="info-window-bottom-unit">
              <div class="info-window-bottom-point"></div>
              <div class="info-window-bottom-title">第一监测期：</div>
              <div class="info-window-bottom-content">{{projectObj.project.monitoringPeriod}}</div>
            </div>
            <div class="info-window-bottom-unit">
              <div class="info-window-bottom-point"></div>
              <div class="info-window-bottom-title">第一监测期减排量：</div>
              <div class="info-window-bottom-content">{{projectObj.project.emissionReduction}}tCO2-e</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-two" v-show="isActive == 2 && isShowBottom == 1">
        <div class="bottom-two-title">
          <div>序号</div>
          <!-- <div>林木</div> -->
          <div>树种</div>
          <div>胸径</div>
          <div>树高</div>
          <div class="big-title">蓄积量</div>
          <div class="big-title">碳储量</div>
        </div>
        <div class="bottom-two-content">
          <div class="bottom-two-content-unit" v-for="(item,index) in projectObj.three" :key="index">
            <div class="bottom-two-tree">{{index + 1}}</div>
            <div>{{item.treeSpecies}}</div>
            <div>{{item.DBH}}</div>
            <div>{{item.treeHeight}}</div>
            <div class="big-content">{{item.volume}}</div>
            <div class="big-content">{{item.carbonStorage}}</div>
          </div>
          <!-- <div class="bottom-two-content-unit">
            <div class="bottom-two-tree">松树1</div>
            <div>阔叶</div>
            <div>10.6</div>
            <div>8.3</div>
            <div class="big-content">0.0380</div>
            <div class="big-content">0.0873</div>
          </div>
          <div class="bottom-two-content-unit">
            <div class="bottom-two-tree">松树1</div>
            <div>阔叶</div>
            <div>10.6</div>
            <div>8.3</div>
            <div class="big-content">0.0380</div>
            <div class="big-content">0.0873</div>
          </div>
          <div class="bottom-two-content-unit">
            <div class="bottom-two-tree">松树1</div>
            <div>阔叶</div>
            <div>10.6</div>
            <div>8.3</div>
            <div class="big-content">0.0380</div>
            <div class="big-content">0.0873</div>
          </div>
          <div class="bottom-two-content-unit">
            <div class="bottom-two-tree">松树1</div>
            <div>阔叶</div>
            <div>10.6</div>
            <div>8.3</div>
            <div class="big-content">0.0380</div>
            <div class="big-content">0.0873</div>
          </div> -->
        </div>
      </div>
      <div class="bottom-three" v-show="isActive == 3 && isShowBottom == 1">
        <div class="bottom-three-unit-container">
          <div class="bottom-three-unit">
            <div class="bottom-three-icon"></div>
            <div class="bottom-three-title">小班航拍俯视图</div>
            <div class="bottom-three-info" @click="showImgViewer(projectObj.pointCloud.srcOne)">查看大图</div>
              <el-image-viewer
                v-if="isShowImageViewer"
                @close="closeViewer"
                teleported
                :url-list="srcList"
                :initial-index="0"
              />
            <img :src="projectObj.pointCloud.srcOne" class="bottom-three-img">
          </div>
          <div class="bottom-three-unit">
            <div class="bottom-three-icon"></div>
            <div class="bottom-three-title">样地点云图</div>
            <div class="bottom-three-info" @click="showImgViewer(projectObj.pointCloud.srcTwo)">查看大图</div>
            <img :src="projectObj.pointCloud.srcTwo" class="bottom-three-img">
          </div>
        </div>
      </div>
      <div class="bottom-four" v-show="isActive == 4 && isShowBottom == 1">
        <div class="bottom-four-title-container">
          <div class="bottom-four-icon"></div>
          <div class="bottom-four-title">小班样地碳储量监测对比</div>
        </div>
        <div class="bottom-four-unit-container">
          <div class="bottom-four-unit">
            <div class="bottom-four-subtitle">
              <div>精度对比</div>
            </div>
            <div id="bottom-four-chart-one"></div>
          </div>
          <div class="bottom-four-unit">
            <div class="bottom-four-subtitle">
              <div>时间对比</div>
            </div>
            <div id="bottom-four-chart-two"></div>
          </div>
        </div>
      </div>
      <div class="bottom-five" v-show="isActive != 1 && isShowBottom == 0">
        <div class="bottom-five-title">待开发</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    isShowBottom:{
      type: Number,
      default(){
        return 1
      }
    },
    projectObj:{
      type: Object,
      default(){
        return {
          title: '开发中',
          project:{
            owner: '',
            area: '',
            creditingPeriod: '',
            monitoringPeriod: '',
            emissionReduction: ''
          },
          three:
          [
            {"treeSpecies":"","DBH":"","treeHeight":"","volume":"","carbonStorage":""}
          ],
          pointCloud:{
            srcOne: '',
            srcTwo: '',
          },
          data:[
            [0,0],
            [0,0],
          ]
        }
      }
    },

  },
  data(){
    return{
      isActive: 1,
      srcList: [
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
      ],
      url:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
      isShowImageViewer: false
    }
  },
  methods:{
    handleChange(val){
      this.isActive = val
    },
    drawChartOne(val){
      let that = this
      document.getElementById("bottom-four-chart-one").removeAttribute("_echarts_instance_")
      let myChart = this.$echarts.init(document.getElementById("bottom-four-chart-one"));
      let option = {
        grid:{
          top: '20%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          splitLine:{
            show:false
          },
          axisLine:{
            lineStyle:{
              color: '#9BB0D6',
              width: 2
            },
          },
          axisTick:{
            show:false
          },
          axisLabel:{
            show: true,
            color: '#FFF'
          },
          data: ['人工样地调查法', '激光雷达遥感法']
        },
        yAxis: {
          type: 'value',
          splitLine:{
            show:false
          },
          axisTick:{
            show:false
          },
          axisLine:{
            show:false
          },
          axisLabel:{
            show: false,
            // color: '#FFF'
          },
        },
        series: [
          {
            data: val,

            label: {
              show: true,
              color: '#FFC147',
              formatter: '{@score}吨',
              fontSize: 22,
              // offset: [0, -40]
            },
            itemStyle:{
              color: new that.$echarts.graphic.LinearGradient(
              0, 0, 0, 1,       //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
              [
                  {offset: 0, color: '#FFC147'},
                  {offset: 1, color: 'rgba(255,193,71,0)'}
              ]),
              borderColor: new that.$echarts.graphic.LinearGradient(
              0, 0, 0, 1,       //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
              [
                  {offset: 0, color: '#F6D3AA'},
                  {offset: 0.05, color: 'rgba(0,0,0,0)'},
                  {offset: 1, color: 'rgba(0,0,0,0)'}
              ]),
              borderWidth: 3,
              borderType: 'solid',
            },
            labelLayout(params) {
              return {
                // x: params.rect.x,
                    y: params.rect.y,
                    verticalAlign: 'bottom',
                    align: 'center'
                }
            },
            barWidth: '12%',
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option);

      window.addEventListener("resize", function () {
        setTimeout(function () {
          myChart.resize();
        }, 350);
      });
 
    },
    drawChartTwo(val){
      let that = this
      document.getElementById("bottom-four-chart-two").removeAttribute("_echarts_instance_")
      let myChart = this.$echarts.init(document.getElementById("bottom-four-chart-two"));
      let option = {
        grid:{
          top: '20%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          splitLine:{
            show:false
          },
          axisLine:{
            lineStyle:{
              color: '#9BB0D6',
              width: 2
            },
          },
          axisTick:{
            show:false
          },
          axisLabel:{
            show: true,
            color: '#FFF'
          },
          data: ['人工样地调查法', '激光雷达遥感法']
        },
        yAxis: {
          type: 'value',
          splitLine:{
            show:false
          },
          axisTick:{
            show:false
          },
          axisLine:{
            show:false
          },
          axisLabel:{
            show: false,
            // color: '#FFF'
          },
        },
        series: [
          {
            data: val,

            label: {
              show: true,
              color: '#FFC147',
              formatter: '{@score}工时',
              fontSize: 22,
              // offset: [0, -40]
            },
            itemStyle:{
              color: new that.$echarts.graphic.LinearGradient(
              0, 0, 0, 1,       //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
              [
                  {offset: 0, color: '#FFC147'},
                  {offset: 1, color: 'rgba(255,193,71,0)'}
              ]),
              borderColor: new that.$echarts.graphic.LinearGradient(
              0, 0, 0, 1,       //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
              [
                  {offset: 0, color: '#F6D3AA'},
                  {offset: 0.05, color: 'rgba(0,0,0,0)'},
                  {offset: 1, color: 'rgba(0,0,0,0)'}
              ]),
              borderWidth: 3,
              borderType: 'solid',
            },
            labelLayout(params) {
              return {
                // x: params.rect.x,
                    y: params.rect.y,
                    verticalAlign: 'bottom',
                    align: 'center'
                }
            },
            barWidth: '12%',
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option);

      window.addEventListener("resize", function () {
        setTimeout(function () {
          myChart.resize();
        }, 350);
      });
 
    },
    showImgViewer(val){
      this.srcList = [val]
      this.isShowImageViewer = true
    },
    closeViewer(){
      console.log('123');
      this.isShowImageViewer = false
    },
    resetActive(){
      this.isActive = 1
    }
  },
  mounted(){
    this.drawChartOne(this.projectObj.data[0])
    this.drawChartTwo(this.projectObj.data[1])
  },
  watch:{
    projectObj(newVal,oldVal){
      this.drawChartOne(newVal.data[0])
      this.drawChartTwo(newVal.data[1])
    }
  }
}
</script>

<style scoped>
.project-dialog{
  width: 364px;
  height: 104px;
  position: absolute;
  top: 280px;
  /* left: 500px; */
  z-index: 999;
  display: none;
}
/* 弹窗1bg@2x.png */
.top-container{
  width: 364px;
  height: 104px;
  background-image: url("../../assets/ProjectDialog/弹窗1bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.top-title{
  width: 328px;
  height: 36px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  line-height: 18px;
  /* text-shadow: 0px 0px 4px rgba(255,255,255,0.5000), 0px 0px 2px #FFFFFF; */
  position: absolute;
  top: 12px;
  left: 18px;
  text-align: left;
}
.top-button-container{
  width: 100%;
  height: 30px;
  display: flex;
  position: absolute;
  bottom: 0;
}
.top-button-unit{
  width: 91px;
  height: 30px;
  background-image: url("../../assets/ProjectDialog/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}
.top-circle{
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #FFEC57;
  box-shadow: 0px 0px 4px 0px #FFF49C;
  position: absolute;
  left: 11px;
  top: 14px;
}
.top-button-content{
  width: 100%;
  height: 19px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D1FEE9;
  line-height: 19px;
  position: absolute;
  top: 6px;
  left: 18px;
}
.active{
  color: #31EFFF;
}
.bottom-one{
  width: 364px;
  /* height: 178px; */
  background-image: url("../../assets/ProjectDialog/弹窗1bg@2xsub1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 15px;
  text-align: left;
}
.info-window-middle{
  width: calc(100% - 18px);
  margin-left: 18px;
  display: flex;
  flex-wrap: wrap;
}
.middle-unit{
  width: 314px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.middle-unit-left{
  width: 95px;
  display: flex;
  justify-content: space-between;
}
/* 小标题icon@2x.png */
/* 属地@2x.png */
.middle-unit-img{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/HomeMap/小标题icon@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.middle-unit-title{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  line-height: 18px;
  text-align: justify;
  text-justify:distribute;
  text-align-last: justify;
}
.middle-unit-content{
  width: 198px;
  /* height: 18px; */
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFC147;
  line-height: 18px;
  text-shadow: 0px 0px 2px #FFBF14;
}
.middle-unit-img-two{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/HomeMap/属地@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.info-window-bottom{
  width: 340px;
  height: 75px;
  margin-top: 44px;
  margin-left: 28px;
  display: flex;
  justify-content: space-between;
}
.info-window-bottom-unit{
  width: 308px;
  height: 14px;
  display: flex;
  margin-bottom: 10px;
}
.info-window-bottom-point{
  width: 4px;
  height: 4px;
  margin: 5px 5px 0 0;
  background: #FFC147;
  box-shadow: 0px 0px 6px 0px #FFBF14, 0px 0px 4px 0px #FFBF14;
}
.info-window-bottom-title{
  width: 130px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D1FEE9;
  line-height: 14px;
  text-shadow: 0px 0px 5px rgba(209, 254, 233, 0.5);
}
.info-window-bottom-content{
  width: 176px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #FFC147;
  line-height: 16px;
  text-shadow: 0px 0px 5px #FFC147;
  text-align: right;
}
.bottom-two{
  width: 364px;
  height: 178px;
  background-image: url("../../assets/ProjectDialog/弹窗1bg@2xsub2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: all;
}
.bottom-two-title{
  width: 342px;
  height: 53px;
  display: flex;
  justify-content: space-between;
  margin-left: 11px;
}
.big-title{
  width: 57px !important;
}
.bottom-two-title div{
  width: 40px;
  height: 53px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFC147;
  line-height: 18px;
  text-shadow: 0px 0px 2px #FFBF14;
  text-align: center;
  line-height: 48px;
}
.bottom-two-content{
  width: 342px;
  height: 110px;
  overflow: auto;
  margin-left: 14px;
}
.bottom-two-content::-webkit-scrollbar{
  width: 4px;
  cursor: pointer !important;
}
.bottom-two-content::-webkit-scrollbar-thumb{
  background: #ffdd4d;
  border-radius: 20px;
  cursor: pointer !important;
}
.bottom-two-content::-webkit-scrollbar-track{
  background: transparent;
  cursor: pointer !important;
  /* box-shadow: inset 0 0 5px gary; */
}
.bottom-two-content-unit{
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
}
.bottom-two-content-unit div{
  width: 40px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #FFC147;
  line-height: 16px;
  text-shadow: 0px 0px 5px #FFC147;
  text-align: center;
}
.bottom-two-tree{
  color: #D1FEE9 !important;
  text-shadow: 0px 0px 5px rgba(209,254,233,0.5000) !important;
}
.big-content{
  width: 57px !important;
}
.bottom-three{
  width: 364px;
  height: 496px;
  background-image: url("../../assets/ProjectDialog/弹窗1bg@2xsub3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.bottom-three-unit-container{
  width: 328px;
  height: 465px;
  position: absolute;
  top: 15px;
  left: 18px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.bottom-three-unit{
  width: 328px;
  height: 213px;
  position: relative;
}
.bottom-three-icon{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/HomeMap/小标题icon@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom-three-title{
  width: 250px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  line-height: 18px;
  text-align: left;
  position: absolute;
  top: 0;
  left: 23px;
}
.bottom-three-info{
  width: 65px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #FFC147;
  line-height: 14px;
  text-shadow: 0px 0px 5px #FFC147;
  text-align: right;
  position: absolute;
  top: 3px;
  right: 0;
}
.bottom-three-img{
  width: 328px;
  height: 185px;
  /* background-color: #fff; */
  position: absolute;
  top: 28px;
  left: 0;
}
.bottom-four{
  width: 364px;
  height: 408px;
  background-image: url("../../assets/ProjectDialog/弹窗1bg@2xsub4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.bottom-four-title-container{
  width: 328px;
  height: 18px;
  position: absolute;
  top: 15px;
  left: 18px;
  display: flex;
  justify-content: space-between;
}
.bottom-four-icon{
  width: 18px;
  height: 18px;
  background-image: url("../../assets/HomeMap/小标题icon@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

}
.bottom-four-title{
  width: 305px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  line-height: 18px;
  text-align: left;
}
.bottom-four-unit-container{
  width: 308px;
  height: 346px;
  position: absolute;
  top: 42px;
  left: 15px;
}
.bottom-four-unit{
  width: 308px;
  height: 157px;
  margin-bottom: 32px;
  position: relative;
}
.bottom-four-subtitle{
  width: 141px;
  height: 30px;
  background-image: url("../../assets/ProjectDialog/小标题bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom-four-subtitle div{
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D1FEE9;
  line-height: 14px;
  text-align: left;
  position: absolute;
  top: 8px;
  left: 13px;
}
#bottom-four-chart-one{
  width: 280px;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 29px;
}
#bottom-four-chart-two{
  width: 280px;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 29px;
}
.bottom-five{
  width: 364px;
  height: 104px;
  background-image: url("../../assets/ProjectDialog/弹窗1bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  justify-items: center;
  align-items: center;
  display: flex;
}
.bottom-five-title{
  width: 100%;
  height: 18px;
  font-size: 30px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFC147;
  line-height: 18px;
  text-shadow: 0px 0px 2px #FFBF14;
  text-align: center;
}
</style>
<style>
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
</style>