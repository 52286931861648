<template>
  <div class="container">
    <div class="title-container">
      <div class="title-zh">碳交易数据</div>
      <div class="title-en">PROPORTION OF KEY PROJECTS</div>
    </div>
    <div class="small-title">
      <img src="../../assets/UpperLeft/小标题icon@2x.png" alt="" />
      <div class="unit">各交易中心年均成交均价</div>
      <span>（元/吨）</span>
      <div class="content-img">
        <div
          class="content1"
          :class="{ 'content1-active': contentActive == 1 }"
        >
          <div class="roll-number">65.03</div>
          <div class="roll-place">广州碳排放权交易所</div>
        </div>
        <div class="content" :class="{ 'content1-active': contentActive == 2 }">
          <div class="roll-number">32.69</div>
          <div class="roll-place">福建海峡交易中心</div>
        </div>

        <div class="content" :class="{ 'content1-active': contentActive == 3 }">
          <div class="roll-number">62.00</div>
          <div class="roll-place">深圳排放权交易所</div>
        </div>
        <div
          class="content2"
          :class="{ 'content1-active': contentActive == 4 }"
        >
          <div class="roll-number">69.39</div>
          <div class="roll-place">上海环境能源交易所</div>
        </div>
        <div
          class="content3"
          :class="{ 'content1-active': contentActive == 5 }"
        >
          <div class="roll-number">35.00</div>
          <div class="roll-place">天津排放权交易所</div>
        </div>
        <div
          class="content3"
          :class="{ 'content1-active': contentActive == 6 }"
        >
          <div class="roll-number">121.72</div>
          <div class="roll-place">北京绿色交易所</div>
        </div>
        <div
          class="content3"
          :class="{ 'content1-active': contentActive == 7 }"
        >
          <div class="roll-number">45.39</div>
          <div class="roll-place">湖北碳排放权交易中心</div>
        </div>
        <!-- <div class="content" v-for="item in PriceData"
              :key="item.id"
              :id="item.id">
               <div class="roll-number">{{ item.amount }}</div>
              <div class="roll-place">{{ item.area }}</div>
            </div> -->
      </div>
    </div>
    <div class="small-title1">
      <img src="../../assets/UpperLeft/小标题icon@2x.png" alt="" />
      <div class="unit1">各交易所今日成交量</div>
      <span>（吨）</span>
    </div>

    <div id="intChart" ref="intchart"></div>

    <div class="small-title2">
      <img src="../../assets/UpperLeft/小标题icon@2x.png" alt="" />
      <div class="unit2">各交易中心年成交额</div>
      <span>（元）</span>
    </div>
    <div class="Turnover" v-for="(item, index) in TurnoverData" :key="index">
      <div class="Turnover-icon"></div>
      <div class="Turnover-content">{{ item.title }}</div>
      <div class="Turnover-unit">元</div>
      <div class="Turnover-number">{{ item.number }}</div>
      
    </div>
    <div id="intChart2" ref="intchart2"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { toRefs, toRef, toRaw } from "vue";
import vueSeamlessScroll from "vue-seamless-scroll/src";
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      TurnoverData: [
        { title: "广州", number: "31,768,900" },
        { title: "天津", number: "36,518,900" },
        { title: "深圳", number: "37,235,100" },
        { title: "上海", number: '25,032,400' },
        { title: "重庆", number: "34,927,700" },
        { title: "北京", number: "33,841,900" },
        { title: "湖北", number: "35,111,900" },
      ],
      PriceData: [],
      contentActive: 1,
      timer: null,
      //柱状图数据
      areaNameArr: ["广州", "福建", "深圳", "上海", "天津", "北京", "湖北"],
      barArr: [2022, 3100, 3500, 133500, 1, 23449, 716],
      myChart: null,
      myChart1: null,
      end: 7,
      timeOut: null,
      option: null,
      option1: null,
      //雷达图数据
      areaCenterNameArr: [
        {
          name: "北京环境交易所",
          min: 100,
        },
        {
          name: "重庆碳排放权交易中心",
          min: 100,
        },
        {
          name: "湖北碳排放权交易中心",
          min: 100,
        },
        {
          name: "广州碳排放权交易所",
          min: 100,
        },
        {
          name: "深圳排放权交易所",
          min: 100,
        },
        {
          name: "上海能源环境交易所",
          ma: 100,
        },
        {
          name: "天津碳排放权交易所",
          m: 100,
        },
      ],
      radarArr: [{value:[33841900, 34927700, 35111900, 31768900, 37235100, 25032400, 36518900]}],
    };
  },
  methods: {
    //柱状图
    intChart() {
    
      this.myChart = this.$echarts.init(document.getElementById("intChart"));
      this.dataAxis = this.areaNameArr;
    
      this.data = this.barArr;
   

      this.option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          width: 48,
          height: 12,
          lineHeight: 12,
          confine: true,
          textStyle: {
            color: "#D1FEE9",
            fontSize: "12",
            fontFamily: "MicrosoftYaHei",
            align: "left",
          },
          // extraCssText:'background: linear-gradient(270deg, #1D4A63 0%, #0F244D 100%);',
          // extraCssText:'background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.61) 50%,rgba(0, 255, 135, 0.1) 100%);',
          backgroundColor: " rgba(0, 0, 0, 0.8) ",
          borderColor: "#00FF87", //tooltip边框颜色
          borderWidth: 1,
          formatter: (params) => {
            let str = "";
            let titleStr = "";
            let bg =
              '<span style="z-index:-1;display:inline-block;position:absolute;top:0;left:0;border-radius:10px;width:100%;height:100%;background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.51) 50%,rgba(0, 255, 135, 0.1) 100%);"></span>';
            for (let i = 0; i < params.length; i++) {
              // #3FBF7A 绿
              // #FFBF61 黄
              let textColor = ["#3FBF7A", "#FFBF61"];
              let markerStr = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${textColor[i]}"></span>`;
              let value = `<span style="color:${textColor[i]}">${params[i].value}</span>`;
              // console.log(params[i]);
              titleStr = params[i].name + "<br/>";
              str +=
                markerStr + " " + params[i].seriesName + ": " + value + "<br/>";
            }
            // return titleStr + str
            return str + bg;
          },
        },
        grid: {
          // y:10
          top: "7%",
          bottom: "12%",
          right: "0%",
          left: "0%",
          // height: 182,
        },
        legend: {
          show: false,
        },

        xAxis: {
          type: "category",
          data: toRaw(this.dataAxis),
          axisLabel: {
            inside: false,
            interval: 0,
            formatter: function (value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp;
                }
                return ret;
              } else {
                return value;
              }
            },
            // textStyle: {
            //   align: "left", // 文字左对齐
            //   backgroundColor: 'red',
            //   padding: [0, 0, 0, -120]    // 四个数字分别为上右下左与原位置距离
            // },
            color: "#D1FEE9",
            // textStyle: {
            // width: 48,
            // height: 12,
            align: "center",
            fontSize: "12",
            fontFamily: "MicrosoftYaHei",
            // },
          },
          axisTick: {
            show: false,
          },

          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            // 是否显示
            show: false,
          },
        },
        dataZoom: [
          {
            show: false,
            type: "slider",
            // realtime: true,
            startValue: 0, // 从头开始。
            endValue: toRaw(this.end), //
          },
          //  {
          //    show: true,
          //    realtime: true,
          //    height: 24,//这里可以设置dataZoom的尺寸
          //    bottom: 6,
          //    start: 0,
          //    end: 100,
          //    backgroundColor: '#1d76e6', // 填充颜色
          //    textStyle: {
          //     color: '#fff' // 左右两边文字颜色
          //   }
          //  }
        ],
        series: [
          {
            name: "成交量",
            type: "bar",
            showBackground: true,
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              formatter: "{c}",
              textStyle: {
                //数值样式
                color: "#BAA270",
                fontSize: 12,
                fontFamily: "MicrosoftYaHei",
              },
            },
            backgroundStyle: {
              color: "rgba(2, 132, 71, 0.2)",
            },
            barGap: "-100%",
            barCategoryGap: "40%",
            barWidth: "34",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(17, 136, 136, 0.8)" },
                { offset: 0.5, color: "rgba(1, 87, 46, 0.49)" },
                { offset: 1, color: "rgba(137, 255, 58, 0.5)" },
              ]),
              borderRadius: 2,
            },
            emphasis: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#FFC147",
                  fontSize: 14,
                  fontFamily: "MicrosoftYaHei",
                  shadowColor: " #FFC147",
                  shadowBlur: 5,
                },
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(17, 136, 136, 0.8)" },
                  { offset: 0.5, color: "rgba(1, 87, 46, 0.49)" },
                  { offset: 1, color: "rgba(137, 255, 58, 0.5)" },
                ]),
                borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "rgba(17, 136, 136, 0.8)" },
                  { offset: 0.5, color: "rgba(1, 87, 46, 0.49)" },
                  { offset: 0, color: "rgba(137, 255, 58, 0.5)" },
                ]),
                borderType: "solid",
                borderWidth: 1,
                // shadowOffsetX: 0,
                // shadowOffsetY: 0,
                // shadowColor: '#00FF87',
                // shadowBlur: 4,
              },
            },
            data: toRaw(this.data),
          },
        ],
      };
      this.myChart.on("mouseover", this.stop);
      this.myChart.on("mouseout", this.goMove);
      window.addEventListener("resize", ()=> {
        setTimeout(() =>{
          this.myChart.resize();
          this.myChart1.resize();
        }, 350);
        // this.myswiper.resize();
        // this.initSwiper()
        // this.swiper.allowSlideNext= true;//设置
      });
      // this.myChart.setOption(this.option);
      this.autoMove();
    },
    //自动滚动
    autoMove() {
      console.log(123);

      toRaw(this.myChart).setOption(toRaw(this.option));
      //this.dataList.seriesData为柱形图数据
      if (this.timeOut == null) {
        this.timeOut = setInterval(() => {
          // clearInterval(this.timeOut)
          // 每次向后滚动一个，最后一个从头开始。
          // if(this.stopMove){ return }

          this.data.push(toRaw(this.data.shift()));
          this.dataAxis.push(toRaw(this.dataAxis.shift()));
          // this.data

          // if (Number(this.option.dataZoom[0].endValue) === this.data.length) {
          //   this.option.dataZoom[0].endValue = this.end;
          //   this.option.dataZoom[0].startValue = 0;
          // } else {
          //   this.option.dataZoom[0].endValue = this.option.dataZoom[0].endValue + 1;
          //   this.option.dataZoom[0].startValue = this.option.dataZoom[0].startValue + 1;
          // }

          toRaw(this.myChart).setOption(toRaw(this.option));
        }, 3000);
      }
    },
    //停止滚动
    stop() {
      console.log(11);
      // this.stopMove=true
      clearInterval(this.timeOut);
      this.timeOut = null;
    },
    //继续滚动
    goMove() {
      console.log(333333);
      //  this.stopMove=false
      this.autoMove();
    },
    //雷达图
    intChart1() {
      let that = this
      this.myChart1 = this.$echarts.init(document.getElementById("intChart2"));
      this.option1= {
        grid: {
        },
        tooltip: {
          trigger: "item",
          // axisPointer: {
          //   type: "none",
          // },
          width: 48,
          height: 12,
          lineHeight: 12,
          confine: true,
          textStyle: {
            color: "#D1FEE9",
            fontSize: "12",
            fontFamily: "MicrosoftYaHei",
            align: "left",
          },
          // extraCssText:'background: linear-gradient(270deg, #1D4A63 0%, #0F244D 100%);',
          // extraCssText:'background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.61) 50%,rgba(0, 255, 135, 0.1) 100%);',
          backgroundColor: " rgba(0, 0, 0, 0.8) ",
          borderColor: "#00FF87", //tooltip边框颜色
          borderWidth: 1,
          formatter: (params) => {
            console.log(params,'params');
            let str = "";
            let titleStr = "";
            let bg =
              '<span style="z-index:-1;display:inline-block;position:absolute;top:0;left:0;border-radius:10px;width:100%;height:100%;background: linear-gradient(90deg,rgba(0, 255, 135, 0.1) 0%, rgba(0, 255, 135, 0.51) 50%,rgba(0, 255, 135, 0.1) 100%);"></span>';
            str = `<span style="display:block;width:100%;text-align:center;margin-bottom:2px;font-size:14px;"> ${params.seriesName} </span>`
            params.data.value.forEach((element,index) => {
              str +=
                  that.areaCenterNameArr[index].name + " : " + element + " 元<br/>";
            });
            // return titleStr + str
            return str + bg;
          },
        },
        radar: {

        axisName: {
          color: 'rgba(209, 254, 233, 1)',
          //  align: 'center',
           //指示器文字换行 start
           formatter: function(text){
                var strlength = text.length;
                if(strlength % 8 != 0){
                    text = text.replace(/\S{8}/g,function(match){
                        console.log(match);
                        return match + '\n'
                    })
                }else{
                    text = text.replace(/\S{8}/g,function(match){
                        console.log(match);
                        return match + '\n'
                    });
                    strlength = text.length;
                    text = text.substring(0,strlength - 1);
                }
                return text
            }
            //指示器文字换行 end
        },
        splitLine: {
          lineStyle: {
            color: [
              'rgba(137, 255, 58, 0.05)']
          }
        },
        splitArea: {
          areaStyle:{
            color: [
              'rgba(137, 255, 58, 0.04)',
              'rgba(137, 255, 58, 0.05)',
              'rgba(137, 255, 58, 0.1)',
              'rgba(137, 255, 58, 0.2)',
              'rgba(137, 255, 58, 0.3)',
              'rgba(137, 255, 58, 0.4)'
            ].reverse()
          }
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(137, 255, 58, 0.2)'
          }
        },
          center: ["50%", "50%"],
          radius: "60%",
          startAngle: 270,
          indicator:toRaw(that.areaCenterNameArr),
        },
        series: [
          {
            name: "成交量",
            type: "radar",
            showBackground: true,
            data: toRaw(that.radarArr),
            itemStyle: {
              color: '#FFF',
              borderWidth: 1,
              borderColor: 'rgba(137, 255, 58, 1)',
              borderType: 'solid'
            },
            lineStyle: {
              color: 'rgba(137, 255, 58, 1)'
            },
            symbolSize: 5,
            areaStyle: {
              color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(137,255,58,0)" },
                  { offset: 0.5, color: "rgba(1,87,46,0.5)" },
                  { offset: 1, color: "rgba(17,136,136,1)" },
                ])
            }
          },
        ],
      };
      this.myChart1.setOption(this.option1);
    },
  },
  mounted() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        if (this.contentActive >= 7) {
          this.contentActive = 1;
        } else {
          this.contentActive++;
        }
      }, 1000);
    }
    this.intChart();
    this.intChart1();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped>
.container {
  width: 432px;
  height: 100%;
  position: absolute;
  top: 110px;
  right: 24px;
  z-index: 9999;
}
.title-container {
  width: 432px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/编组备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}
.title-zh {
  width: calc(100% - 34px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 18px;
  position: absolute;
  top: 11px;
  left: 34px;
  text-align: left;
}
.title-en {
  width: calc(100% - 152px);
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #c7fdf3;
  line-height: 12px;
  position: absolute;
  top: 18px;
  left: 152px;
  text-align: left;
  opacity: 0.5;
}
.img-container {
  height: 286px;
  width: 480px;
  background-image: url("../../assets/UpperLeft/文案bg@2x.png");
  margin-top: 30px;
  margin-left: -24px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.blog_news_box {
  position: absolute;
  width: 412px;
  height: 224px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 16px;
  margin-top: 32px;
  margin-left: 34px;
  overflow: hidden;
}
/* 各交易中心年成交均价 */
.small-title {
  width: 283px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/小标题bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 47px;
}
.small-title img {
  float: left;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  margin-top: 6px;
}
.small-title span {
  float: left;
  width: 54px;
  height: 14px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 7px;
  margin-left: -6px;
}
.unit {
  float: left;
  width: 154px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 5px;
  margin-left: 2px;
}
/* 各交易中心年成交量 */
.small-title1 {
  width: 283px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/小标题bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 206px;
}
.small-title1 img {
  float: left;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  margin-top: 6px;
}
.small-title1 span {
  float: left;
  width: 36px;
  height: 14px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 7px;
  margin-left: -18px;
}
.unit1 {
  float: left;
  width: 154px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 5px;
  margin-left: -12px;
}
.small-title2 {
  width: 283px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/小标题bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 245px;
}
.small-title2 img {
  float: left;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  margin-top: 6px;
}
.small-title2 span {
  float: left;
  width: 36px;
  height: 14px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 7px;
  margin-left: -18px;
}
.unit2 {
  float: left;
  width: 154px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 5px;
  margin-left: -12px;
}
.content-img {
  position: absolute;
  width: 432px;
  height: 200px;
  margin-top: 29px;
}
.content1 {
  position: relative;
  float: left;
  margin-left: 69px;
  width: 102px;
  height: 116px;
  background-image: url("../../assets/TopRight/编组 18@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.content {
  position: relative;
  float: left;
  width: 102px;
  height: 116px;
  background-image: url("../../assets/TopRight/编组 18@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: -5px;
  opacity: 0.7;
}
.content2 {
  position: relative;
  float: left;
  margin-left: 21px;
  margin-top: -30px;
  width: 102px;
  height: 116px;
  background-image: url("../../assets/TopRight/编组 18@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.content3 {
  position: relative;
  float: left;
  width: 102px;
  height: 116px;
  background-image: url("../../assets/TopRight/编组 18@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: -5px;
  margin-top: -30px;
  opacity: 0.7;
}
.content1-active {
  opacity: 1;
}
.roll-number {
  width: calc(100% - 0px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #ffc147;
  padding-top: 30px;

  text-shadow: 0px 0px 2px #ffbf14;
  /* margin-left: 14%; */
  text-align: center;
}
.roll-place {
  width: calc(100% - 12px);
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 8px;
  padding-left: 7%;
  text-align: center;
}
#intChart {
  position: absolute;
  width: 432px !important;
  height: 202px !important;
  top: 335px;
}

/* 各交易中心年成交额 */
.Turnover {
  float: left;
  width: 203px;
  height: 22px;
  /* background-color: #c7fdf3; */
  margin-top: 7px;
  background: linear-gradient(
    90deg,
    rgba(43, 154, 151, 0) 0%,
    rgba(19, 97, 94, 0.5) 100%
  );
  margin-right: 13px;
}
.Turnover-icon {
  float: left;
  width: 4px;
  height: 4px;
  background: #ffec57;
  box-shadow: 0px 0px 4px 0px #fff49c;
  border-radius: 2px;
  margin: 10px 0 0 4px;
}
.Turnover-content {
  float: left;
  width: 40px;
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #9cfff1;
  line-height: 12px;
  margin: 5px 0 0 4px;
}
.Turnover-number {
width: 120px;
  float: right !important;
  height: 16px;
  font-size: 22px;
  font-family: DINPro-CondensedBold, DINPro;
  font-weight: normal;
  color: #3dd674;
  line-height: 16px;
  margin: 3px 0 0 0px;
  text-align: right;
}
.Turnover-unit {
  float: right;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #9cfff1;
  line-height: 12px;
  margin: 7px 0 0 2px;
}
/* 雷达图 */
#intChart2 {
  float: left;
  width: 432px !important;
  height: 220px !important;
  /* background-color: rgba(61, 214, 116, 0.3); */
  /* margin-top: 20px; */
}
</style>