<template>
  <div class="home">
    <div class="title-container">
      <div class="title-zh">树种累计碳储量占比</div>
      <div class="title-en">FOREST SPECIES CARBO</div>
    </div>
    <div class="com-container" ref="container"></div>
    <!-- 底座背景 -->
    <div class="bg"></div>
    <!-- <div class="percent">{{ areaProjectPercent }} <div class="percent-unit">%</div>
        </div> -->
    <div class="descname">
      <div class="small-placeName">{{ areaProjectName }}</div>
      <div class="small-number">{{ areaProjectPercent }}%</div>
      <!-- <div class="small-number">{{ areaProjectNumber }}</div> -->
    </div>

    
  </div>
</template>

<script>
// import { getPie3D, getParametricEquation } from './echart' //工具类js，页面路径自己修改

import { getProjectType } from "../../api/request.js";
const color = ["#62D095", "#FFC147"];
import HighCharts from "highcharts";
import { init } from "echarts";

export default {
  name: "chart",
  data() {
    return {
      dataIndex: 0,
      timerChart: null,
      baseList: [], //地区筛选 选择框数据
      baseIndex: 0,
      tempTreeSpeciesArr: [
        { name: "阔叶树", y: 103377, h: 28 },
          { name: "杉木", y: 7950941, h: 40 },
      ],
      // 项目一碳层8
      treeSpeciesArr: [
          { name: "阔叶树", y: 103377, h: 28 },
          { name: "杉木", y: 7950941, h: 40 },

      ],
      // 项目二碳层26
      treeSpeciesArr1: [
          { name: "马尾松", y: 2233110, h: 28 },
          { name: "杉木", y: 11135670, h: 40 },
      ],
      // 项目三碳层5
      treeSpeciesArr2: [
          { name: "毛竹", y: 11135670, h: 40 },
          { name: "", y: 0, h: 28 },
      ],
      statusChart: null,
      option: {},
      timer: null,
      areaProjectPercent: "",
      areaProjectNumber: 0,
      areaProjectName: "暂无数据",
      dataList: [],
      seriesObj: {},
      myHighChart: {},
    };
  },
  created() {
    // this.setLabel()
  },
  destroyed() {
    clearTimeout(this.timerChart);
    this.timerChart = null;
  },
  mounted() {
    this.getData();
    // this.initChart();
    const that = this;
    window.onresize = function () {
      setTimeout(function(){
        // clearTimeout(that.timerChart)
        // that.timerChart = null
        that.myHighChart.reflow();
        const each = HighCharts.each;
        each(that.myHighChart.series[0].points, function (p, i) {
          p.graphic.attr({
            translateY: -p.shapeArgs.ran,
          });
          p.graphic.side1.attr({
            translateY: -p.shapeArgs.ran,
          });
          p.graphic.side2.attr({
            translateY: -p.shapeArgs.ran,
          });
        });
        // console.log(that.myHighChart,'that.myHighChart');
      }, 350);
    };
  },
  methods: {
    getData() {
      this.ChangeBase();
    },
    ChangeBase() {
      this.myHighChart = {};
      clearTimeout(this.timerChart);
      this.timerChart = null;
      this.initChart(
        this.tempTreeSpeciesArr[0].y,
        this.tempTreeSpeciesArr[1].y,
        this.tempTreeSpeciesArr[0].name,
        this.tempTreeSpeciesArr[1].name,
      );
    },
    initChart(value1, value2, name1, name2) {
      const that = this;
      // 修改3d饼图绘制过程
      const each = HighCharts.each;
      const round = Math.round;
      const cos = Math.cos;
      const sin = Math.sin;
      const deg2rad = Math.deg2rad;
      HighCharts.wrap(
        HighCharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          const series = this;
          const chart = series.chart;
          const options = chart.options;
          const seriesOptions = series.options;
          const depth = seriesOptions.depth || 0;
          const options3d = options.chart.options3d;
          const alpha = options3d.alpha;
          const beta = options3d.beta;
          var z = seriesOptions.stacking
            ? (seriesOptions.stack || 0) * depth
            : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            const shapeArgs = point.shapeArgs;
            var angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          HighCharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            const ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(HighCharts);
      // 生成不同高度的3d饼图
      const highEcharts = this.$refs.container;
      that.myHighChart = HighCharts.chart(highEcharts, {
        chart: {
          type: "pie",
          animation: false,
          backgroundColor: "rgba(0,0,0,0)",
          events: {
            load: function () {
              that.dataList = [
                [
                  { name: name1, y: value1, h: 40 },
                  { name: name2, y: value2, h: 28 },
                ],
                [
                  { name: name1, y: value1, h: 28 },
                  { name: name2, y: value2, h: 40 },
                ],
              ];
              const each = HighCharts.each;
              const points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
              let series = this.series[0];
              that.seriesObj = this.series[0];
              console.log(series, "123123");
              console.log(that.seriesObj, "that.seriesObj");
              // console.log(that.dataIndex + 1,'that.seriesObj');
              // that.areaProjectPercent = series.data[that.dataIndex + 1].percentage.toFixed(0)
              that.areaProjectPercent =
                series.data[series.data.length - 1].percentage.toFixed(0);
              that.areaProjectNumber =
                series.data[series.data.length - 1].y.toFixed(0);
              that.areaProjectName = series.data[series.data.length - 1].name;
              that.initChartInterval(series, each, points);
              // highEcharts.addEventListener('mouseenter',clearInterval(that.timerChart))
              // highEcharts.addEventListener('mouseleave',that.initChartInterval(series,each,points))
            },
          },
          options3d: {
            enabled: true,
            alpha: 70,
          },
        },

        title: {
          show: "false",
          text: null,
        },
        subtitle: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        legend: {
          // 【图例】位置样式
          backgroundColor: "rgba(0,0,0,0)",
          shadow: false,
          layout: "vertical",
          align: "right", // 水平方向位置
          verticalAlign: "top", // 垂直方向位置
          x: 0, // 距离x轴的距离
          y: 100, // 距离Y轴的距离
          symbolPadding: 10,
          symbolHeight: 14,
          itemStyle: {
            lineHeight: "24px",
            fontSize: "16px",
            color: "#fff",
          },
          labelFormatter: function () {
            /*
             *  格式化函数可用的变量：this， 可以用 console.log(this) 来查看包含的详细信息
             *  this 代表当前数据列对象，所以默认的实现是 return this.name
             */
            return this.name + this.h + "%";
          },
        },
        plotOptions: {
          pie: {
            // allowPointSelect: true,
            cursor: "pointer",
            depth: 4,
            innerSize: "",
            dataLabels: {
              enabled: false,
            },
            // 显示图例
            showInLegend: false,
            center: ["50%", "70%"],
          },
          series: {
            point: {
              events: {
                mouseOver: function (e) {
                  console.log("鼠标移入>>", e, this);
                  // self.curX = this.x
                  // self.curY = this.y
                  // self.setHoverColumnBg(this);
                  // e.target.options.h = 35
                  // e.target.setData([
                  //   { name: '省级重点项目数', y: 20, h: 20 },
                  //   { name: '市级重点项目数', y: 28, h: 35 },
                  // ])
                  console.log(that.seriesObj, "that.seriesObj");
                  console.log(e, "e");
                  const each = HighCharts.each;
                  that.dataIndex = e.target.index;
                  that.areaProjectPercent =
                    that.seriesObj.data[that.dataIndex].percentage.toFixed(0);
                  that.areaProjectNumber =
                    that.seriesObj.data[that.dataIndex].y.toFixed(0);
                  that.areaProjectName =
                    that.seriesObj.data[that.dataIndex].name;
                  // data.push({ name: '省级重点项目数', y: 20, h: 35 });
                  // data.push({ name: '市级重点项目数', y: 28, h: 20 });
                  that.seriesObj.setData(that.dataList[that.dataIndex]);
                  each(that.seriesObj.points, function (p, i) {
                    p.graphic.attr({
                      translateY: -p.shapeArgs.ran,
                    });
                    p.graphic.side1.attr({
                      translateY: -p.shapeArgs.ran,
                    });
                    p.graphic.side2.attr({
                      translateY: -p.shapeArgs.ran,
                    });
                  });
                  clearInterval(that.timerChart);
                  that.timerChart = null;
                },
              },
            },
            events: {
              mouseOut: function (e) {
                // console.log(e,'123');
                const each = HighCharts.each;
                const points = e.target.points;
                let series = e.target;
                that.initChartInterval(series, each, points);
              },
            },
          },
        },
        tooltip: {
          style: {
            // 文字内容相关样式

            color: "rgba(0,0,0,0)",

            fontSize: "12px",

            fontWeight: "blod",

            fontFamily: "Courir new",
          },
          backgroundColor: "rgba(0,0,0,0)", // 背景颜色

          borderColor: "rgba(0,0,0,0)", // 边框颜色
          animation: true,
          shadow: false, // 是否显示阴影
          formatter: function () {
            //设置环形内显示的内容
            // console.log(HighCharts);
            // console.log(this.percentage.toFixed(2) + "%");
            // console.log(this.point);
            that.areaProjectPercent = this.percentage.toFixed(0);
            that.areaProjectNumber = this.point.y;
            that.areaProjectName = this.point.name;
            // clearInterval(that.timerChart)
            return this.point.name + ":" + this.percentage.toFixed(2) + "%";
          },
          // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'//小数点保留位数
        },
        series: [
          {
            type: "pie",
            name: "占比",
            // h 是高度  y是占的圆环长度
            colorByPoint: true,
            colors: [
              // { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
              //   linearGradient: {
              //     x1: 0,
              //     y1: 1,
              //     x2: 1,
              //     y2: 0
              //   },
              //   stops: [
              //     [0, '#19596d'],
              //     [1, '#2ea1b2']
              //   ]
              // }
              "rgba(255, 193, 71, 1)",
              "rgba(98, 208, 149, 1)",
            ],
            data: [
              { name: name1, y: value1, h: 28 },
              { name: name2, y: value2, h: 40 },
            ],
          },
        ],
      });
    },

    initChartInterval(series, each, points) {
      const that = this;
      if (!that.timerChart) {
        that.timerChart = setInterval(function () {
          if (that.dataIndex >= 1) {
            that.dataIndex = 0;
          } else {
            that.dataIndex++;
          }
          // console.log(123, that.dataIndex);
          console.log(series, "series");
          // console.log(points, 'points');
          that.areaProjectPercent =
            series.data[that.dataIndex].percentage.toFixed(0);
          that.areaProjectNumber = series.data[that.dataIndex].y;
          that.areaProjectName = series.data[that.dataIndex].name;
          // data.push({ name: '省级重点项目数', y: 20, h: 35 });
          // data.push({ name: '市级重点项目数', y: 28, h: 20 });
          series.setData(that.dataList[that.dataIndex]);
          each(points, function (p, i) {
            p.graphic.attr({
              translateY: -p.shapeArgs.ran,
            });
            p.graphic.side1.attr({
              translateY: -p.shapeArgs.ran,
            });
            p.graphic.side2.attr({
              translateY: -p.shapeArgs.ran,
            });
          });
        }, 5000);
      }
    },
  },
  computed:{
    getProjectType(){
      
      return this.$store.state.projectType
    }
  },
  watch:{
    getProjectType(newVal){
      if(newVal == 1){
        this.tempTreeSpeciesArr = this.treeSpeciesArr
        this.ChangeBase();
      }else if(newVal == 2){
        this.tempTreeSpeciesArr = this.treeSpeciesArr1
        this.ChangeBase();
      }else if(newVal == 3){
        this.tempTreeSpeciesArr = this.treeSpeciesArr2
        this.ChangeBase();
      }
    }
  }
};
</script>

<style scoped>
.home {
  position: absolute;
  width: 438px;
  height: 296px;

  z-index: 9999;
  top: 782px;
  left: 24px;
}

.title-container {
  width: 432px;
  height: 30px;
  background-image: url("../../assets/UpperLeft/编组备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}

.title-container span {
  width: 48px;
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 12px;
  position: absolute;
  top: 17px;
  left: 106px;
}

.title-zh {
  width: calc(100% - 34px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 18px;
  position: absolute;
  top: 11px;
  left: 34px;
  text-align: left;
}

.title-en {
  width: calc(100% - 152px);
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #c7fdf3;
  line-height: 12px;
  position: absolute;
  top: 18px;
  left: 202px;
  text-align: left;
  opacity: 0.5;
}

.location {
  height: 40px;
  width: 438px;
  background-image: url("../../assets/LowerLeft/小标题bg备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 41px;
}

.location img {
  float: left;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  margin-top: 11px;
}

.location span {
  float: left;
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  margin-top: 11px;
  margin-left: 2px;
}

.line {
  position: absolute;
  width: 1px;
  height: 20px;
  background: #00ff87;
  left: 97px;
  top: 51px !important;
}

.location-name {
  position: absolute;
  height: 40px;
  width: 100%;
  margin-top: 4px;
  margin-left: 45px;
  z-index: 99999;
}

.location-name >>> .el-icon svg {
  height: 0;
  width: 0;
}

.location-name >>> .popper__arrow::after,
.location-name >>> .popper__arrow {
  display: none;
}

.location-name >>> .el-input__inner {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  width: 310px;
}

.location-name >>> .el-select-dropdown {
  /* background-color: transparent; */
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: none;
}

.location-name >>> .el-popper {
  background-color: transparent;
  border: 1px solid rgba(33, 163, 96, 0.8);
}

.location-name >>> .el-select-dropdown__item {
  background-color: transparent !important;
  color: #d1fee9;
}

.location-name >>> .el-select-dropdown__item.hover {
  background-color: #000c39;
}

.location-name >>> .el-select .el-input.is-focus {
  box-shadow: none;
}

.location-name >>> .el-input__inner:focus {
  box-shadow: none !important;
}

.location-name >>> .el-input__inner:hover {
  box-shadow: none !important;
}

.location-name >>> .el-select:hover:not(.el-select--disabled) .el-input__inner {
  box-shadow: none !important;
}

.chart {
  position: absolute;
  width: 267px;
  height: 170px !important;
  z-index: 999999;

  top: 70px;
  left: 86px;
}

.bg {
  background: url("../../assets/LowerLeft/dp_25.png"),
    url("../../assets/LowerLeft/dp_1.png"),
    url("../../assets/LowerLeft/dp_2.png"),
    url("../../assets/LowerLeft/dp_3.png"),
    url("../../assets/LowerLeft/dp_4.png"),
    url("../../assets/LowerLeft/dp_5.png"),
    url("../../assets/LowerLeft/dp_6.png"),
    url("../../assets/LowerLeft/dp_7.png"),
    url("../../assets/LowerLeft/dp_8.png"),
    url("../../assets/LowerLeft/dp_9.png"),
    url("../../assets/LowerLeft/dp_10.png"),
    url("../../assets/LowerLeft/dp_11.png"),
    url("../../assets/LowerLeft/dp_12.png"),
    url("../../assets/LowerLeft/dp_13.png"),
    url("../../assets/LowerLeft/dp_14.png"),
    url("../../assets/LowerLeft/dp_15.png"),
    url("../../assets/LowerLeft/dp_16.png"),
    url("../../assets/LowerLeft/dp_17.png"),
    url("../../assets/LowerLeft/dp_18.png"),
    url("../../assets/LowerLeft/dp_19.png"),
    url("../../assets/LowerLeft/dp_20.png"),
    url("../../assets/LowerLeft/dp_21.png"),
    url("../../assets/LowerLeft/dp_22.png"),
    url("../../assets/LowerLeft/dp_23.png"),
    url("../../assets/LowerLeft/dp_24.png"),
    url("../../assets/LowerLeft/dp_25.png");
  width: 267px;
  height: 112px;
  position: absolute;
  top: 130px;
  left: calc(50% - 134px);
  /*循环播放动画*/
  animation-iteration-count: infinite;
  /*动画名字*/
  animation-name: round_rotate;
  /*动画一周期时间*/
  animation-duration: 3s;
  /*动画周期曲线*/
  animation-timing-function: linear;
  /*动画延迟*/
  /* animation-delay: 2s; */
  /* 调整动画大小 */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 9;
}

@keyframes round_rotate {
  0% {
    background-image: url("../../assets/LowerLeft/dp_25.png");
  }

  4% {
    background-image: url("../../assets/LowerLeft/dp_1.png");
  }

  8% {
    background-image: url("../../assets/LowerLeft/dp_2.png");
  }

  12% {
    background-image: url("../../assets/LowerLeft/dp_3.png");
  }

  16% {
    background-image: url("../../assets/LowerLeft/dp_4.png");
  }

  20% {
    background-image: url("../../assets/LowerLeft/dp_5.png");
  }

  24% {
    background-image: url("../../assets/LowerLeft/dp_6.png");
  }

  28% {
    background-image: url("../../assets/LowerLeft/dp_7.png");
  }

  32% {
    background-image: url("../../assets/LowerLeft/dp_8.png");
  }

  36% {
    background-image: url("../../assets/LowerLeft/dp_9.png");
  }

  40% {
    background-image: url("../../assets/LowerLeft/dp_10.png");
  }

  44% {
    background-image: url("../../assets/LowerLeft/dp_11.png");
  }

  48% {
    background-image: url("../../assets/LowerLeft/dp_12.png");
  }

  52% {
    background-image: url("../../assets/LowerLeft/dp_13.png");
  }

  56% {
    background-image: url("../../assets/LowerLeft/dp_14.png");
  }

  60% {
    background-image: url("../../assets/LowerLeft/dp_15.png");
  }

  64% {
    background-image: url("../../assets/LowerLeft/dp_16.png");
  }

  68% {
    background-image: url("../../assets/LowerLeft/dp_17.png");
  }

  72% {
    background-image: url("../../assets/LowerLeft/dp_18.png");
  }

  76% {
    background-image: url("../../assets/LowerLeft/dp_19.png");
  }

  80% {
    background-image: url("../../assets/LowerLeft/dp_20.png");
  }

  84% {
    background-image: url("../../assets/LowerLeft/dp_21.png");
  }

  88% {
    background-image: url("../../assets/LowerLeft/dp_22.png");
  }

  92% {
    background-image: url("../../assets/LowerLeft/dp_23.png");
  }

  96% {
    background-image: url("../../assets/LowerLeft/dp_24.png");
  }

  100% {
    background-image: url("../../assets/LowerLeft/dp_25.png");
  }
}

.descname {
  position: absolute;
  top: 60px;
  right: 50px;
}

.small-placeName {
  font-size: 24px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 24px;
}

.descSmall {
  position: absolute;
  width: 168px;
  height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHeiSemibold;
  color: #d1fee9;
  line-height: 14px;
  top: 255px;
  left: 103px;
}

.small-number {
  font-size: 24px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 24px;
  text-align: right;
  margin-top: 5px;
}

.percent {
  position: absolute;
  width: 119px;
  height: 60px;
  font-size: 76px;
  font-family: PangMenZhengDao;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(255, 191, 20, 0.5), 0px 0px 4px #ffbf14;
  z-index: 9999;
  top: 98px;
  left: 165px;
  display: flex;
}

.percent-unit {
  height: 60px;
  font-size: 30px;
  line-height: 115px;
}

.high-chart {
  width: 267px;
  /* width: 267px; */
  height: 240px !important;
  position: absolute;
  top: 30px;
  left: 86px;
  z-index: 9999;
}

.com-container {
  width: 400px;
  /* width: 267px; */
  height: 240px !important;
  position: absolute;
  top: 30px;
  left: 16px;
  z-index: 99;
}

>>> .el-popper__arrow {
  display: none;
}
</style>
